import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormHelperText,
  InputRightElement,
  IconButton,
  Icon,
  Button,
  Checkbox,
  FormControl,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import {
  FaBuilding,
  FaBriefcase,
  FaCalendarAlt,
  FaCheckCircle,
  FaCity,
  FaDollarSign,
  FaEnvelope,
  FaFacebook,
  FaFileAlt,
  FaGlobe,
  FaGavel,
  FaHome,
  FaIndustry,
  FaInfoCircle,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaMapPin,
  FaMapSigns,
  FaPhone,
  FaPlus,
  FaSearch,
  FaUserTie,
  FaUsers,
  FaWhatsapp
} from 'react-icons/fa'

export default function CompanyForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { contact_id: contactIdFromParams } = useParams() || ''
  const [contact_id, setContactId] = useState(contactIdFromParams)
  const [selectedOption, setSelectedOption] = useState('')

  // Select Search Company states
  const [searchTermCompany, setSearchTermCompany] = useState('')
  const [resultsCompany, setResultsCompany] = useState([])
  const [isOpenCompany, setIsOpenCompany] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [sendAlert, setSendAlert] = useState('')

  useEffect(() => {
    if (searchTermCompany) {
      setLoading(true)
      axios
        .get('/companies', {
          headers: authHeader(),
          params: {
            account_id,
            name: { $ilike: `%${searchTermCompany}%` }
          }
        })
        .then((response) => {
          setResultsCompany(response.data.data)
          setLoading(false)
          console.log('response company:', response)
        })
        .catch((e) => {
          setError(e.message)
          setLoading(false)
        })
    } else {
      setResultsCompany([])
    }
  }, [searchTermCompany, account_id])

  const [formData, setFormData] = useState({
    account_id: account_id,
    company_id: null,
    title: '',
    first_name: '',
    last_name: '',
    position: '',
    contact_data: {
      whatsapp: '',
      telefone: [
        {
          tipo: '', // Fixo, whatsapp, móvel, voip
          numero: ''
        }
      ],
      email: [
        {
          tipo: '', // principal, suporte, ...
          endereco_email: ''
        }
      ],
      address: {
        pais: '',
        uf: '',
        municipio: '',
        bairro: '',
        tipo_logradouro: '',
        logradouro: '',
        numero: '',
        cep: ''
      },
      descricao: ''
    },
    tags: []
  })

  useEffect(() => {
    console.log('formData', formData)
  }, [account_id, formData])

  useEffect(() => {
    async function getContactData() {
      try {
        const { data } = await axios.get(`/contacts/${contact_id}`, { headers: authHeader() })
        setFormData(data)
      } catch (error) {
        console.log('Erro ao carregar dados da empresa: ', error)
      }
    }

    if (contact_id) {
      getContactData()
    }
  }, [])

  const handleCompanySearchSelect = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      company_id: value.id
    }))
  }

  const handleCheckboxProfileChange = (event) => {
    const value = event.target.value
    setFormData((prevContactProfile) => {
      const newCompanyProfile = JSON.parse(JSON.stringify(prevContactProfile))

      if (event.target.checked) {
        newCompanyProfile.owner_data.profile.push(value)
      } else {
        newCompanyProfile.owner_data.profile = newCompanyProfile.owner_data.profile.filter(
          (item) => item !== value
        )
      }

      return newCompanyProfile
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const newState = { ...prevState }

      const keys = name.split('.') // Divide o name em um array
      let temp = newState

      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) {
          temp[keys[i]] = {}
        }
        temp = temp[keys[i]]
      }

      temp[keys[keys.length - 1]] = value

      return newState
    })
  }

  const handleSelect = (value) => {
    console.log('value: ', value)
    setSelectedOption(value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      company_id: value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (contact_id) {
      try {
        const response = await axios.patch(`/contacts/${contact_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/contacts', formData, {
          headers: authHeader()
        })
        console.log('Contato cadastrado com sucesso!', response.data)
        setContactId(response.data.id)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o contato:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  return (
    <>
      <Card>
        <Flex justifyContent={'space-between'}>
          <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
            {formData.first_name ? `${formData.first_name} ${formData.last_name}` : 'Novo Contato'}
          </Text>
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormControl id="contato1">
              <FormHelperText mt={'4px'} fontSize="xs" ml="15px">
                Contato
              </FormHelperText>
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px',
                    paddingInlineEnd: '0'
                  }}
                >
                  <Icon as={FaUserTie} mr="16px" />
                </InputLeftAddon>
                <Select
                  name="title"
                  placeholder="Título"
                  value={formData.title}
                  onChange={handleChange}
                  maxWidth="120px"
                  style={{
                    borderRadius: '0',
                    paddingInlineEnd: '0'
                  }}
                >
                  <option value="Sr.">Sr.</option>
                  <option value="Sra.">Sra.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Dra.">Dra.</option>
                </Select>
                <Input
                  type="text"
                  name="first_name"
                  placeholder="Nome"
                  value={formData.first_name}
                  onChange={handleChange}
                  style={{ borderRadius: '0', width: '310px' }}
                />
                <Input
                  type="text"
                  name="last_name"
                  placeholder="Sobrenome"
                  value={formData.last_name}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>

            <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Posição
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaBriefcase} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.position}
                      name="position"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Posição"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Empresa
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaIndustry} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={searchTermCompany}
                      name="searchTermCompany"
                      onChange={(e) => setSearchTermCompany(e.target.value)}
                      onFocus={() => setIsOpenCompany(true)}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Buscar Empresa..."
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                  <Box position="relative">
                    <Menu isOpen={isOpenCompany}>
                      <MenuButton as={Box} visibility="hidden" />
                      <MenuList
                        position="absolute"
                        top="100%"
                        left="50px"
                        width="100%"
                        zIndex="dropdown"
                        display={isOpenCompany ? 'block' : 'none'}
                        bg="white"
                        boxShadow="lg"
                        p="0px"
                        mt="-10px"
                        onBlur={() => setIsOpenCompany(false)}
                        fontSize={'sm'}
                        borderRadius="10px"
                      >
                        {resultsCompany && resultsCompany.length > 0 ? (
                          resultsCompany.map((company) => (
                            <MenuItem
                              key={company.id}
                              onClick={() => {
                                console.log('Company Click: ', company)
                                setSearchTermCompany(company.name)
                                handleCompanySearchSelect(company)
                              }}
                            >
                              {`${company.name}`}
                            </MenuItem>
                          ))
                        ) : (
                          <>
                            <MenuItem>Criar empresa</MenuItem>
                            {/* <MenuItem>Nenhum contato encontrado.</MenuItem> */}
                          </>
                        )}
                      </MenuList>
                    </Menu>
                  </Box>
                </Stack>
              </Flex>

              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Email
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderRadius: '50px 0 0 50px'
                      }}
                    >
                      <Icon as={FaEnvelope} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      type="text"
                      name="contact_data.email"
                      placeholder="Email"
                      value={formData.contact_data.email}
                      onChange={handleChange}
                      style={{ borderRadius: '0 50px 50px 0' }}
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <FormControl id="contato1_whatsapp">
                <FormHelperText fontSize="xs" ml="15px">
                  WhatsApp
                </FormHelperText>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderRadius: '50px 0 0 50px'
                    }}
                  >
                    <Icon as={FaWhatsapp} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="contact_data.whatsapp"
                    placeholder="WhatsApp"
                    value={formData.contact_data.whatsapp}
                    onChange={handleChange}
                    style={{ borderRadius: '0 50px 50px 0' }}
                  />
                </InputGroup>
              </FormControl>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Telefone
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaPhone} mr="5px" />
                    </InputLeftAddon>
                    {/* <Select
                      value={formData.contact_data.telefone[0].tipo}
                      name="contact_data.telefone[0].tipo"
                      onChange={handleChange}
                      placeholder="Tipo"
                      style={{ bordeRadius: '0px' }}
                    >
                      <option value="fixo">Fixo</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="movel">Móvel</option>
                      <option value="voip">VoIP</option>
                    </Select> */}
                    <Input
                      value={formData.contact_data.telefone[0].numero}
                      name="contact_data.telefone[0].numero"
                      onChange={handleChange}
                      style={{
                        borderRadius: '0 50px 50px 0',
                        paddingInlineEnd: '0'
                      }}
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  País
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaGlobe} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.contact_data.address.pais}
                      name="contact_data.address.pais"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="País"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  UF
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaMapSigns} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.contact_data.address.uf}
                      name="contact_data.address.uf"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="UF"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Município
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaCity} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.contact_data.address.municipio}
                      name="contact_data.address.municipio"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Município"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Bairro
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaMapMarkerAlt} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.contact_data.address.bairro}
                      name="contact_data.address.bairro"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Bairro"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  CEP
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaMapPin} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.contact_data.address.cep}
                      name="contact_data.address.cep"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="CEP"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
            </SimpleGrid>
            <Flex direction="column">
              <FormHelperText fontSize="xs" ml="15px">
                Descrição
              </FormHelperText>
              <Stack>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaFileAlt} mr="5px" />
                  </InputLeftAddon>
                  <Textarea
                    value={formData.contact_data.descricao}
                    name="profile.descricao"
                    onChange={handleChange}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Descrição"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                    style={{ border: 'solid 1px #E0E5F2', borderRadius: '0px 20px 20px 20px' }}
                  />
                </InputGroup>
              </Stack>
            </Flex>

            <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
              Perfil da empresa
            </FormLabel>
            <Stack
              spacing={1}
              style={{ display: 'grid', gap: '10px' }}
              gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
              mb={10}
            >
              {['Cliente', 'Fornecedor'].map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData?.owner_data?.profile?.includes(value)}
                  onChange={handleCheckboxProfileChange}
                >
                  {value}
                </Checkbox>
              ))}
            </Stack>

            <Button
              type="submit"
              fontSize="xs"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              mb="24px"
              borderRadius="50px"
            >
              {contact_id ? 'Atualizar empresa' : 'Adicionar empresa'}
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
    </>
  )
}
