import { createContext, useState, useContext } from 'react'

const PipelineContext = createContext()

export const PipelineProvider = ({ children }) => {
  const [orderedSteps, setOrderedSteps] = useState([])

  return (
    <PipelineContext.Provider value={{ orderedSteps, setOrderedSteps }}>{children}</PipelineContext.Provider>
  )
}

export const usePipelineContext = () => {
  const context = useContext(PipelineContext)
  if (context === undefined) {
    throw new Error('usePipelineContext must be used within a PipelineProvider')
  }
  return context
}
