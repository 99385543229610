export const porteEmpresas = [
  { name: 'MEI', value: 'MEI' },
  { name: 'Microempresa (Até R$ 360mil/ano)', value: 'ME' },
  { name: 'Empresa de Pequeno Porte (R$ 360mil à R$ 4,8mi/ano)', value: 'EPP' },
  { name: 'Média Empresa I (Até R$ 6mi/ano)', value: 'Grupo IV' },
  { name: 'Média Empresa II (R$ 6mi à R$ 20mi/ano)', value: 'Grupo III' },
  { name: 'Grande Empresa I (R$ 20mi à R$ 50mi/ano)', value: 'Grupo II' },
  { name: 'Grande Empresa II (Mais que R$ 50mi/ano)', value: 'Grupo I' }
]

export const situacaoCadastral = [
  { value: 'ATIVA', name: 'Ativa' },
  { value: 'BAIXADA', name: 'Baixada' },
  { value: 'INAPTA', name: 'Inapta' },
  { value: 'SUSPENSA', name: 'Suspensa' },
  { value: 'NULA', name: 'Nula' }
]

export const estadosUF = [
  { value: 'AC', name: 'AC' },
  { value: 'AL', name: 'AL' },
  { value: 'AP', name: 'AP' },
  { value: 'AM', name: 'AM' },
  { value: 'BA', name: 'BA' },
  { value: 'CE', name: 'CE' },
  { value: 'ES', name: 'ES' },
  { value: 'GO', name: 'GO' },
  { value: 'MA', name: 'MA' },
  { value: 'MT', name: 'MT' },
  { value: 'MS', name: 'MS' },
  { value: 'MG', name: 'MG' },
  { value: 'PA', name: 'PA' },
  { value: 'PB', name: 'PB' },
  { value: 'PR', name: 'PR' },
  { value: 'PE', name: 'PE' },
  { value: 'PI', name: 'PI' },
  { value: 'RJ', name: 'RJ' },
  { value: 'RN', name: 'RN' },
  { value: 'RS', name: 'RS' },
  { value: 'RO', name: 'RO' },
  { value: 'RR', name: 'RR' },
  { value: 'SC', name: 'SC' },
  { value: 'SP', name: 'SP' },
  { value: 'SE', name: 'SE' },
  { value: 'TO', name: 'TO' }
]

export const listaMunicipios = [{ value: 'Petropolis', name: 'Petrópolis', uf: 'RJ', habitantes: '125.252' }]

export const naturezasJuridicas = [
  { value: '1015', name: 'Órgão Público do Poder Executivo Federal' },
  { value: '1023', name: 'Órgão Público do Poder Executivo Estadual ou do Distrito Federal' },
  { value: '1031', name: 'Órgão Público do Poder Executivo Municipal' },
  { value: '1040', name: 'Órgão Público do Poder Legislativo Federal' },
  { value: '1058', name: 'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal' },
  { value: '1066', name: 'Órgão Público do Poder Legislativo Municipal' },
  { value: '1074', name: 'Órgão Público do Poder Judiciário Federal' },
  { value: '1082', name: 'Órgão Público do Poder Judiciário Estadual' },
  { value: '1104', name: 'Autarquia Federal' },
  { value: '1112', name: 'Autarquia Estadual ou do Distrito Federal' },
  { value: '1120', name: 'Autarquia Municipal' },
  { value: '1139', name: 'Fundação Pública de Direito Público Federal' },
  { value: '1147', name: 'Fundação Pública de Direito Público Estadual ou do Distrito Federal' },
  { value: '1155', name: 'Fundação Pública de Direito Público Municipal' },
  { value: '1163', name: 'Órgão Público Autônomo Federal' },
  { value: '1171', name: 'Órgão Público Autônomo Estadual ou do Distrito Federal' },
  { value: '1180', name: 'Órgão Público Autônomo Municipal' },
  { value: '1198', name: 'Comissão Polinacional' },
  { value: '1210', name: 'Consórcio Público de Direito Público (Associação Pública)' },
  { value: '1228', name: 'Consórcio Público de Direito Privado' },
  { value: '1236', name: 'Estado ou Distrito Federal' },
  { value: '1244', name: 'Município' },
  { value: '1252', name: 'Fundação Pública de Direito Privado Federal' },
  { value: '1260', name: 'Fundação Pública de Direito Privado Estadual ou do Distrito Federal' },
  { value: '1279', name: 'Fundação Pública de Direito Privado Municipal' },
  { value: '1287', name: 'Fundo Público da Administração Indireta Federal' },
  { value: '1295', name: 'Fundo Público da Administração Indireta Estadual ou do Distrito Federal' },
  { value: '1309', name: 'Fundo Público da Administração Indireta Municipal' },
  { value: '1317', name: 'Fundo Público da Administração Direta Federal' },
  { value: '1325', name: 'Fundo Público da Administração Direta Estadual ou do Distrito Federal' },
  { value: '1333', name: 'Fundo Público da Administração Direta Municipal' },
  { value: '1341', name: 'União' },
  { value: '2011', name: 'Empresa Pública' },
  { value: '2038', name: 'Sociedade de Economia Mista' },
  { value: '2046', name: 'Sociedade Anônima Aberta' },
  { value: '2054', name: 'Sociedade Anônima Fechada' },
  { value: '2062', name: 'Sociedade Empresária Limitada' },
  { value: '2070', name: 'Sociedade Empresária em Nome Coletivo' },
  { value: '2089', name: 'Sociedade Empresária em Comandita Simples' },
  { value: '2097', name: 'Sociedade Empresária em Comandita por Ações' },
  { value: '2100', name: 'Sociedade Mercantil de Capital e Indústria' },
  { value: '2127', name: 'Sociedade em Conta de Participação' },
  { value: '2135', name: 'Empresário (Individual)' },
  { value: '2143', name: 'Cooperativa' },
  { value: '2151', name: 'Consórcio de Sociedades' },
  { value: '2160', name: 'Grupo de Sociedades' },
  { value: '2178', name: 'Estabelecimento, no Brasil, de Sociedade Estrangeira' },
  { value: '2216', name: 'Empresa Domiciliada no Exterior' },
  { value: '2224', name: 'Clube/Fundo de Investimento' },
  { value: '2232', name: 'Sociedade Simples Pura' },
  { value: '2240', name: 'Sociedade Simples Limitada' },
  { value: '2259', name: 'Sociedade Simples em Nome Coletivo' },
  { value: '2267', name: 'Sociedade Simples em Comandita Simples' },
  { value: '2275', name: 'Empresa Binacional' },
  { value: '2283', name: 'Consórcio de Empregadores' },
  { value: '2291', name: 'Consórcio Simples' },
  { value: '2305', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)' },
  { value: '2313', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)' },
  { value: '2321', name: 'Natureza Jurídica Inválida' },
  { value: '2330', name: 'Cooperativas de Consumo' },
  { value: '2348', name: 'Empresa Simples de Inovação' },
  { value: '3034', name: 'Serviço Notarial e Registral (Cartório)' },
  { value: '3069', name: 'Fundação Privada' },
  { value: '3077', name: 'Serviço Social Autônomo' },
  { value: '3085', name: 'Condomínio Edilício' },
  { value: '3107', name: 'Comissão de Conciliação Prévia' },
  { value: '3115', name: 'Entidade de Mediação e Arbitragem' },
  { value: '3131', name: 'Entidade Sindical' },
  { value: '3204', name: 'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras' },
  { value: '3212', name: 'Fundação ou Associação Domiciliada no Exterior' },
  { value: '3220', name: 'Organização Religiosa' },
  { value: '3239', name: 'Comunidade Indígena' },
  { value: '3247', name: 'Fundo Privado' },
  { value: '3255', name: 'Órgão de Direção Nacional de Partido Político' },
  { value: '3263', name: 'Órgão de Direção Regional de Partido Político' },
  { value: '3271', name: 'Órgão de Direção Local de Partido Político' },
  { value: '3280', name: 'Comitê Financeiro de Partido Político' },
  { value: '3298', name: 'Frente Plebiscitária ou Referendária' },
  { value: '3301', name: 'Organização Social (OS)' },
  { value: '3328', name: 'Plano de Benefícios de Previdência Complementar Fechada' },
  { value: '3999', name: 'Associação Privada' },
  { value: '4014', name: 'Empresa Individual Imobiliária' },
  { value: '4090', name: 'Candidato a Cargo Político Eletivo' },
  { value: '4120', name: 'Produtor Rural (Pessoa Física)' },
  { value: '5010', name: 'Organização Internacional' },
  { value: '5029', name: 'Representação Diplomática Estrangeira' },
  { value: '5037', name: 'Outras Instituições Extraterritoriais' },
  { value: '8885', name: 'Natureza Jurídica Não Informada' }
]

export const estadosRegioes = [
  {
    label: 'Norte',
    items: [
      { label: 'Amazonas', value: 'Amazonas' },
      { label: 'Acre', value: 'Acre' },
      { label: 'Rondônia', value: 'Rondonia' },
      { label: 'Roraima', value: 'Roraima' },
      { label: 'Amapá', value: 'Amapa' },
      { label: 'Pará', value: 'Para' },
      { label: 'Tocantins', value: 'Tocantins' }
    ]
  },
  {
    label: 'Nordeste',
    items: [
      { label: 'Maranhão', value: 'Maranhao' },
      { label: 'Piauí', value: 'Piaui' },
      { label: 'Rio Grande do Norte', value: 'Rio Grande do Norte' },
      { label: 'Ceará', value: 'Ceara' },
      { label: 'Paraíba', value: 'Paraíba' },
      { label: 'Bahia', value: 'Bahia' },
      { label: 'Pernambuco', value: 'Pernambuco' },
      { label: 'Alagoas', value: 'Alagoas' },
      { label: 'Sergipe', value: 'Sergipe' }
    ]
  },
  {
    label: 'Centro-oeste',
    items: [
      { label: 'Distrito Federal', value: 'Distrito Federal' },
      { label: 'Goiás', value: 'Goias' },
      { label: 'Mato Grosso', value: 'Mato Grosso' },
      { label: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul' }
    ]
  },
  {
    label: 'Sudeste',
    items: [
      { label: 'Minas Gerais', value: 'Minas Gerais' },
      { label: 'Espírito Santo', value: 'Espirito Santo' },
      { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
      { label: 'São Paulo', value: 'Sao Paulo' }
    ]
  },
  {
    label: 'Sul',
    items: [
      { label: 'Santa Catarina', value: 'Santa Catarina' },
      { label: 'Paraná', value: 'Parana' },
      { label: 'Rio Grande do Sul', value: 'Rio Grande do Sul' }
    ]
  }
]

export const ptBR = {
  firstDayOfWeek: 1,
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar',
  dateFormat: 'dd/mm/yy', // Formato de data
  weekHeader: 'Sem',
  chooseDate: 'Escolher data' // Corrige o erro, adicionando esta chave
}

export const cnaes = [
  { value: '0111301', name: '0111301 - Cultivo de arroz' },
  { value: '0111302', name: '0111302 - Cultivo de milho' },
  { value: '0111303', name: '0111303 - Cultivo de trigo' },
  { value: '0111399', name: '0111399 - Cultivo de outros cereais não especificados anteriormente' },
  { value: '0112101', name: '0112101 - Cultivo de algodão herbáceo' },
  { value: '0112102', name: '0112102 - Cultivo de juta' },
  {
    value: '0112199',
    name: '0112199 - Cultivo de outras fibras de lavoura temporária não especificadas anteriormente'
  },
  { value: '0113000', name: '0113000 - Cultivo de cana-de-açúcar' },
  { value: '0114800', name: '0114800 - Cultivo de fumo' },
  { value: '0115600', name: '0115600 - Cultivo de soja' },
  { value: '0116401', name: '0116401 - Cultivo de amendoim' },
  { value: '0116402', name: '0116402 - Cultivo de girassol' },
  { value: '0116403', name: '0116403 - Cultivo de mamona' },
  {
    value: '0116499',
    name: '0116499 - Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente'
  },
  { value: '0119901', name: '0119901 - Cultivo de abacaxi' },
  { value: '0119902', name: '0119902 - Cultivo de alho' },
  { value: '0119903', name: '0119903 - Cultivo de batata-inglesa' },
  { value: '0119904', name: '0119904 - Cultivo de cebola' },
  { value: '0119905', name: '0119905 - Cultivo de feijão' },
  { value: '0119906', name: '0119906 - Cultivo de mandioca' },
  { value: '0119907', name: '0119907 - Cultivo de melão' },
  { value: '0119908', name: '0119908 - Cultivo de melancia' },
  { value: '0119909', name: '0119909 - Cultivo de tomate rasteiro' },
  {
    value: '0119999',
    name: '0119999 - Cultivo de outras plantas de lavoura temporária não especificadas anteriormente'
  },
  { value: '0121101', name: '0121101 - Horticultura' },
  { value: '0121102', name: '0121102 - Cultivo de morango' },
  { value: '0122900', name: '0122900 - Cultivo de flores e plantas ornamentais' },
  { value: '0131800', name: '0131800 - Cultivo de laranja' },
  { value: '0132600', name: '0132600 - Cultivo de uva' },
  { value: '0133401', name: '0133401 - Cultivo de açaí' },
  { value: '0133402', name: '0133402 - Cultivo de banana' },
  { value: '0133403', name: '0133403 - Cultivo de caju' },
  { value: '0133404', name: '0133404 - Cultivo de cítricos' },
  { value: '0133405', name: '0133405 - Cultivo de coco-da-baía' },
  { value: '0133406', name: '0133406 - Cultivo de guaraná' },
  { value: '0133407', name: '0133407 - Cultivo de maíz' },
  { value: '0133408', name: '0133408 - Cultivo de mamão' },
  { value: '0133409', name: '0133409 - Cultivo de maracujá' },
  { value: '0133410', name: '0133410 - Cultivo de manga' },
  { value: '0133411', name: '0133411 - Cultivo de pêssego' },
  {
    value: '0133499',
    name: '0133499 - Cultivo de frutas de lavoura permanente não especificadas anteriormente'
  },
  { value: '0134200', name: '0134200 - Cultivo de café' },
  { value: '0135100', name: '0135100 - Cultivo de cacau' },
  { value: '0139301', name: '0139301 - Cultivo de chuchu-da-índia' },
  { value: '0139302', name: '0139302 - Cultivo de erva-mate' },
  { value: '0139303', name: '0139303 - Cultivo de pimenta-do-reino' },
  { value: '0139304', name: '0139304 - Cultivo de plantas para condimento' },
  { value: '0139305', name: '0139305 - Cultivo de dendê' },
  { value: '0139306', name: '0139306 - Cultivo de seringueira' },
  {
    value: '0139399',
    name: '0139399 - Cultivo de outras plantas de lavoura permanente não especificadas anteriormente'
  },
  { value: '0141501', name: '0141501 - Produção de sementes certificadas' },
  {
    value: '0141502',
    name: '0141502 - Produção de sementes certificadas de forrageiras para formação de pasto'
  },
  { value: '0142300', name: '0142300 - Produção de mudas e outras formas de propagação vegetal' },
  { value: '0151201', name: '0151201 - Criação de bovinos para corte' },
  { value: '0151202', name: '0151202 - Criação de bovinos para leite' },
  { value: '0151203', name: '0151203 - Criação de bovinos' },
  { value: '0152101', name: '0152101 - Criação de bufalinos' },
  { value: '0152102', name: '0152102 - Criação de equinos' },
  { value: '0152103', name: '0152103 - Criação de asininos e muares' },
  { value: '0153901', name: '0153901 - Criação de caprinos' },
  { value: '0153902', name: '0153902 - Criação de ovinos' },
  { value: '0154700', name: '0154700 - Criação de sonhos' },
  { value: '0155501', name: '0155501 - Criação de frangos para corte' },
  { value: '0155502', name: '0155502 - Produção de pintos de um dia' },
  { value: '0155503', name: '0155503 - Criação de outros galináceos' },
  { value: '0155504', name: '0155504 - Criação de aves' },
  { value: '0155505', name: '0155505 - Produção de ovos' },
  { value: '0159801', name: '0159801 - Apicultura' },
  { value: '0159802', name: '0159802 - Criação de animais de estimação' },
  { value: '0159803', name: '0159803 - Criação de escargot' },
  { value: '0159804', name: '0159804 - Criação de bicho-da-seda' },
  { value: '0159899', name: '0159899 - Criação de outros animais não especificados anteriormente' },
  { value: '0161001', name: '0161001 - Serviço de pulverização e controle de pragas agrícolas' },
  { value: '0161002', name: '0161002 - Serviço de poda de árvores para lavouras' },
  { value: '0161003', name: '0161003 - Serviço de preparação de terreno' },
  { value: '0161099', name: '0161099 - Atividades de apoio à agricultura não especificadas anteriormente' },
  { value: '0162801', name: '0162801 - Serviço de inseminação artificial em animais' },
  { value: '0162802', name: '0162802 - Serviço de tosquiamento de ovinos' },
  { value: '0162803', name: '0162803 - Serviço de manejo de animais' },
  { value: '0162899', name: '0162899 - Atividades de apoio à pecuária não especificadas anteriormente' },
  { value: '0163600', name: '0163600 - Atividades de pós-colheita' },
  { value: '0170900', name: '0170900 - Câmara e serviços relacionados' },
  { value: '0210101', name: '0210101 - Cultivo de eucalipto' },
  { value: '0210102', name: '0210102 - Cultivo de acácia-negra' },
  { value: '0210103', name: '0210103 - Cultivo de pinus' },
  { value: '0210104', name: '0210104 - Cultivo de teca' },
  { value: '0210105', name: '0210105 - Cultivo de espécies madeireiras: acácia-negra, pinus e teca' },
  { value: '0210106', name: '0210106 - Cultivo de mudas em viveiros florestais' },
  { value: '0210107', name: '0210107 - Extração de madeira em florestas plantadas' },
  { value: '0210108', name: '0210108 - Produção de carvão vegetal - florestas plantadas' },
  { value: '0210109', name: '0210109 - Produção de casca de acácia-negra - florestas plantadas' },
  {
    value: '0210199',
    name: '0210199 - Produção de produtos não-madeireiros não especificados anteriormente em florestas plantadas'
  },
  { value: '0220901', name: '0220901 - Extração de madeira em florestas nativas' },
  { value: '0220902', name: '0220902 - Produção de carvão vegetal - florestas nativas' },
  { value: '0220903', name: '0220903 - Coleta de castanha-do-pará em florestas nativas' },
  { value: '0220904', name: '0220904 - Coleta de latex em florestas nativas' },
  { value: '0220905', name: '0220905 - Coleta de palmito em florestas nativas' },
  { value: '0220906', name: '0220906 - Conservação de florestas nativas' },
  {
    value: '0220999',
    name: '0220999 - Coleta de produtos não-madeireiros não especificados anteriormente em florestas nativas'
  },
  { value: '0230600', name: '0230600 - Atividades de apoio à produção florestal' },
  { value: '0311601', name: '0311601 - Pesca de peixes em água salgada' },
  { value: '0311602', name: '0311602 - Pesca de crustáceos e moluscos em água salgada' },
  { value: '0311603', name: '0311603 - Coleta de outros produtos marinhos' },
  { value: '0311604', name: '0311604 - Atividades de apoio à pesca em água salgada' },
  { value: '0312401', name: '0312401 - Pesca de peixes em água doce' },
  { value: '0312402', name: '0312402 - Pesca de crustáceos e moluscos em água doce' },
  { value: '0312403', name: '0312403 - Coleta de outros produtos aquáticos de água doce' },
  { value: '0312404', name: '0312404 - Atividades de apoio à pesca em água doce' },
  { value: '0321301', name: '0321301 - Criação de peixes em água salgada e salobra' },
  { value: '0321302', name: '0321302 - Criação de camarões em água salgada e salobra' },
  { value: '0321303', name: '0321303 - Criação de ostras e mexilhões em água salgada e salobra' },
  { value: '0321304', name: '0321304 - Criação de peixes ornamentais em água salgada e salobra' },
  { value: '0321305', name: '0321305 - Atividades de apoio à aquicultura em água salgada e salobra' },
  {
    value: '0321399',
    name: '0321399 - Cultivos e semicultivos da aquicultura em água salgada e salobra não especificados anteriormente'
  },
  { value: '0322101', name: '0322101 - Criação de peixes em água doce' },
  { value: '0322102', name: '0322102 - Criação de camarões em água doce' },
  { value: '0322103', name: '0322103 - Criação de ostras e mexilhões em água doce' },
  { value: '0322104', name: '0322104 - Criação de peixes ornamentais em água doce' },
  { value: '0322105', name: '0322105 - Ranicultura' },
  { value: '0322106', name: '0322106 - Criação de jacarés' },
  { value: '0322107', name: '0322107 - Atividades de apoio à aquicultura em água doce' },
  {
    value: '0322199',
    name: '0322199 - Cultivos e semicultivos da aquicultura em água doce não especificados anteriormente'
  },
  { value: '0500301', name: '0500301 - Extração de carvão mineral' },
  { value: '0500302', name: '0500302 - Beneficiamento de carvão mineral' },
  { value: '0600001', name: '0600001 - Extração de petróleo e gás natural' },
  { value: '0600002', name: '0600002 - Extração e beneficiamento de xisto' },
  { value: '0600003', name: '0600003 - Extração e beneficiamento de areias betuminosas' },
  { value: '0710301', name: '0710301 - Extratificação de minério de ferro' },
  { value: '0710302', name: '0710302 - Pelotização' },
  { value: '0721901', name: '0721901 - Extrato de minério de alumínio' },
  { value: '0721902', name: '0721902 - Beneficiamento de minério de alumínio' },
  { value: '0722701', name: '0722701 - Extraordinário de minério de estanho' },
  { value: '0722702', name: '0722702 - Beneficiamento de minérios de estanho' },
  { value: '0723501', name: '0723501 - Extração de minério de manganês' },
  { value: '0723502', name: '0723502 - Beneficiamento de minério de manganês' },
  { value: '0724301', name: '0724301 - Extração de minérios de metais preciosos' },
  { value: '0724302', name: '0724302 - Beneficiamento de minério de metais preciosos' },
  { value: '0725100', name: '0725100 - Extração de minerais radioativos' },
  { value: '0729401', name: '0729401 - Extraordinário de minérios de níquel e titânio' },
  { value: '0729402', name: '0729402 - Extratificação de minério de tungstênio' },
  { value: '0729403', name: '0729403 - Extraordinário de minério de níquel' },
  {
    value: '0729404',
    name: '0729404 - Extração de minérios de cobre, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente'
  },
  {
    value: '0729405',
    name: '0729405 - Beneficiamento de minérios de cobre-zinco e outros minerais metálicos não-ferrosos não especificados anteriormente'
  },
  { value: '0810001', name: '0810001 - Extração de ardósia e beneficiamento associado' },
  { value: '0810002', name: '0810002 - Extração de granito e beneficiamento associado' },
  { value: '0810003', name: '0810003 - Extraído de mármore e beneficiamento associado' },
  { value: '0810004', name: '0810004 - Extração de calcário e dolomita e beneficiamento associado' },
  { value: '0810005', name: '0810005 - Extraordinário de gesso e caulim' },
  { value: '0810006', name: '0810006 - Extrato de areia' },
  { value: '0810007', name: '0810007 - Extração de argila e beneficiamento associado' },
  { value: '0810008', name: '0810008 - Extração de saibro e beneficiamento associado' },
  { value: '0810009', name: '0810009 - Extração de basalto e beneficiamento associado' },
  { value: '0810010', name: '0810010 - Beneficiamento de gesso e caulim associado à extração' },
  {
    value: '0810099',
    name: '0810099 - Extração e britamento de pedras e outros materiais para construção e beneficiamento associado'
  },
  { value: '0891600', name: '0891600 - Extração de minerais para fabricação de adubos' },
  { value: '0892401', name: '0892401 - Extração de sal marinho' },
  { value: '0892402', name: '0892402 - Extração de sal-gema' },
  { value: '0892403', name: '0892403 - Refino e outros tratamentos do sal' },
  { value: '0893200', name: '0893200 - Extraordinário de gemas (pedras preciosas e semipreciosas)' },
  { value: '0899101', name: '0899101 - Extraordinário de grafita' },
  { value: '0899102', name: '0899102 - Extração de quartzo' },
  { value: '0899103', name: '0899103 - Extração de amianto' },
  {
    value: '0899199',
    name: '0899199 - Extração de outros minerais não-metálicos não especificados anteriormente'
  },
  { value: '0910600', name: '0910600 - Atividades de apoio à extração de petróleo e gás natural' },
  { value: '0990401', name: '0990401 - Atividades de apoio à extração de minério de ferro' },
  { value: '0990402', name: '0990402 - Atividades de apoio à extração de minerais metálicos não-ferrosos' },
  { value: '0990403', name: '0990403 - Atividades de apoio à extração de minerais não-metálicos' },
  { value: '1011201', name: '1011201 - Frigorífico - abate de bovinos' },
  { value: '1011202', name: '1011202 - Frigorífico - abate de equinos' },
  { value: '1011203', name: '1011203 - Frigorífico - abate de ovinos e caprinos' },
  { value: '1011204', name: '1011204 - Frigorífico - abate de bufalinos' },
  { value: '1011205', name: '1011205 - Matadouro - abate de reses sob contrato - exceto abate de suínos' },
  { value: '1012101', name: '1012101 - Abate de aves' },
  { value: '1012102', name: '1012102 - Abate de pequenos animais' },
  { value: '1012103', name: '1012103 - Frigorífico - abate de suínos' },
  { value: '1012104', name: '1012104 - Matadouro - abate de suínos sob contrato' },
  { value: '1013901', name: '1013901 - Fabricação de produtos de carne' },
  { value: '1013902', name: '1013902 - Preparação de subprodutos do abate' },
  { value: '1020101', name: '1020101 - Preservação de peixes' },
  { value: '1020102', name: '1020102 - Fabricação de conservas de peixes' },
  { value: '1031700', name: '1031700 - Fabricação de conservas de frutas' },
  { value: '1032501', name: '1032501 - Fabricação de conservas de palmito' },
  { value: '1032599', name: '1032599 - Fabricação de conservas de legumes e outros vegetais' },
  { value: '1033301', name: '1033301 - Fabricação de sucos concentrados de frutas' },
  { value: '1033302', name: '1033302 - Fabricação de sucos de frutas exceto concentrados' },
  { value: '1041400', name: '1041400 - Fabricação de óleos vegetais em bruto' },
  { value: '1042200', name: '1042200 - Fabricação de óleos vegetais refinados' },
  {
    value: '1043100',
    name: '1043100 - Fabricação de margarina e outras gorduras vegetais e de óleos não-comestíveis de animais'
  },
  { value: '1051100', name: '1051100 - Preparação do leite' },
  { value: '1052000', name: '1052000 - Fabricação de laticínios' },
  { value: '1053800', name: '1053800 - Fabricação de sorvetes e outros gelados comestíveis' },
  { value: '1061901', name: '1061901 - Beneficiamento de arroz' },
  { value: '1061902', name: '1061902 - Fabricação de produtos do arroz' },
  { value: '1062700', name: '1062700 - Moagem de trigo e fabricação de derivados' },
  { value: '1063500', name: '1063500 - Fabricação de farinha de mandioca e derivados' },
  { value: '1064300', name: '1064300 - Fabricação de farinha de milho e derivados' },
  { value: '1065101', name: '1065101 - Fabricação de amidos e féculas de vegetais' },
  { value: '1065102', name: '1065102 - Fabricação de óleo de milho em bruto' },
  { value: '1065103', name: '1065103 - Fabricação de óleo de milho refinado' },
  { value: '1066000', name: '1066000 - Fabricação de alimentos para animais' },
  {
    value: '1069400',
    name: '1069400 - Moagem e fabricação de produtos de origem vegetal não especificados anteriormente'
  },
  { value: '1071600', name: '1071600 - Fabricação de açúcar em bruto' },
  { value: '1072401', name: '1072401 - Fabricação de açúcar de cana refinado' },
  { value: '1072402', name: '1072402 - Fabricação de açúcar de cereais (dextrose) e de beterraba' },
  { value: '1081301', name: '1081301 - Beneficiamento de café' },
  { value: '1081302', name: '1081302 - Torrefação e moagem de café' },
  { value: '1082100', name: '1082100 - Fabricação de produtos à base de café' },
  { value: '1091100', name: '1091100 - Fabricação de produtos de panificação' },
  { value: '1091101', name: '1091101 - Fabricação de produtos de panificação industrial' },
  {
    value: '1091102',
    name: '1091102 - Fabricação de produtos de padaria e confeitaria com predominância de produção própria'
  },
  { value: '1092900', name: '1092900 - Fabricação de biscoitos e bolachas' },
  { value: '1093701', name: '1093701 - Fabricação de produtos derivados do cacau e de chocolates' },
  { value: '1093702', name: '1093702 - Fabricação de frutas cristalizadas' },
  { value: '1094500', name: '1094500 - Fabricação de massas alimentícias' },
  { value: '1095300', name: '1095300 - Fabricação de especiarias, temperos e condimentos' },
  { value: '1096100', name: '1096100 - Fabricação de alimentos e pratos prontos' },
  { value: '1099601', name: '1099601 - Fabricação de vinagres' },
  { value: '1099602', name: '1099602 - Fabricação de pães alimentícios' },
  { value: '1099603', name: '1099603 - Fabricação de fermentos e leveduras' },
  { value: '1099604', name: '1099604 - Fabricação de gelo comum' },
  { value: '1099605', name: '1099605 - Fabricação de produtos para infusão (chá, etc.)' },
  { value: '1099606', name: '1099606 - Fabricação de adoçantes naturais e artificiais' },
  { value: '1099607', name: '1099607 - Fabricação de alimentos dietéticos e complementos alimentares' },
  {
    value: '1099699',
    name: '1099699 - Fabricação de outros produtos alimentícios não especificados anteriormente'
  },
  { value: '1111901', name: '1111901 - Fabricação de aguardente de cana-de-açúcar' },
  { value: '1111902', name: '1111902 - Fabricação de outras aguardentes e bebidas destiladas' },
  { value: '1112700', name: '1112700 - Fabricação de vinho' },
  { value: '1113501', name: '1113501 - Fabricação de malte' },
  { value: '1113502', name: '1113502 - Fabricação de cervejas e chopes' },
  { value: '1121600', name: '1121600 - Fabricação de águas envasadas' },
  { value: '1122401', name: '1122401 - Fabricação de refrigerantes' },
  { value: '1122402', name: '1122402 - Fabricação de chá mate e outros chás prontos para consumo' },
  { value: '1122403', name: '1122403 - Fabricação de refrescos, exceto refrescos de frutas' },
  { value: '1122404', name: '1122404 - Fabricação de bebidas isotônicas' },
  {
    value: '1122499',
    name: '1122499 - Fabricação de outras bebidas não-alcoólicas não especificadas anteriormente'
  },
  { value: '1210700', name: '1210700 - Processamento industrial do fumo' },
  { value: '1220401', name: '1220401 - Fabricação de cigarros' },
  { value: '1220402', name: '1220402 - Fabricação de cigarrilhas e charutos' },
  { value: '1220403', name: '1220403 - Fabricação de filtros para cigarros' },
  { value: '1220499', name: '1220499 - Fabricação de outros produtos do fumo, cigarrilhas e charutos' },
  { value: '1311100', name: '1311100 - Preparação e fiamento de fibras de algodão' },
  { value: '1312000', name: '1312000 - Preparação e fiação de fibras têxteis naturais' },
  { value: '1313800', name: '1313800 - Fiaçamento de fibras artificiais e sintéticas' },
  { value: '1314600', name: '1314600 - Fabricação de linhas para costurar e bordar' },
  { value: '1321900', name: '1321900 - Tecelagem de fios de algodão' },
  { value: '1322700', name: '1322700 - Tecelagem de fios de fibras têxteis naturais' },
  { value: '1323500', name: '1323500 - Tecelagem de fios de fibras artificiais e sintéticas' },
  { value: '1330800', name: '1330800 - Fabricação de tecidos de malha' },
  {
    value: '1340501',
    name: '1340501 - Estamparia e texturização em fios, artefatos têxteis e peças do vestuário'
  },
  { value: '1340502', name: '1340502 - Alvejamento de tecidos, artefatos têxteis e peças do vestuário' },
  {
    value: '1340599',
    name: '1340599 - Outros serviços de acabamento em fios, artefatos têxteis e peças do vestuário'
  },
  { value: '1351100', name: '1351100 - Fabricação de artefatos têxteis para uso doméstico' },
  { value: '1352900', name: '1352900 - Fabricação de artefatos de tapeçaria' },
  { value: '1353700', name: '1353700 - Fabricação de artefatos de cordoaria' },
  { value: '1354500', name: '1354500 - Fabricação de tecidos especiais' },
  {
    value: '1359600',
    name: '1359600 - Fabricação de outros produtos têxteis não especificados anteriormente'
  },
  { value: '1411801', name: '1411801 - Confecção de roupas íntimas' },
  { value: '1411802', name: '1411802 - Faculdade de roupas íntimas' },
  { value: '1412601', name: '1412601 - Confecção de peças de vestuário' },
  { value: '1412602', name: '1412602 - Confecção de peças do vestuário exceto roupas íntimas' },
  { value: '1412603', name: '1412603 - Faculdade de peças do vestuário' },
  { value: '1413401', name: '1413401 - Confecção de roupas profissionais' },
  { value: '1413402', name: '1413402 - Confecção de roupas profissionais' },
  { value: '1413403', name: '1413403 - Faculdade de roupas profissionais' },
  { value: '1414200', name: '1414200 - Fabricação de acessórios do vestuário' },
  { value: '1421500', name: '1421500 - Fabricação de meias' },
  { value: '1422300', name: '1422300 - Fabricação de artigos do vestuário, exceto meias' },
  { value: '1510600', name: '1510600 - Curtimento e outras preparações de couro' },
  { value: '1521100', name: '1521100 - Fabricação de artigos para viagem' },
  { value: '1529700', name: '1529700 - Fabricação de artefatos de couro não especificados anteriormente' },
  { value: '1531901', name: '1531901 - Fabricação de calçados de couro' },
  { value: '1531902', name: '1531902 - Acabamento de calçados de couro sob contrato' },
  { value: '1532700', name: '1532700 - Fabricação de tênis de qualquer material' },
  { value: '1533500', name: '1533500 - Fabricação de calçados de material sintético' },
  { value: '1539400', name: '1539400 - Fabricação de calçados de materiais não especificados anteriormente' },
  { value: '1540800', name: '1540800 - Fabricação de partes para calçados' },
  { value: '1610201', name: '1610201 - Serrarias com desdobramento de madeira' },
  { value: '1610202', name: '1610202 - Serrarias sem desdobramento de madeira' },
  { value: '1610203', name: '1610203 - Serrarias com desdobramento de madeira em bruto' },
  { value: '1610204', name: '1610204 - Serrarias sem desdobramento de madeira em bruto  -Resserragem' },
  { value: '1610205', name: '1610205 - Serviço de tratamento de madeira realizado sob contrato' },
  { value: '1621800', name: '1621800 - Fabricação de madeira laminada e de chapas de madeira compensada' },
  { value: '1622601', name: '1622601 - Fabricação de casas de madeira pré-fabricadas' },
  {
    value: '1622602',
    name: '1622602 - Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais'
  },
  { value: '1622699', name: '1622699 - Fabricação de outros artigos de carpintaria para construção' },
  { value: '1623400', name: '1623400 - Fabricação de artefatos de tanoaria e de embalagens de madeira' },
  { value: '1629301', name: '1629301 - Fabricação de artefatos diversos de madeira' },
  {
    value: '1629302',
    name: '1629302 - Fabricação de artefatos diversos de cortiça, palha, vime e outros materiais translúcidos, exceto móveis'
  },
  { value: '1710900', name: '1710900 - Fabricação de celulose e outras pastas para a fabricação de papel' },
  { value: '1721400', name: '1721400 - Fabricação de papel' },
  { value: '1722200', name: '1722200 - Fabricação de cartolina e papel-cartão' },
  { value: '1731100', name: '1731100 - Fabricação de embalagens de papel' },
  { value: '1732000', name: '1732000 - Fabricação de embalagens de cartolina e papel-cartão' },
  { value: '1733800', name: '1733800 - Fabricação de chapas e de embalagens de papelão ondulado' },
  { value: '1741901', name: '1741901 - Fabricação de formulários contínuos' },
  {
    value: '1741902',
    name: '1741902 - Fabricação de produtos de papel, papel cartão e papelão ondulado para uso comercial e de escritório, exceto formulário contínuo'
  },
  { value: '1742701', name: '1742701 - Fabricação de fraldas descartáveis' },
  { value: '1742702', name: '1742702 - Fabricação de absorventes higiênicos' },
  {
    value: '1742799',
    name: '1742799 - Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente'
  },
  {
    value: '1749400',
    name: '1749400 - Fabricação de produtos de pastas celulósicas, cartolina, papel-cartão e papelão ondulado não especificados anteriormente'
  },
  { value: '1811301', name: '1811301 - Impressão de jornais' },
  { value: '1811302', name: '1811302 - Impressão de livros' },
  { value: '1812100', name: '1812100 - Impressão de material de segurança' },
  { value: '1813001', name: '1813001 - Impressão de material para uso publicitário' },
  { value: '1813099', name: '1813099 - Impressão de material para outros usos' },
  { value: '1821100', name: '1821100 - Serviços de pré-impressão' },
  { value: '1822900', name: '1822900 - Serviços de acabamentos gráficos' },
  { value: '1822901', name: '1822901 - Serviços de encadernação e plastificação' },
  { value: '1822999', name: '1822999 - Serviços de acabamentos gráficos' },
  { value: '1830001', name: '1830001 - Reprodução de som em qualquer suporte' },
  { value: '1830002', name: '1830002 - Reprodução de vídeo em qualquer suporte' },
  { value: '1830003', name: '1830003 - Reprodução de software em qualquer suporte' },
  { value: '1910100', name: '1910100 - Coquerias' },
  { value: '1921700', name: '1921700 - Fabricação de produtos do refino de petróleo' },
  { value: '1922501', name: '1922501 - Fórmula de combustíveis' },
  { value: '1922502', name: '1922502 - Rerrefino de óleos lubrificantes' },
  { value: '1922599', name: '1922599 - Fabricação de outros produtos derivados do petróleo' },
  { value: '1931400', name: '1931400 - Fabricação de álcool' },
  { value: '1932200', name: '1932200 - Fabricação de biocombustíveis' },
  { value: '2011800', name: '2011800 - Fabricação de cloro e alcalis' },
  { value: '2012600', name: '2012600 - Fabricação de intermediários para fertilizantes' },
  { value: '2013400', name: '2013400 - Fabricação de adubos e fertilizantes' },
  { value: '2013401', name: '2013401 - Fabricação de adubos e fertilizantes organo-minerais' },
  { value: '2013402', name: '2013402 - Fabricação de adubos e fertilizantes' },
  { value: '2014200', name: '2014200 - Fabricação de gases industriais' },
  { value: '2019301', name: '2019301 - Elaboração de combustíveis nucleares' },
  {
    value: '2019399',
    name: '2019399 - Fabricação de outros produtos químicos inorgânicos não especificados anteriormente'
  },
  { value: '2021500', name: '2021500 - Fabricação de produtos petroquímicos básicos' },
  { value: '2022300', name: '2022300 - Fabricação de intermediários para plastificantes' },
  {
    value: '2029100',
    name: '2029100 - Fabricação de produtos químicos orgânicos não especificados anteriormente'
  },
  { value: '2031200', name: '2031200 - Fabricação de resinas termoplásticas' },
  { value: '2032100', name: '2032100 - Fabricação de resinas termofixas' },
  { value: '2033900', name: '2033900 - Fabricação de elastômeros' },
  { value: '2040100', name: '2040100 - Fabricação de fibras artificiais e sintéticas' },
  { value: '2051700', name: '2051700 - Fabricação de defensivos agrícolas' },
  { value: '2052500', name: '2052500 - Fabricação de desinfetantes domissanitários' },
  { value: '2061400', name: '2061400 - Fabricação de sabões e detergentes sintéticos' },
  { value: '2062200', name: '2062200 - Fabricação de produtos de limpeza e polimento' },
  { value: '2063100', name: '2063100 - Fabricação de cosméticos' },
  { value: '2071100', name: '2071100 - Fabricação de tintas, esmaltes e lacas' },
  { value: '2072000', name: '2072000 - Fabricação de tintas de impressão' },
  { value: '2073800', name: '2073800 - Fabricação de impermeabilizantes' },
  { value: '2091600', name: '2091600 - Fabricação de adesivos e selantes' },
  { value: '2092401', name: '2092401 - Fabricação de pólvoras' },
  { value: '2092402', name: '2092402 - Fabricação de artigos pirotécnicos' },
  { value: '2092403', name: '2092403 - Fabricação de fósforos de segurança' },
  { value: '2093200', name: '2093200 - Fabricação de aditivos de uso industrial' },
  { value: '2094100', name: '2094100 - Fabricação de catalisadores' },
  {
    value: '2099101',
    name: '2099101 - Fabricação de chapas papéis e outros materiais e produtos químicos para fotografia'
  },
  {
    value: '2099199',
    name: '2099199 - Fabricação de outros produtos químicos não especificados anteriormente'
  },
  { value: '2110600', name: '2110600 - Fabricação de produtos farmacêuticos' },
  { value: '2121101', name: '2121101 - Fabricação de medicamentos alopáticos para uso humano' },
  { value: '2121102', name: '2121102 - Fabricação de medicamentos homeopáticos para uso humano' },
  { value: '2121103', name: '2121103 - Fabricação de medicamentos fitoterápicos para uso humano' },
  { value: '2122000', name: '2122000 - Fabricação de medicamentos para uso veterinário' },
  { value: '2123800', name: '2123800 - Fabricação de preparações farmacêuticas' },
  { value: '2211100', name: '2211100 - Fabricação de pneumáticos e de câmaras-de-ar' },
  { value: '2212900', name: '2212900 - Reforma de pneumáticos usados' },
  { value: '2219600', name: '2219600 - Fabricação de artefatos de borracha não especificados anteriormente' },
  { value: '2221800', name: '2221800 - Fabricação de laminados planos e tubulares de material plástico' },
  { value: '2222600', name: '2222600 - Fabricação de embalagens de material plástico' },
  {
    value: '2223400',
    name: '2223400 - Fabricação de tubos e acessórios de material plástico para uso na construção'
  },
  {
    value: '2229301',
    name: '2229301 - Fabricação de artefatos de material plástico para uso pessoal e doméstico'
  },
  { value: '2229302', name: '2229302 - Fabricação de artefatos de material plástico para usos industriais' },
  { value: '2229303', name: '2229303 - Fabricação de artefatos de material plástico para uso na construção' },
  {
    value: '2229399',
    name: '2229399 - Fabricação de artefatos de material plástico para outros usos não especificados anteriormente'
  },
  { value: '2311700', name: '2311700 - Fabricação de vidro plano e de segurança' },
  { value: '2312500', name: '2312500 - Fabricação de embalagens de vidro' },
  { value: '2319200', name: '2319200 - Fabricação de artigos de vidro' },
  { value: '2320600', name: '2320600 - Fabricação de cimento' },
  { value: '2330301', name: '2330301 - Fabricação de estruturas pré-moldadas de concreto armado' },
  { value: '2330302', name: '2330302 - Fabricação de artefatos de cimento para uso na construção' },
  { value: '2330303', name: '2330303 - Fabricação de artefatos de fibrocimento para uso na construção' },
  { value: '2330304', name: '2330304 - Fabricação de casas pré-moldadas de concreto' },
  { value: '2330305', name: '2330305 - Preparação de massa de concreto e argamassa para construção' },
  {
    value: '2330399',
    name: '2330399 - Fabricação de outros artefatos e produtos de concreto, fibrocimento, gesso e materiais semelhantes'
  },
  { value: '2341900', name: '2341900 - Fabricação de produtos cerâmicos refratários' },
  { value: '2342701', name: '2342701 - Fabricação de azulejos e pisos' },
  {
    value: '2342702',
    name: '2342702 - Fabricação de artefatos de cerâmica e barro cozido para uso na construção'
  },
  { value: '2349401', name: '2349401 - Fabricação de material sanitário de cerâmica' },
  {
    value: '2349499',
    name: '2349499 - Fabricação de produtos cerâmicos não-refratários não especificados anteriormente'
  },
  { value: '2391501', name: '2391501 - Britamento de pedras' },
  { value: '2391502', name: '2391502 - Aparelhamento de pedras para construção' },
  {
    value: '2391503',
    name: '2391503 - Aparelhamento de placas e execução de trabalhos em marcenaria e outras pedras'
  },
  { value: '2392300', name: '2392300 - Fabricação de cal e gesso' },
  { value: '2399101', name: '2399101 - Decoração e outros trabalhos em cerâmica, louça, vidro e cristal' },
  { value: '2399102', name: '2399102 - Fabricação de abrasivos' },
  {
    value: '2399199',
    name: '2399199 - Fabricação de outros produtos de minerais não-metálicos não especificados anteriormente'
  },
  { value: '2411300', name: '2411300 - Produção de ferro-gusa' },
  { value: '2412100', name: '2412100 - Produção de ferroligas' },
  { value: '2421100', name: '2421100 - Produção de semi-acabados de aço' },
  { value: '2422901', name: '2422901 - Produção de laminados planos de aço ao carbono' },
  { value: '2422902', name: '2422902 - Produção de laminados planos de aços especiais' },
  { value: '2423701', name: '2423701 - Produção de tubos de aço sem costura' },
  { value: '2423702', name: '2423702 - Produção de laminados longos de aço' },
  { value: '2424501', name: '2424501 - Produção de arames de aço' },
  { value: '2424502', name: '2424502 - Produção de relaminados exceto arames' },
  { value: '2431800', name: '2431800 - Produção de tubos de aço com costura' },
  { value: '2439300', name: '2439300 - Produção de outros tubos de ferro e aço' },
  { value: '2441501', name: '2441501 - Produção de alumínio e suas ligas em formas primárias' },
  { value: '2441502', name: '2441502 - Produção de laminados de alumínio' },
  { value: '2442300', name: '2442300 - Metalurgia dos metais preciosos' },
  { value: '2443100', name: '2443100 - Metalurgia do cobre' },
  { value: '2449101', name: '2449101 - Produção de zinco em formas primárias' },
  { value: '2449102', name: '2449102 - Produção de laminados de zinco' },
  { value: '2449103', name: '2449103 - Fabricação de ínodos para galvanoplastia' },
  {
    value: '2449199',
    name: '2449199 - Metalurgia de outros metais não ferrosos e suas ligas não especificados anteriormente'
  },
  { value: '2451200', name: '2451200 - Fundição de ferro e aço' },
  { value: '2452100', name: '2452100 - Fundições de metais não-ferrosos e suas ligas' },
  { value: '2511000', name: '2511000 - Fabricação de estruturas metálicas' },
  { value: '2512800', name: '2512800 - Fabricação de esquadrias de metal' },
  { value: '2513600', name: '2513600 - Fabricação de obras de caldeiraria pesada' },
  { value: '2521700', name: '2521700 - Fabricação de tanques' },
  { value: '2522500', name: '2522500 - Fabricação de caldeiras geradoras de vapor' },
  { value: '2531401', name: '2531401 - Produção de forjados de aço' },
  { value: '2531402', name: '2531402 - Produção de forjados de metais não-ferrosos e suas ligas' },
  { value: '2532201', name: '2532201 - Produção de artefatos estampados de metal' },
  { value: '2532202', name: '2532202 - Metalurgia do pé' },
  { value: '2539000', name: '2539000 - Serviços de usinagem, tratamento e revestimento em metais' },
  { value: '2539001', name: '2539001 - Serviços de usinagem' },
  { value: '2539002', name: '2539002 - Serviços de tratamento e revestimento em metais' },
  { value: '2541100', name: '2541100 - Fabricação de artigos de cutelaria' },
  { value: '2542000', name: '2542000 - Fabricação de artigos de serralheria' },
  { value: '2543800', name: '2543800 - Fabricação de ferramentas' },
  { value: '2550101', name: '2550101 - Fabricação de equipamento bélico pesado' },
  { value: '2550102', name: '2550102 - Fabricação de armas de fogo' },
  { value: '2591800', name: '2591800 - Fabricação de embalagens metálicas' },
  { value: '2592601', name: '2592601 - Fabricação de produtos de trefilados de metal padronizados' },
  { value: '2592602', name: '2592602 - Fabricação de produtos de trefilados de metal' },
  { value: '2593400', name: '2593400 - Fabricação de artigos de metal para uso doméstico e pessoal' },
  { value: '2599301', name: '2599301 - Serviços de confecção de armações metálicas para a construção' },
  { value: '2599302', name: '2599302 - Serviço de corte e dobra de metais' },
  {
    value: '2599399',
    name: '2599399 - Fabricação de outros produtos de metal não especificados anteriormente'
  },
  { value: '2610800', name: '2610800 - Fabricação de componentes eletrônicos' },
  { value: '2621300', name: '2621300 - Fabricação de equipamentos de informática' },
  { value: '2622100', name: '2622100 - Fabricação de periféricos para equipamentos de informática' },
  { value: '2631100', name: '2631100 - Fabricação de equipamentos transmissores de comunicação' },
  {
    value: '2632900',
    name: '2632900 - Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação'
  },
  {
    value: '2640000',
    name: '2640000 - Fabricação de aparelhos de recepção, gravação e amplificação de áudio e vídeo'
  },
  { value: '2651500', name: '2651500 - Fabricação de aparelhos e equipamentos de medida' },
  { value: '2652300', name: '2652300 - Fabricação de cronômetros e relógios' },
  {
    value: '2660400',
    name: '2660400 - Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação'
  },
  { value: '2670101', name: '2670101 - Fabricação de equipamentos e instrumentos ópticos' },
  { value: '2670102', name: '2670102 - Fabricação de aparelhos fotográficos e cinematográficos' },
  { value: '2680900', name: '2680900 - Fabricação de mídias virgens' },
  { value: '2710401', name: '2710401 - Fabricação de geradores de corrente contínua e alternada' },
  {
    value: '2710402',
    name: '2710402 - Fabricação de transformadores conversores sincronizadores e semelhantes peças e acessórios'
  },
  { value: '2710403', name: '2710403 - Fabricação de motores elétricos' },
  { value: '2721000', name: '2721000 - Fabricação de pilhas exceto para veículos automotores' },
  { value: '2722801', name: '2722801 - Fabricação de baterias e acumuladores para veículos automotores' },
  {
    value: '2722802',
    name: '2722802 - Recondicionamento de baterias e acumuladores para veículos automotores'
  },
  {
    value: '2731700',
    name: '2731700 - Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica'
  },
  {
    value: '2732500',
    name: '2732500 - Fabricação de material elétrico para instalações em circuito de consumo'
  },
  { value: '2733300', name: '2733300 - Fabricação de fios' },
  { value: '2740601', name: '2740601 - Fabricação de lâmpadas' },
  { value: '2740602', name: '2740602 - Fabricação de luminárias e outros equipamentos de iluminação' },
  { value: '2751100', name: '2751100 - Fabricação de fogões, peças e acessórios' },
  { value: '2759701', name: '2759701 - Fabricação de aparelhos elétricos de uso pessoal' },
  {
    value: '2759799',
    name: '2759799 - Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente'
  },
  { value: '2790201', name: '2790201 - Fabricação de eletrodos, eletroímãs e isoladores' },
  { value: '2790202', name: '2790202 - Fabricação de equipamentos para sinalização e alarme' },
  {
    value: '2790299',
    name: '2790299 - Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente'
  },
  {
    value: '2811900',
    name: '2811900 - Fabricação de motores e turbinas exceto para aviões e veículos rodoviários'
  },
  {
    value: '2812700',
    name: '2812700 - Fabricação de equipamentos hidráulicos e pneumáticos exceto válvulas'
  },
  { value: '2813500', name: '2813500 - Fabricação de válvulas, peças e acessórios' },
  { value: '2814301', name: '2814301 - Fabricação de compressores para uso industrial' },
  { value: '2814302', name: '2814302 - Fabricação de compressores para uso não industrial' },
  { value: '2815101', name: '2815101 - Fabricação de rolamentos para fins industriais' },
  { value: '2815102', name: '2815102 - Fabricação de equipamentos de transmissão para fins industriais' },
  { value: '2821601', name: '2821601 - Fabricação de fornos industriais, peças e acessórios' },
  { value: '2821602', name: '2821602 - Fabricação de estufas e fornos elétricos para fins industriais' },
  { value: '2822401', name: '2822401 - Fabricação de máquinas, peças e acessórios' },
  { value: '2822402', name: '2822402 - Fabricação de máquinas, peças e acessórios' },
  {
    value: '2823200',
    name: '2823200 - Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial'
  },
  {
    value: '2824101',
    name: '2824101 - Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial'
  },
  {
    value: '2824102',
    name: '2824102 - Fabricação de aparelhos e equipamentos de ar condicionado para uso não industrial'
  },
  {
    value: '2825900',
    name: '2825900 - Fabricação de máquinas e equipamentos para saneamento básico e ambiental'
  },
  { value: '2829101', name: '2829101 - Fabricação de máquinas de escrever, peças e acessórios' },
  {
    value: '2829199',
    name: '2829199 - Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente'
  },
  { value: '2831300', name: '2831300 - Fabricação de tratores agrícolas' },
  { value: '2832100', name: '2832100 - Fabricação de equipamentos para irrigação agrícola' },
  {
    value: '2833000',
    name: '2833000 - Fabricação de máquinas e equipamentos para a agricultura e pecuária exceto para irrigação'
  },
  { value: '2840200', name: '2840200 - Fabricação de máquinas-ferramenta' },
  {
    value: '2851800',
    name: '2851800 - Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo'
  },
  {
    value: '2852600',
    name: '2852600 - Fabricação de outras máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo'
  },
  { value: '2853400', name: '2853400 - Fabricação de tratores exceto agrícolas' },
  {
    value: '2854200',
    name: '2854200 - Fabricação de máquinas e equipamentos para terraplenagem, peças e acessórios, exceto tratores'
  },
  {
    value: '2861500',
    name: '2861500 - Fabricação de máquinas para a indústria metalúrgica exceto máquinas-ferramenta'
  },
  {
    value: '2862300',
    name: '2862300 - Fabricação de máquinas e equipamentos para as indústrias de alimentos, peças e acessórios'
  },
  { value: '2863100', name: '2863100 - Fabricação de máquinas e equipamentos para a indústria têxtil' },
  {
    value: '2864000',
    name: '2864000 - Fabricação de máquinas e equipamentos para as indústrias do vestuário e acessórios'
  },
  {
    value: '2865800',
    name: '2865800 - Fabricação de máquinas e equipamentos para as indústrias de celulose, peças e acessórios'
  },
  { value: '2866600', name: '2866600 - Fabricação de máquinas e equipamentos para a indústria do plástico' },
  {
    value: '2869100',
    name: '2869100 - Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente'
  },
  { value: '2910701', name: '2910701 - Fabricação de automóveis' },
  { value: '2910702', name: '2910702 - Fabricação de chassis com motor para automóveis' },
  { value: '2910703', name: '2910703 - Fabricação de motores para automóveis' },
  { value: '2920401', name: '2920401 - Fabricação de caminhões e ônibus' },
  { value: '2920402', name: '2920402 - Fabricação de motores para caminhões e ônibus' },
  { value: '2930101', name: '2930101 - Fabricação de cabines' },
  { value: '2930102', name: '2930102 - Fabricação de carrocerias para ônibus' },
  { value: '2930103', name: '2930103 - Fabricação de cabines, exceto caminhões e ônibus' },
  {
    value: '2941700',
    name: '2941700 - Fabricação de peças e acessórios para o sistema motor de veículos automotores'
  },
  {
    value: '2942500',
    name: '2942500 - Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores'
  },
  {
    value: '2943300',
    name: '2943300 - Fabricação de peças e acessórios para o sistema de freios de veículos automotores'
  },
  {
    value: '2944100',
    name: '2944100 - Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores'
  },
  {
    value: '2945000',
    name: '2945000 - Fabricação de material elétrico e eletrônico para veículos automotores'
  },
  { value: '2949201', name: '2949201 - Fabricação de bancos e estofados para veículos automotores' },
  {
    value: '2949299',
    name: '2949299 - Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente'
  },
  {
    value: '2950600',
    name: '2950600 - Recondicionamento e recuperação de motores para veículos automotores'
  },
  { value: '3011301', name: '3011301 - Construção de embarcações de grande porte' },
  { value: '3011302', name: '3011302 - Construção de embarcações para uso comercial e para usos especiais' },
  { value: '3012100', name: '3012100 - Construção de embarcações para esporte e lazer' },
  { value: '3031800', name: '3031800 - Fabricação de locomotivas' },
  { value: '3032600', name: '3032600 - Fabricação de peças e acessórios para veículos ferroviários' },
  { value: '3041500', name: '3041500 - Fabricação de aeronaves' },
  { value: '3042300', name: '3042300 - Fabricação de turbinas' },
  { value: '3050400', name: '3050400 - Fabricação de veículos militares de combate' },
  { value: '3091100', name: '3091100 - Fabricação de motocicletas' },
  { value: '3091101', name: '3091101 - Fabricação de motocicletas' },
  { value: '3091102', name: '3091102 - Fabricação de peças e acessórios para motocicletas' },
  { value: '3092000', name: '3092000 - Fabricação de bicicletas e triciclos não-motorizados' },
  {
    value: '3099700',
    name: '3099700 - Fabricação de equipamentos de transporte não especificados anteriormente'
  },
  { value: '3101200', name: '3101200 - Fabricação de móveis com predominância de madeira' },
  { value: '3102100', name: '3102100 - Fabricação de móveis com predominância de metal' },
  { value: '3103900', name: '3103900 - Fabricação de móveis de outros materiais' },
  { value: '3104700', name: '3104700 - Fabricação de colchões' },
  { value: '3211601', name: '3211601 - Lapidário de gemas' },
  { value: '3211602', name: '3211602 - Fabricação de artefatos de joalheria e ourivesaria' },
  { value: '3211603', name: '3211603 - Cunhagem de moedas e medalhas' },
  { value: '3212400', name: '3212400 - Fabricação de bijuterias e artefatos semelhantes' },
  { value: '3220500', name: '3220500 - Fabricação de instrumentos musicais' },
  { value: '3230200', name: '3230200 - Fabricação de artefatos para pesca e esporte' },
  { value: '3240001', name: '3240001 - Fabricação de jogos eletrônicos' },
  { value: '3240002', name: '3240002 - Fabricação de mesas de bilhar' },
  { value: '3240003', name: '3240003 - Fabricação de mesas de bilhar' },
  {
    value: '3240099',
    name: '3240099 - Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente'
  },
  {
    value: '3250701',
    name: '3250701 - Fabricação de instrumentos não-eletrônicos e utensílios para uso médico-odontológico e de laboratório'
  },
  {
    value: '3250702',
    name: '3250702 - Fabricação de mobiliário para uso médico-odontológico e de laboratório'
  },
  {
    value: '3250703',
    name: '3250703 - Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda'
  },
  {
    value: '3250704',
    name: '3250704 - Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral'
  },
  { value: '3250705', name: '3250705 - Fabricação de materiais para medicina e odontologia' },
  { value: '3250706', name: '3250706 - Serviços de prótese dentária' },
  { value: '3250707', name: '3250707 - Fabricação de artigos ópticos' },
  {
    value: '3250708',
    name: '3250708 - Fabricação de artefatos de tecido não tecido para uso odonto-médico-hospitalar'
  },
  { value: '3250709', name: '3250709 - Serviço de laboratório óptico' },
  { value: '3291400', name: '3291400 - Fabricação de escovas' },
  { value: '3292201', name: '3292201 - Fabricação de roupas de proteção e segurança e resistentes a fogo' },
  {
    value: '3292202',
    name: '3292202 - Fabricação de equipamentos e acessórios para segurança pessoal e profissional'
  },
  { value: '3299001', name: '3299001 - Fabricação de guarda-chuvas e similares' },
  { value: '3299002', name: '3299002 - Fabricação de canetas' },
  { value: '3299003', name: '3299003 - Fabricação de letras exceto luminosos' },
  { value: '3299004', name: '3299004 - Fabricação de painéis e letreiros luminosos' },
  { value: '3299005', name: '3299005 - Fabricação de aviamentos para costura' },
  { value: '3299006', name: '3299006 - Fabricação de velas' },
  { value: '3299099', name: '3299099 - Fabricação de produtos diversos não especificados anteriormente' },
  { value: '3311200', name: '3311200 - Manutenção e reparação de tanques, exceto para veículos' },
  { value: '3312101', name: '3312101 - Manutenção e reparação de equipamentos transmissores de comunicação' },
  { value: '3312102', name: '3312102 - Manutenção e reparação de aparelhos e instrumentos de medida' },
  {
    value: '3312103',
    name: '3312103 - Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação'
  },
  { value: '3312104', name: '3312104 - Manutenção e reparação de equipamentos e instrumentos ópticos' },
  { value: '3313901', name: '3313901 - Manutenção e reparação de geradores' },
  { value: '3313902', name: '3313902 - Manutenção e reparação de baterias e acumuladores elétricos' },
  { value: '3313999', name: '3313999 - Manutenção e reparação de máquinas' },
  { value: '3314701', name: '3314701 - Manutenção e reparação de máquinas motrizes não-elétricas' },
  { value: '3314702', name: '3314702 - Manutenção e reparação de equipamentos hidráulicos e pneumáticos' },
  { value: '3314703', name: '3314703 - Manutenção e reparação de válvulas industriais' },
  { value: '3314704', name: '3314704 - Manutenção e reparação de compressores' },
  {
    value: '3314705',
    name: '3314705 - Manutenção e reparação de equipamentos de transmissão para fins industriais'
  },
  { value: '3314706', name: '3314706 - Manutenção e reparação de máquinas' },
  {
    value: '3314707',
    name: '3314707 - Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial'
  },
  { value: '3314708', name: '3314708 - Manutenção e reparação de máquinas' },
  { value: '3314709', name: '3314709 - Manutenção e reparação de máquinas de escrever' },
  {
    value: '3314710',
    name: '3314710 - Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente'
  },
  {
    value: '3314711',
    name: '3314711 - Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária'
  },
  { value: '3314712', name: '3314712 - Manutenção e reparação de tratores agrícolas' },
  { value: '3314713', name: '3314713 - Manutenção e reparação de máquinas-ferramenta' },
  {
    value: '3314714',
    name: '3314714 - Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo'
  },
  {
    value: '3314715',
    name: '3314715 - Manutenção e reparação de máquinas e equipamentos para uso na extração mineral'
  },
  { value: '3314716', name: '3314716 - Manutenção e reparação de tratores' },
  {
    value: '3314717',
    name: '3314717 - Manutenção e reparação de máquinas e equipamentos de terraplenagem, exceto tratores'
  },
  { value: '3314718', name: '3314718 - Manutenção e reparação de máquinas para a indústria metalúrgica' },
  {
    value: '3314719',
    name: '3314719 - Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos'
  },
  {
    value: '3314720',
    name: '3314720 - Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do couro e calçados'
  },
  {
    value: '3314721',
    name: '3314721 - Manutenção e reparação de máquinas e aparelhos para a indústria de celulose'
  },
  {
    value: '3314722',
    name: '3314722 - Manutenção e reparação de máquinas e aparelhos para a indústria do plástico'
  },
  {
    value: '3314799',
    name: '3314799 - Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente'
  },
  { value: '3315500', name: '3315500 - Manutenção e reparação de veículos ferroviários' },
  { value: '3316301', name: '3316301 - Manutenção e reparação de aeronaves' },
  { value: '3316302', name: '3316302 - Manutenção de aeronaves na pista' },
  { value: '3317101', name: '3317101 - Manutenção e reparação de embarcações e estruturas flutuantes' },
  { value: '3317102', name: '3317102 - Manutenção e reparação de embarcações para esporte e lazer' },
  {
    value: '3319800',
    name: '3319800 - Manutenção e reparação de equipamentos e produtos não especificados anteriormente'
  },
  { value: '3321000', name: '3321000 - Instalação de máquinas e equipamentos industriais' },
  { value: '3329501', name: '3329501 - Serviços de montagem de móveis de qualquer material' },
  { value: '3329599', name: '3329599 - Instalação de outros equipamentos não especificados anteriormente' },
  { value: '3511500', name: '3511500 - Geração de energia elétrica' },
  { value: '3511501', name: '3511501 - Geração de energia elétrica' },
  {
    value: '3511502',
    name: '3511502 - Atividades de coordenação e controle da operação da geração e transmissão de energia elétrica'
  },
  { value: '3512300', name: '3512300 - Transmissão de energia elétrica' },
  { value: '3513100', name: '3513100 - Comércio atacadista de energia elétrica' },
  { value: '3514000', name: '3514000 - Distribuição de energia elétrica' },
  { value: '3520401', name: '3520401 - Produção de gás; processamento de gás natural' },
  { value: '3520402', name: '3520402 - Distribuição de combustíveis gasosos por redes urbanas' },
  { value: '3530100', name: '3530100 - Produção e distribuição de vapor' },
  { value: '3600601', name: '3600601 - Captação' },
  { value: '3600602', name: '3600602 - Distribuição de água por caminhões' },
  { value: '3701100', name: '3701100 - Gestão de redes de esgoto' },
  { value: '3702900', name: '3702900 - Atividades relacionadas a esgoto' },
  { value: '3811400', name: '3811400 - Coleta de resíduos não-perigosos' },
  { value: '3812200', name: '3812200 - Coleta de resíduos perigosos' },
  { value: '3821100', name: '3821100 - Tratamento e disposição de resíduos não-perigosos' },
  { value: '3822000', name: '3822000 - Tratamento e disposição de resíduos perigosos' },
  { value: '3831901', name: '3831901 - Recuperação de sucatas de alumínio' },
  { value: '3831999', name: '3831999 - Recuperação de materiais metálicos' },
  { value: '3832700', name: '3832700 - Recuperação de materiais plásticos' },
  { value: '3839401', name: '3839401 - Usinas de compostagem' },
  { value: '3839499', name: '3839499 - Recuperação de materiais não especificados anteriormente' },
  { value: '3900500', name: '3900500 - Descontaminação e outros serviços de gestão de resíduos' },
  { value: '4110700', name: '4110700 - Incorporaçãodeempreendimentosimobiliários' },
  { value: '4120400', name: '4120400 - Construção de edifícios' },
  { value: '4211101', name: '4211101 - Construção de rodovias e ferrovias' },
  { value: '4211102', name: '4211102 - Pintura para sinalização em pistas rodoviárias e aeroportos' },
  { value: '4212000', name: '4212000 - Construção de obras de arte especiais' },
  { value: '4213800', name: '4213800 - Obras de urbanização - ruas' },
  { value: '4221901', name: '4221901 - Construção de barragens e represas para geração de energia elétrica' },
  { value: '4221902', name: '4221902 - Construção de estações e redes de distribuição de energia elétrica' },
  { value: '4221903', name: '4221903 - Manutenção de redes de distribuição de energia elétrica' },
  { value: '4221904', name: '4221904 - Construção de estações e redes de telecomunicações' },
  { value: '4221905', name: '4221905 - Manutenção de estações e redes de telecomunicações' },
  {
    value: '4222701',
    name: '4222701 - Construção de redes de abastecimento de água exceto obras de irrigação'
  },
  { value: '4222702', name: '4222702 - Obras de irrigação' },
  { value: '4223500', name: '4223500 - Construção de redes de transportes por dutos' },
  { value: '4291000', name: '4291000 - Obras portuárias' },
  { value: '4292801', name: '4292801 - Montagem de estruturas metálicas' },
  { value: '4292802', name: '4292802 - Obras de montagem industrial' },
  { value: '4299501', name: '4299501 - Construção de instalações esportivas e recreativas' },
  { value: '4299599', name: '4299599 - Outras obras de engenharia civil não especificadas anteriormente' },
  { value: '4311801', name: '4311801 - Demolição de edifícios e outras estruturas' },
  { value: '4311802', name: '4311802 - Preparação de canteiro e limpeza de terreno' },
  { value: '4312600', name: '4312600 - Perfurações e sondagens' },
  { value: '4313400', name: '4313400 - Obras de terraplenagem' },
  { value: '4319300', name: '4319300 - Serviços de preparação do terreno não especificados anteriormente' },
  { value: '4321500', name: '4321500 - Instalação e manutenção elétrica' },
  { value: '4322301', name: '4322301 - Instalações hidráulicas' },
  { value: '4322302', name: '4322302 - Instalação e manutenção de sistemas centrais de ar condicionado' },
  { value: '4322303', name: '4322303 - Instalações de sistema de prevenção contra incêndio' },
  { value: '4329101', name: '4329101 - Instalação de painéis publicitários' },
  {
    value: '4329102',
    name: '4329102 - Instalação de equipamentos para orientação e navegação marítima, fluvial e lacustre'
  },
  { value: '4329103', name: '4329103 - Instalação de escadas e esteiras rolantes' },
  {
    value: '4329104',
    name: '4329104 - Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas'
  },
  { value: '4329105', name: '4329105 - Tratamentos térmicos' },
  {
    value: '4329199',
    name: '4329199 - Outras obras de instalações em construções não especificadas anteriormente'
  },
  { value: '4330401', name: '4330401 - Impermeabilização em obras de engenharia civil' },
  {
    value: '4330402',
    name: '4330402 - Instalação de porta-objetos, divisórias e armários embutidos de qualquer material'
  },
  { value: '4330403', name: '4330403 - Obras de acabamento em gesso e estuque' },
  { value: '4330404', name: '4330404 - Serviços de pintura de edifícios em geral' },
  { value: '4330405', name: '4330405 - Aplicação de revestimentos e de resinas em interiores e exteriores' },
  { value: '4330499', name: '4330499 - Outras obras de acabamento da construção' },
  { value: '4391600', name: '4391600 - Obras de fundações' },
  { value: '4399101', name: '4399101 - Administração de obras' },
  { value: '4399102', name: '4399102 - Montagem e desmontagem de andaimes e outras estruturas temporárias' },
  { value: '4399103', name: '4399103 - Obras de alvenaria' },
  {
    value: '4399104',
    name: '4399104 - Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras'
  },
  { value: '4399105', name: '4399105 - Perfuração e construção de poços de água' },
  {
    value: '4399199',
    name: '4399199 - Serviços especializados para construção não especificados anteriormente'
  },
  { value: '4511101', name: '4511101 - Comércio a varejo de automóveis' },
  { value: '4511102', name: '4511102 - Comércio a varejo de automóveis' },
  { value: '4511103', name: '4511103 - Comércio por atacado de automóveis' },
  { value: '4511104', name: '4511104 - Comércio por atacado de caminhões novos e usados' },
  { value: '4511105', name: '4511105 - Comércio por atacado de reboques e semi-reboques novos e usados' },
  { value: '4511106', name: '4511106 - Comércio por atacado de ônibus e microônibus novos e usados' },
  {
    value: '4512901',
    name: '4512901 - Representantes comerciais e agentes do comércio de veículos automotores'
  },
  { value: '4512902', name: '4512902 - Comércio sob consignação de veículos automotores' },
  { value: '4520001', name: '4520001 - Serviços de manutenção e reparação mecânica de veículos automotores' },
  {
    value: '4520002',
    name: '4520002 - Serviços de lanternagem ou funilaria e pintura de veículos automotores'
  },
  { value: '4520003', name: '4520003 - Serviços de manutenção e reparação elétrica de veículos automotores' },
  { value: '4520004', name: '4520004 - Serviços de alinhamento e balanceamento de veículos automotores' },
  { value: '4520005', name: '4520005 - Serviços de lavagem' },
  { value: '4520006', name: '4520006 - Serviços de borracharia para veículos automotores' },
  { value: '4520007', name: '4520007 - Serviços de instalação' },
  { value: '4520008', name: '4520008 - Serviços de capotaria' },
  {
    value: '4530701',
    name: '4530701 - Comércio por atacado de peças e acessórios novos para veículos automotores'
  },
  { value: '4530702', name: '4530702 - Comércio por atacado de pneumáticos e câmaras-de-ar' },
  {
    value: '4530703',
    name: '4530703 - Comércio a varejo de peças e acessórios novos para veículos automotores'
  },
  {
    value: '4530704',
    name: '4530704 - Comércio a varejo de peças e acessórios usados para veículos automotores'
  },
  { value: '4530705', name: '4530705 - Comércio a varejo de pneus e câmaras-de-ar' },
  {
    value: '4530706',
    name: '4530706 - Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores'
  },
  { value: '4541201', name: '4541201 - Comércio por atacado de motocicletas e motonetas' },
  {
    value: '4541202',
    name: '4541202 - Comércio por atacado de peças e acessórios para motocicletas e motonetas'
  },
  { value: '4541203', name: '4541203 - Comércio a varejo de motocicletas e motonetas novas' },
  { value: '4541204', name: '4541204 - Comércio a varejo de motocicletas e motonetas usadas' },
  {
    value: '4541205',
    name: '4541205 - Comércio a varejo de peças e acessórios para motocicletas e motonetas'
  },
  {
    value: '4541206',
    name: '4541206 - Comércio a varejo de peças e acessórios novos para motocicletas e motonetas'
  },
  {
    value: '4541207',
    name: '4541207 - Comércio a varejo de peças e acessórios usados para motocicletas e motonetas'
  },
  {
    value: '4542101',
    name: '4542101 - Representantes comerciais e agentes do comércio de motocicletas e motonetas'
  },
  { value: '4542102', name: '4542102 - Comércio sob consignação de motocicletas e motonetas' },
  { value: '4543900', name: '4543900 - Manutenção e reparação de motocicletas e motonetas' },
  {
    value: '4611700',
    name: '4611700 - Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos'
  },
  {
    value: '4612500',
    name: '4612500 - Representantes comerciais e agentes do comércio de combustíveis, produtos siderúrgicos e químicos'
  },
  { value: '4613300', name: '4613300 - Representantes comerciais e agentes do comércio de madeira' },
  {
    value: '4614100',
    name: '4614100 - Representantes comerciais e agentes do comércio de máquinas, embarcações e aeronaves'
  },
  { value: '4615000', name: '4615000 - Representantes comerciais e agentes do comércio de eletrodomésticos' },
  {
    value: '4616800',
    name: '4616800 - Representantes comerciais e agentes do comércio de têxteis e artigos de viagem'
  },
  {
    value: '4617600',
    name: '4617600 - Representantes comerciais e agentes do comércio de produtos alimentícios'
  },
  { value: '4618401', name: '4618401 - Representantes comerciais e agentes do comércio de medicamentos' },
  {
    value: '4618402',
    name: '4618402 - Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares'
  },
  { value: '4618403', name: '4618403 - Representantes comerciais e agentes do comércio de jornais' },
  {
    value: '4618499',
    name: '4618499 - Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente'
  },
  {
    value: '4619200',
    name: '4619200 - Representantes comerciais e agentes do comércio de mercadorias em geral não especializado'
  },
  { value: '4621400', name: '4621400 - Comércio atacadista de café em grão' },
  { value: '4622200', name: '4622200 - Comércio atacadista de soja' },
  { value: '4623101', name: '4623101 - Comércio atacadista de animais vivos' },
  {
    value: '4623102',
    name: '4623102 - Comércio atacadista de couros, peles e outros subprodutos não-comestíveis de origem animal'
  },
  { value: '4623103', name: '4623103 - Comércio atacadista de algodão' },
  { value: '4623104', name: '4623104 - Comércio atacadista de fumo em folha não beneficiado' },
  { value: '4623105', name: '4623105 - Comércio atacadista de cacau' },
  { value: '4623106', name: '4623106 - Comércio atacadista de sementes, plantas e gramas' },
  { value: '4623107', name: '4623107 - Comércio atacadista de sisal' },
  {
    value: '4623108',
    name: '4623108 - Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada'
  },
  { value: '4623109', name: '4623109 - Comércio atacadista de alimentos para animais' },
  {
    value: '4623199',
    name: '4623199 - Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente'
  },
  { value: '4631100', name: '4631100 - Comércio atacadista de leite e laticínios' },
  { value: '4632001', name: '4632001 - Comércio atacadista de cereais e leguminosas beneficiados' },
  { value: '4632002', name: '4632002 - Comércio atacadista de farinhas' },
  {
    value: '4632003',
    name: '4632003 - Comércio atacadista de cereais e leguminosas beneficiados, amidos e féculas com atividade de fracionamento e acondicionamento associada'
  },
  {
    value: '4633801',
    name: '4633801 - Comércio atacadista de frutas, raiz, tubérculos, hortaliças e legumes frescos'
  },
  { value: '4633802', name: '4633802 - Comércio atacadista de aves vivas e ovos' },
  {
    value: '4633803',
    name: '4633803 - Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação'
  },
  { value: '4634601', name: '4634601 - Comércio atacadista de carnes bovinas e suínas e derivados' },
  { value: '4634602', name: '4634602 - Comércio atacadista de aves abatidas e derivados' },
  { value: '4634603', name: '4634603 - Comércio atacadista de pescados e frutos do mar' },
  { value: '4634699', name: '4634699 - Comércio atacadista de carnes e derivados de outros animais' },
  { value: '4635401', name: '4635401 - Comércio atacadista de água mineral' },
  { value: '4635402', name: '4635402 - Comércio atacadista de cerveja' },
  {
    value: '4635403',
    name: '4635403 - Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada'
  },
  { value: '4635499', name: '4635499 - Comércio atacadista de bebidas não especificadas anteriormente' },
  { value: '4636201', name: '4636201 - Comércio atacadista de fumo beneficiado' },
  { value: '4636202', name: '4636202 - Comércio atacadista de cigarros' },
  { value: '4637101', name: '4637101 - Comércio atacadista de café torrado' },
  { value: '4637102', name: '4637102 - Comércio atacadista de açúcar' },
  { value: '4637103', name: '4637103 - Comércio atacadista de óleos e gorduras' },
  { value: '4637104', name: '4637104 - Comércio atacadista de pães, biscoitos e similares' },
  { value: '4637105', name: '4637105 - Comércio atacadista de massas alimentícias' },
  { value: '4637106', name: '4637106 - Comércio atacadista de sorvetes' },
  { value: '4637107', name: '4637107 - Comércio atacadista de chocolates, balas, bombons e semelhantes' },
  {
    value: '4637199',
    name: '4637199 - Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente'
  },
  { value: '4639701', name: '4639701 - Comércio atacadista de produtos alimentícios em geral' },
  { value: '4639702', name: '4639702 - Comércio atacadista de produtos alimentícios em geral' },
  { value: '4641901', name: '4641901 - Comércio atacadista de tecidos' },
  { value: '4641902', name: '4641902 - Comércio atacadista de artigos de cama' },
  { value: '4641903', name: '4641903 - Comércio atacadista de artigos de armarinho' },
  { value: '4642701', name: '4642701 - Comércio atacadista de artigos do vestuário e acessórios' },
  {
    value: '4642702',
    name: '4642702 - Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho'
  },
  { value: '4643501', name: '4643501 - Comércio atacadista de calçados' },
  { value: '4643502', name: '4643502 - Comércio atacadista de bolsas' },
  { value: '4644301', name: '4644301 - Comércio atacadista de medicamentos e drogas de uso humano' },
  { value: '4644302', name: '4644302 - Comércio atacadista de medicamentos e drogas de uso veterinário' },
  {
    value: '4645101',
    name: '4645101 - Comércio atacadista de instrumentos e materiais para uso médico-hospitalar e de laboratórios'
  },
  { value: '4645102', name: '4645102 - Comércio atacadista de prosteses e artigos de ortopedia' },
  { value: '4645103', name: '4645103 - Comércio atacadista de produtos odontológicos' },
  { value: '4646001', name: '4646001 - Comércio atacadista de cosméticos e produtos de perfumaria' },
  { value: '4646002', name: '4646002 - Comércio atacadista de produtos de higiene pessoal' },
  { value: '4647801', name: '4647801 - Comércio atacadista de artigos de escritório e de papelaria' },
  { value: '4647802', name: '4647802 - Comércio atacadista de livros' },
  {
    value: '4649401',
    name: '4649401 - Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico'
  },
  {
    value: '4649402',
    name: '4649402 - Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico'
  },
  { value: '4649403', name: '4649403 - Comércio atacadista de bicicletas' },
  { value: '4649404', name: '4649404 - Comércio atacadista de móveis e artigos de colchoaria' },
  { value: '4649405', name: '4649405 - Comércio atacadista de artigos de tapeçaria; persianas e cortinas' },
  { value: '4649406', name: '4649406 - Comércio atacadista de lustres' },
  { value: '4649407', name: '4649407 - Comércio atacadista de filmes, DVDs, fitas e discos' },
  { value: '4649408', name: '4649408 - Comércio atacadista de produtos de higiene' },
  {
    value: '4649409',
    name: '4649409 - Comércio atacadista de produtos de higiene com atividade de fracionamento e acondicionamento associada'
  },
  {
    value: '4649410',
    name: '4649410 - Comércio atacadista de jóias, inclusive pedras preciosas e semipreciosas lapidadas'
  },
  {
    value: '4649499',
    name: '4649499 - Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente'
  },
  { value: '4651601', name: '4651601 - Comércio atacadista de equipamentos de informática' },
  { value: '4651602', name: '4651602 - Comércio atacadista de suprimentos para informática' },
  {
    value: '4652400',
    name: '4652400 - Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação'
  },
  { value: '4661300', name: '4661300 - Comércio atacadista de máquinas' },
  {
    value: '4662100',
    name: '4662100 - Comércio atacadista de máquinas, mineração e construção; partes e peças'
  },
  {
    value: '4663000',
    name: '4663000 - Comércio atacadista de Máquinas e equipamentos para uso industrial; partes e peças'
  },
  { value: '4664800', name: '4664800 - Comércio atacadista de máquinas' },
  {
    value: '4665600',
    name: '4665600 - Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças'
  },
  { value: '4669901', name: '4669901 - Comércio atacadista de bombas e compressores; partes e peças.' },
  {
    value: '4669999',
    name: '4669999 - Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente; partes e peças.'
  },
  { value: '4671100', name: '4671100 - Comércio atacadista de madeira e produtos derivados' },
  { value: '4672900', name: '4672900 - Comércio atacadista de ferragens e ferramentas' },
  { value: '4673700', name: '4673700 - Comércio atacadista de material elétrico' },
  { value: '4674500', name: '4674500 - Comércio atacadista de cimento' },
  { value: '4679601', name: '4679601 - Comércio atacadista de tintas' },
  { value: '4679602', name: '4679602 - Comércio atacadista de mármores e granitos' },
  { value: '4679603', name: '4679603 - Comércio atacadista de vidros vitriais e molduras' },
  {
    value: '4679604',
    name: '4679604 - Comércio atacadista especializado de materiais de construção não especificados anteriormente'
  },
  { value: '4679699', name: '4679699 - Comércio atacadista de materiais de construção em geral' },
  {
    value: '4681801',
    name: '4681801 - Comércio atacadista de álcool carburante, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador.'
  },
  {
    value: '4681802',
    name: '4681802 - Comércio atacadista de combustíveis realizado por transportador retalhista (T.R.R.)'
  },
  { value: '4681803', name: '4681803 - Comércio atacadista de combustíveis de origem vegetal' },
  { value: '4681804', name: '4681804 - Comércio atacadista de combustíveis de origem mineral em bruto' },
  { value: '4681805', name: '4681805 - Comércio atacadista de lubrificantes' },
  { value: '4682600', name: '4682600 - Comércio atacadista de gás liquefeito de petróleo (GLP)' },
  {
    value: '4683400',
    name: '4683400 - Comércio atacadista de defensivos agrícolas, fertilizantes e corretivos do solo'
  },
  { value: '4684201', name: '4684201 - Comércio atacadista de resinas e elastômeros' },
  { value: '4684202', name: '4684202 - Comércio atacadista de solventes' },
  {
    value: '4684299',
    name: '4684299 - Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente'
  },
  { value: '4685100', name: '4685100 - Comércio atacadista de produtos siderúrgicos e metalúrgicos' },
  { value: '4686901', name: '4686901 - Comércio atacadista de papel e papelão em bruto' },
  { value: '4686902', name: '4686902 - Comércio atacadista de embalagens' },
  { value: '4687701', name: '4687701 - Comércio atacadista de resíduos de papel e papelão' },
  { value: '4687702', name: '4687702 - Comércio atacadista de resíduos e sucatas não-metálicos' },
  { value: '4687703', name: '4687703 - Comércio atacadista de resíduos e sucatas metálicos' },
  { value: '4689301', name: '4689301 - Comércio atacadista de produtos da extração mineral' },
  { value: '4689302', name: '4689302 - Comércio atacadista de fios e fibras beneficiados' },
  {
    value: '4689399',
    name: '4689399 - Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente'
  },
  { value: '4691500', name: '4691500 - Comércio atacadista de mercadorias em geral' },
  { value: '4692300', name: '4692300 - Comércio atacadista de mercadorias em geral' },
  { value: '4693100', name: '4693100 - Comércio atacadista de mercadorias em geral' },
  { value: '4711301', name: '4711301 - Comércio varejista de mercadorias em geral' },
  { value: '4711302', name: '4711302 - Comércio varejista de mercadorias em geral' },
  { value: '4712100', name: '4712100 - Comércio varejista de mercadorias em geral, mercearias e armazéns' },
  { value: '4713001', name: '4713001 - Lojas de departamentos ou magazines' },
  { value: '4713002', name: '4713002 - Lojas de variedades' },
  { value: '4713003', name: '4713003 - Lojas duty free de aeroportos internacionais' },
  { value: '4713004', name: '4713004 - Lojas de departamentos ou magazines' },
  { value: '4713005', name: '4713005 - Lojas francas (Duty free) de aeroportos' },
  { value: '4721101', name: '4721101 - Padaria e confeitaria com predominância de produção própria' },
  { value: '4721102', name: '4721102 - Padaria e confeitaria com predominância de revenda' },
  { value: '4721103', name: '4721103 - Comércio varejista de laticínios e frios' },
  { value: '4721104', name: '4721104 - Comércio varejista de doces, bombons e semelhantes' },
  { value: '4722901', name: '4722901 - Comércio varejista de carnes - açougues' },
  { value: '4722902', name: '4722902 - Peixaria' },
  { value: '4723700', name: '4723700 - Comércio varejista de bebidas' },
  { value: '4724500', name: '4724500 - Comércio varejista de hortifrutigranjeiros' },
  { value: '4729601', name: '4729601 - Tabacaria' },
  { value: '4729602', name: '4729602 - Comércio varejista de mercadorias em lojas de conveniência' },
  {
    value: '4729699',
    name: '4729699 - Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente'
  },
  { value: '4731800', name: '4731800 - Comércio varejista de combustíveis para veículos automotores' },
  { value: '4732600', name: '4732600 - Comércio varejista de lubrificantes' },
  { value: '4741500', name: '4741500 - Comércio varejista de tintas e materiais para pintura' },
  { value: '4742300', name: '4742300 - Comércio varejista de material elétrico' },
  { value: '4743100', name: '4743100 - Comércio varejista de vidros' },
  { value: '4744001', name: '4744001 - Comércio varejista de ferragens e ferramentas' },
  { value: '4744002', name: '4744002 - Comércio varejista de madeira e artefatos' },
  { value: '4744003', name: '4744003 - Comércio varejista de materiais hidráulicos' },
  { value: '4744004', name: '4744004 - Comércio varejista de calçada britada, tijolos e telhas' },
  {
    value: '4744005',
    name: '4744005 - Comércio varejista de materiais de construção não especificados anteriormente'
  },
  { value: '4744006', name: '4744006 - Comércio varejista de pedras para revestimento' },
  { value: '4744099', name: '4744099 - Comércio varejista de materiais de construção em geral' },
  {
    value: '4751200',
    name: '4751200 - Comércio varejista especializado de equipamentos e suprimentos de informática'
  },
  {
    value: '4751201',
    name: '4751201 - Comércio varejista especializado de equipamentos e suprimentos de informática'
  },
  { value: '4751202', name: '4751202 - Recarga de cartuchos para equipamentos de informática' },
  {
    value: '4752100',
    name: '4752100 - Comércio varejista especializado de equipamentos de telefonia e comunicação'
  },
  {
    value: '4753900',
    name: '4753900 - Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo'
  },
  { value: '4754701', name: '4754701 - Comércio varejista de móveis' },
  { value: '4754702', name: '4754702 - Comércio varejista de artigos de colchoaria' },
  { value: '4754703', name: '4754703 - Comércio varejista de artigos de iluminação' },
  { value: '4755501', name: '4755501 - Comércio varejista de tecidos' },
  { value: '4755502', name: '4755502 - Comercio varejista de artigos de armarinho' },
  { value: '4755503', name: '4755503 - Comercio varejista de artigos de cama' },
  {
    value: '4756300',
    name: '4756300 - Comércio varejista especializado de instrumentos musicais e acessórios'
  },
  {
    value: '4757100',
    name: '4757100 - Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico'
  },
  { value: '4759801', name: '4759801 - Comércio varejista de artigos de tapeçaria' },
  {
    value: '4759899',
    name: '4759899 - Comércio varejista de outros artigos de uso pessoal e doméstico não especificados anteriormente'
  },
  { value: '4761001', name: '4761001 - Comércio varejista de livros' },
  { value: '4761002', name: '4761002 - Comércio varejista de jornais e revistas' },
  { value: '4761003', name: '4761003 - Comércio varejista de artigos de papelaria' },
  { value: '4762800', name: '4762800 - Comércio varejista de discos, DVDs e fitas' },
  { value: '4763601', name: '4763601 - Comércio varejista de brinquedos e artigos recreativos' },
  { value: '4763602', name: '4763602 - Comércio varejista de artigos esportivos' },
  { value: '4763603', name: '4763603 - Comércio varejista de bicicletas e triciclos; peças e acessórios' },
  { value: '4763604', name: '4763604 - Comércio varejista de artigos de caça' },
  {
    value: '4763605',
    name: '4763605 - Comércio varejista de embarcações e outros veículos recreativos; peças e acessórios'
  },
  { value: '4771701', name: '4771701 - Comércio varejista de produtos farmacêuticos' },
  { value: '4771702', name: '4771702 - Comércio varejista de produtos farmacêuticos' },
  { value: '4771703', name: '4771703 - Comércio varejista de produtos farmacêuticos homeopáticos' },
  { value: '4771704', name: '4771704 - Comércio varejista de medicamentos veterinários' },
  { value: '4772500', name: '4772500 - Comércio varejista de cosméticos' },
  { value: '4773300', name: '4773300 - Comércio varejista de artigos médicos e ortopédicos' },
  { value: '4774100', name: '4774100 - Comércio varejista de artigos de óptica' },
  { value: '4781400', name: '4781400 - Comércio varejista de artigos do vestuário e acessórios' },
  { value: '4782201', name: '4782201 - Comércio varejista de calçados' },
  { value: '4782202', name: '4782202 - Comércio varejista de artigos de viagem' },
  { value: '4783101', name: '4783101 - Comércio varejista de artigos de joalheria' },
  { value: '4783102', name: '4783102 - Comércio varejista de artigos de relojoaria' },
  { value: '4784900', name: '4784900 - Comércio varejista de gás liquefeito de petróleo (GLP)' },
  { value: '4785701', name: '4785701 - Comércio varejista de antiguidades' },
  { value: '4785799', name: '4785799 - Comércio varejista de outros artigos usados' },
  { value: '4789001', name: '4789001 - Comércio varejista de suvenires' },
  { value: '4789002', name: '4789002 - Comércio varejista de plantas e flores naturais' },
  { value: '4789003', name: '4789003 - Comércio varejista de objetos de arte' },
  {
    value: '4789004',
    name: '4789004 - Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação'
  },
  { value: '4789005', name: '4789005 - Comércio varejista de produtos saneantes domissanitários' },
  { value: '4789006', name: '4789006 - Comércio varejista de fogos de artifício e artigos pirotécnicos' },
  { value: '4789007', name: '4789007 - Comércio varejista de equipamentos para escritório' },
  { value: '4789008', name: '4789008 - Comércio varejista de artigos fotográficos e para filmagem' },
  { value: '4789009', name: '4789009 - Comércio varejista de armas e munições' },
  {
    value: '4789099',
    name: '4789099 - Comércio varejista de outros produtos não especificados anteriormente'
  },
  { value: '4911600', name: '4911600 - Transporte ferroviário de carga' },
  {
    value: '4912401',
    name: '4912401 - Transporte ferroviário de passageiros intermunicipal e interestadual'
  },
  {
    value: '4912402',
    name: '4912402 - Transporte ferroviário de passageiros municipal e em região metropolitana'
  },
  { value: '4912403', name: '4912403 - Transporte metroviário' },
  { value: '4921301', name: '4921301 - Transporte rodoviário coletivo de passageiros municipal' },
  {
    value: '4921302',
    name: '4921302 - Transporte rodoviário coletivo de passageiros intermunicipal em região metropolitana'
  },
  {
    value: '4922101',
    name: '4922101 - Transporte rodoviário coletivo de passageiros intermunicipal exceto em região metropolitana'
  },
  { value: '4922102', name: '4922102 - Transporte rodoviário coletivo de passageiros interestadual' },
  { value: '4922103', name: '4922103 - Transporte rodoviário coletivo de passageiros internacional' },
  { value: '4923001', name: '4923001 - Serviço de táxi' },
  {
    value: '4923002',
    name: '4923002 - Serviço de transporte de passageiros - locação de automóveis com motorista'
  },
  { value: '4924800', name: '4924800 - Transporte escolar' },
  { value: '4929901', name: '4929901 - Transporte rodoviário coletivo de passageiros municipal' },
  {
    value: '4929902',
    name: '4929902 - Transporte rodoviário coletivo de passageiros intermunicipal interestadual e internacional'
  },
  { value: '4929903', name: '4929903 - Organização de excursões em veículos rodoviários próprios' },
  {
    value: '4929904',
    name: '4929904 - Organização de excursões em veículos rodoviários próprios interestadual e internacional'
  },
  {
    value: '4929999',
    name: '4929999 - Outros transportes rodoviários de passageiros não especificados anteriormente'
  },
  { value: '4930201', name: '4930201 - Transporte rodoviário de carga municipal.' },
  {
    value: '4930202',
    name: '4930202 - Transporte rodoviário de carga intermunicipal, interestadual e internacional'
  },
  { value: '4930203', name: '4930203 - Transporte rodoviário de produtos perigosos' },
  { value: '4930204', name: '4930204 - Transporte rodoviário de mudanças' },
  { value: '4940000', name: '4940000 - Transporte dutoviário' },
  { value: '4950700', name: '4950700 - Trens turísticos' },
  { value: '5011401', name: '5011401 - Transporte marítimo de cabotagem - Carga' },
  { value: '5011402', name: '5011402 - Transporte marítimo de cabotagem - passageiros' },
  { value: '5012201', name: '5012201 - Transporte marítimo de longo curso - Carga' },
  { value: '5012202', name: '5012202 - Transporte marítimo de longo curso - Passageiros' },
  { value: '5021101', name: '5021101 - Transporte por navegação interior de carga exceto travessia' },
  {
    value: '5021102',
    name: '5021102 - Transporte por navegação interior de carga interestadual e internacional, exceto travessia'
  },
  {
    value: '5022001',
    name: '5022001 - Transporte por navegação interior de passageiros em linhas regulares exceto travessia'
  },
  {
    value: '5022002',
    name: '5022002 - Transporte por navegação interior de passageiros em linhas regulares interestaduais e internacionais, exceto travessia.'
  },
  { value: '5030101', name: '5030101 - Navegação de apoio marítimo' },
  { value: '5030102', name: '5030102 - Navegação de apoio portuário' },
  { value: '5030103', name: '5030103 - Serviço de rebocadores e empurradores' },
  { value: '5091201', name: '5091201 - Transporte por navegação de travessia' },
  { value: '5091202', name: '5091202 - Transporte por navegação de travessia intermunicipal' },
  { value: '5099801', name: '5099801 - Transporte aquaviário para passeios turísticos' },
  { value: '5099899', name: '5099899 - Outros transportes aquaviários não especificados anteriormente' },
  { value: '5111100', name: '5111100 - Transporte aéreo de passageiros regular' },
  { value: '5112901', name: '5112901 - Serviço de táxi aéreo e locação de aeronaves com tripulação' },
  { value: '5112999', name: '5112999 - Outros serviços de transporte aéreo de passageiros não-regular' },
  { value: '5120000', name: '5120000 - Transporte aéreo de carga' },
  { value: '5130700', name: '5130700 - Transporte espacial' },
  { value: '5211701', name: '5211701 - Armazéns gerais - emissão de warrant' },
  { value: '5211702', name: '5211702 - Guarda-móveis' },
  { value: '5211799', name: '5211799 - Depósitos de mercadorias para terceiros' },
  { value: '5212500', name: '5212500 - Carga e descarga' },
  { value: '5221400', name: '5221400 - Concessões rodoviárias e serviços relacionados' },
  { value: '5222200', name: '5222200 - Terminais rodoviários e ferroviários' },
  { value: '5223100', name: '5223100 - Estacionamento de veículos' },
  { value: '5229001', name: '5229001 - Serviços de apoio ao transporte por táxi' },
  { value: '5229002', name: '5229002 - Serviços de reboque de veículos' },
  {
    value: '5229099',
    name: '5229099 - Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente'
  },
  { value: '5231101', name: '5231101 - Administração da infraestrutura portuária' },
  { value: '5231102', name: '5231102 - Atividades do Operador Portuário' },
  { value: '5231103', name: '5231103 - Gestão de terminais aquaviários' },
  { value: '5232000', name: '5232000 - Atividades de agenciamento marítimo' },
  {
    value: '5239700',
    name: '5239700 - Atividades auxiliares dos transportes aquaviários não especificadas anteriormente'
  },
  { value: '5239701', name: '5239701 - Serviços de praticagem' },
  {
    value: '5239799',
    name: '5239799 - Atividades auxiliares dos transportes aquaviários não especificadas anteriormente'
  },
  { value: '5240101', name: '5240101 - Operação dos aeroportos e campos de aterrissagem' },
  { value: '5240199', name: '5240199 - Atividades auxiliares dos transportes aéreos' },
  { value: '5250801', name: '5250801 - Comissaria de despachos' },
  { value: '5250802', name: '5250802 - Atividades de despachantes aduaneiros' },
  { value: '5250803', name: '5250803 - Agenciamento de cargas' },
  { value: '5250804', name: '5250804 - Organização logística do transporte de carga' },
  { value: '5250805', name: '5250805 - Operador de transporte multimodal - OTM' },
  { value: '5310501', name: '5310501 - Atividades do Correio Nacional' },
  { value: '5310502', name: '5310502 - Atividades de franqueadas do Correio Nacional' },
  { value: '5320201', name: '5320201 - Serviços de malote não realizados pelo Correio Nacional' },
  { value: '5320202', name: '5320202 - Serviços de entrega rápida' },
  { value: '5510801', name: '5510801 - Hotéis' },
  { value: '5510802', name: '5510802 - Apart-hotel' },
  { value: '5510803', name: '5510803 - Motociclistas' },
  { value: '5590601', name: '5590601 - Albergues' },
  { value: '5590602', name: '5590602 - Campings' },
  { value: '5590603', name: '5590603 - Pensees (alojamento)' },
  { value: '5590699', name: '5590699 - Outros alojamentos não especificados anteriormente' },
  { value: '5611201', name: '5611201 - Restaurantes e similares' },
  { value: '5611202', name: '5611202 - Bares e outros estabelecimentos especializados em servir bebidas' },
  { value: '5611203', name: '5611203 - Lanchonetesde sucos e similares"' },
  { value: '5611204', name: '5611204 - Bares e outros estabelecimentos especializados em servir bebidas' },
  { value: '5611205', name: '5611205 - Bares e outros estabelecimentos especializados em servir bebidas' },
  { value: '5612100', name: '5612100 - Serviços ambulantes de alimentação' },
  {
    value: '5620101',
    name: '5620101 - Fornecimento de alimentos preparados preponderantemente para empresas'
  },
  { value: '5620102', name: '5620102 - Serviços de alimentação para eventos e recepções - bufê' },
  { value: '5620103', name: '5620103 - Cantinas - serviços de alimentação privativos' },
  {
    value: '5620104',
    name: '5620104 - Fornecimento de alimentos preparados preponderantemente para consumo domiciliar'
  },
  { value: '5811500', name: '5811500 - Edição de livros' },
  { value: '5812300', name: '5812300 - Edição de jornais' },
  { value: '5812301', name: '5812301 - Edição de jornais diários' },
  { value: '5812302', name: '5812302 - Edição de jornais não diários' },
  { value: '5813100', name: '5813100 - Edição de revistas' },
  { value: '5819100', name: '5819100 - Edição de cadastros' },
  { value: '5821200', name: '5821200 - Edição integrada à impressão de livros' },
  { value: '5822100', name: '5822100 - Edição integrada à impressão de jornais' },
  { value: '5822101', name: '5822101 - Edição integrada na impressão de jornais diários' },
  { value: '5822102', name: '5822102 - Edição integrada à impressão de jornais não diários' },
  { value: '5823900', name: '5823900 - Edição integrada à impressão de revistas' },
  { value: '5829800', name: '5829800 - Edição integrada na impressão de cadastros' },
  { value: '5911101', name: '5911101 - Estúdios cinematográficos' },
  { value: '5911102', name: '5911102 - Produção de filmes para publicidade' },
  { value: '5911199', name: '5911199 - Atividades de produção cinematográfica' },
  { value: '5912001', name: '5912001 - Serviços de dublagem' },
  { value: '5912002', name: '5912002 - Serviços de mixagem sonora em produção audiovisual' },
  { value: '5912099', name: '5912099 - Atividades de pós-produção cinematográfica' },
  { value: '5913800', name: '5913800 - Distribuição cinematográfica' },
  { value: '5914600', name: '5914600 - Atividades de exibição cinematográfica' },
  { value: '5920100', name: '5920100 - Atividades de gravação de som e de edição de música' },
  { value: '6010100', name: '6010100 - Atividades de rádio' },
  { value: '6021700', name: '6021700 - Atividades de televisão aberta' },
  { value: '6022501', name: '6022501 - Programadoras' },
  { value: '6022502', name: '6022502 - Atividades relacionadas à televisão por assinatura' },
  { value: '6110801', name: '6110801 - Serviços de telefonia fixa comutada - STFC' },
  { value: '6110802', name: '6110802 - Serviços de redes de transportes de telecomunicações - SRTT' },
  { value: '6110803', name: '6110803 - Serviços de comunicação multimídia - SCM' },
  {
    value: '6110899',
    name: '6110899 - Serviços de telecomunicações por fio não especificados anteriormente'
  },
  { value: '6120501', name: '6120501 - Telefonia móvel celular' },
  { value: '6120502', name: '6120502 - Serviço móvel especializado - SME' },
  {
    value: '6120599',
    name: '6120599 - Serviços de telecomunicações sem fio não especificados anteriormente'
  },
  { value: '6130200', name: '6130200 - Telecomunicações por satélite' },
  { value: '6141800', name: '6141800 - Operadoras de televisão por assinatura por cabo' },
  { value: '6142600', name: '6142600 - Operadoras de televisão por assinatura por microondas' },
  { value: '6143400', name: '6143400 - Operadoras de televisão por assinatura por satélite' },
  { value: '6190601', name: '6190601 - Provedores de acesso às redes de comunicações' },
  { value: '6190602', name: '6190602 - Provedores de voz sobre protocolo internet - VOIP' },
  {
    value: '6190699',
    name: '6190699 - Outras atividades de telecomunicações não especificadas anteriormente'
  },
  { value: '6201500', name: '6201500 - Desenvolvimento de programas de computador sob encomenda' },
  { value: '6201501', name: '6201501 - Desenvolvimento de programas de computador sob encomenda' },
  { value: '6201502', name: '6201502 - Web design' },
  {
    value: '6202300',
    name: '6202300 - Desenvolvimento e licenciamento de programas de computador customizáveis'
  },
  {
    value: '6203100',
    name: '6203100 - Desenvolvimento e licenciamento de programas de computador não-customizáveis'
  },
  { value: '6204000', name: '6204000 - Consultoria em tecnologia da informação' },
  { value: '6209100', name: '6209100 - Suporte técnico' },
  { value: '6311900', name: '6311900 - Tratamento de dados' },
  { value: '6319400', name: '6319400 - Portais' },
  { value: '6391700', name: '6391700 - Agências de notícias' },
  {
    value: '6399200',
    name: '6399200 - Outras atividades de prestação de serviços de informação não especificadas anteriormente'
  },
  { value: '6410700', name: '6410700 - Banco Central' },
  { value: '6421200', name: '6421200 - Bancos comerciais' },
  { value: '6422100', name: '6422100 - Bancos múltiplos' },
  { value: '6423900', name: '6423900 - Caixas econômicas' },
  { value: '6424701', name: '6424701 - Bancos cooperativos' },
  { value: '6424702', name: '6424702 - Cooperativas centrais de crédito' },
  { value: '6424703', name: '6424703 - Cooperativas de crédito mútuo' },
  { value: '6424704', name: '6424704 - Cooperativas de crédito rural' },
  { value: '6431000', name: '6431000 - Bancos múltiplos' },
  { value: '6432800', name: '6432800 - Bancos de investimento' },
  { value: '6433600', name: '6433600 - Bancos de desenvolvimento' },
  { value: '6434400', name: '6434400 - Agências de fomento' },
  { value: '6435201', name: '6435201 - Sociedades de crédito imobiliário' },
  { value: '6435202', name: '6435202 - Associações de poupança e empréstimo' },
  { value: '6435203', name: '6435203 - Companhias hipotecárias' },
  { value: '6436100', name: '6436100 - Sociedades de crédito' },
  { value: '6437900', name: '6437900 - Sociedades de crédito ao microempreendedor' },
  { value: '6438701', name: '6438701 - Bancos de câmbio' },
  {
    value: '6438799',
    name: '6438799 - Outras instituições de intermediação não-monetária não especificadas anteriormente'
  },
  { value: '6440900', name: '6440900 - Arrendamento mercantil' },
  { value: '6450600', name: '6450600 - Sociedades de capitalização' },
  { value: '6461100', name: '6461100 - Holdings de instituições financeiras' },
  { value: '6462000', name: '6462000 - Holdings de instituições não-financeiras' },
  { value: '6463800', name: '6463800 - Outras sociedades de participação' },
  { value: '6470101', name: '6470101 - Fundos de investimento' },
  { value: '6470102', name: '6470102 - Fundos de investimento previdenciários' },
  { value: '6470103', name: '6470103 - Fundos de investimento imobiliários' },
  { value: '6491300', name: '6491300 - Sociedades de fomento mercantil - factoring' },
  { value: '6492100', name: '6492100 - Securitização de créditos' },
  { value: '6493000', name: '6493000 - Administração de consórcios para aquisição de bens e direitos' },
  { value: '6499901', name: '6499901 - Clubes de investimento' },
  { value: '6499902', name: '6499902 - Sociedades de investimento' },
  { value: '6499903', name: '6499903 - Fundo garantidor de crédito' },
  { value: '6499904', name: '6499904 - Caixas de financiamento de corporações' },
  { value: '6499905', name: '6499905 - Concessão de crédito pelas OSCIP' },
  {
    value: '6499999',
    name: '6499999 - Outras atividades de serviços financeiros não especificadas anteriormente'
  },
  { value: '6511101', name: '6511101 - Sociedade seguradora de seguros vida' },
  { value: '6511102', name: '6511102 - Planos de auxílio-funeral' },
  { value: '6512000', name: '6512000 - Sociedade seguradora de seguros não vida' },
  { value: '6520100', name: '6520100 - Sociedade seguradora de seguros saúde' },
  { value: '6530800', name: '6530800 - Resseguros' },
  { value: '6541300', name: '6541300 - Previdência complementar fechada' },
  { value: '6542100', name: '6542100 - Previdência complementar aberta' },
  { value: '6550200', name: '6550200 - Planos de saúde' },
  { value: '6611801', name: '6611801 - Bolsa de valores' },
  { value: '6611802', name: '6611802 - Bolsa de mercadorias' },
  { value: '6611803', name: '6611803 - Bolsa de mercadorias e futuros' },
  { value: '6611804', name: '6611804 - Administração de mercados de balcão organizados' },
  { value: '6612601', name: '6612601 - Corretoras de títulos e valores mobiliários' },
  { value: '6612602', name: '6612602 - Distribuidoras de títulos e valores mobiliários' },
  { value: '6612603', name: '6612603 - Corretoras de câmbio' },
  { value: '6612604', name: '6612604 - Corretoras de contratos de mercadorias' },
  { value: '6612605', name: '6612605 - Agentes de investimentos em aplicações financeiras' },
  { value: '6613400', name: '6613400 - Administração de cartões de crédito' },
  { value: '6619301', name: '6619301 - Serviços de liquidação e custódia' },
  { value: '6619302', name: '6619302 - Correspondentes de instituições financeiras' },
  { value: '6619303', name: '6619303 - Representações de bancos estrangeiros' },
  { value: '6619304', name: '6619304 - Caixas eletrônicos' },
  { value: '6619305', name: '6619305 - Operadoras de cartões de débito' },
  {
    value: '6619399',
    name: '6619399 - Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente'
  },
  { value: '6621501', name: '6621501 - Peritos e avaliadores de seguros' },
  { value: '6621502', name: '6621502 - Auditoria e consultoria atuarial' },
  { value: '6622300', name: '6622300 - Corretores e agentes de seguros' },
  { value: '6629100', name: '6629100 - Atividades auxiliares dos seguros' },
  { value: '6630400', name: '6630400 - Atividades de administração de fundos por contrato ou comissão' },
  { value: '6810201', name: '6810201 - Compra e venda de imóveis próprios' },
  { value: '6810202', name: '6810202 - Aluguel de imóveis próprios' },
  { value: '6810203', name: '6810203 - Loteamento de imóveis próprios' },
  { value: '6821801', name: '6821801 - Corretagem na compra e venda e avaliação de imóveis' },
  { value: '6821802', name: '6821802 - Corretagem no aluguel de imóveis' },
  { value: '6822600', name: '6822600 - Gestão e administração da propriedade imobiliária' },
  { value: '6911701', name: '6911701 - Serviços advocatícios' },
  { value: '6911702', name: '6911702 - Atividades auxiliares da justiça' },
  { value: '6911703', name: '6911703 - Agente de propriedade industrial' },
  { value: '6912500', name: '6912500 - Cartórios' },
  { value: '6920601', name: '6920601 - Atividades de contabilidade' },
  { value: '6920602', name: '6920602 - Atividades de consultoria e auditoria contábil e tributária' },
  { value: '7020400', name: '7020400 - Atividades de consultoria em gestão empresarial' },
  { value: '7111100', name: '7111100 - Serviços de arquitetura' },
  { value: '7112000', name: '7112000 - Serviços de engenharia' },
  { value: '7119701', name: '7119701 - Serviços de cartografia' },
  { value: '7119702', name: '7119702 - Atividades de estudos geológicos' },
  { value: '7119703', name: '7119703 - Serviços de desenho técnico relacionados à arquitetura e engenharia' },
  { value: '7119704', name: '7119704 - Serviços de perícia técnica relacionados à segurança do trabalho' },
  {
    value: '7119799',
    name: '7119799 - Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente'
  },
  { value: '7120100', name: '7120100 - Testes e análises técnicas' },
  {
    value: '7210000',
    name: '7210000 - Pesquisa e desenvolvimento experimental em ciências físicas e naturais'
  },
  {
    value: '7220700',
    name: '7220700 - Pesquisa e desenvolvimento experimental em ciências sociais e humanas'
  },
  { value: '7311400', name: '7311400 - Agências de publicidade' },
  { value: '7312200', name: '7312200 - Agenciamento de espaços para publicidade' },
  { value: '7319001', name: '7319001 - Criação de estandes para feiras e exposições' },
  { value: '7319002', name: '7319002 - Promoção de vendas' },
  { value: '7319003', name: '7319003 - Marketing direto' },
  { value: '7319004', name: '7319004 - Consultoria em publicidade' },
  { value: '7319099', name: '7319099 - Outras atividades de publicidade não especificadas anteriormente' },
  { value: '7320300', name: '7320300 - Pesquisas de mercado e de opinião pública' },
  { value: '7410201', name: '7410201 - Design' },
  { value: '7410202', name: '7410202 - Design de interiores' },
  { value: '7410203', name: '7410203 - Design de produto' },
  { value: '7410299', name: '7410299 - atividades de design não especificadas anteriormente' },
  { value: '7420001', name: '7420001 - Atividades de produção de fotografias' },
  { value: '7420002', name: '7420002 - Atividades de produção de fotografias aéreas e submarinas' },
  { value: '7420003', name: '7420003 - Laboratórios fotográficos' },
  { value: '7420004', name: '7420004 - Filmagem de festas e eventos' },
  { value: '7420005', name: '7420005 - Serviços de microfilmagem' },
  { value: '7490101', name: '7490101 - Serviços de tradução' },
  { value: '7490102', name: '7490102 - Escafandria e mergulho' },
  {
    value: '7490103',
    name: '7490103 - Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias'
  },
  {
    value: '7490104',
    name: '7490104 - Atividades de intermediação e agenciamento de serviços e negócios em geral'
  },
  { value: '7490105', name: '7490105 - Agenciamento de profissionais para atividades esportivas' },
  { value: '7490199', name: '7490199 - Outras atividades profissionais' },
  { value: '7500100', name: '7500100 - Atividades veterinárias' },
  { value: '7711000', name: '7711000 - Locação de automóveis sem condutor' },
  { value: '7719501', name: '7719501 - Locação de embarcações sem tripulação' },
  { value: '7719502', name: '7719502 - Locação de aeronaves sem tripulação' },
  {
    value: '7719599',
    name: '7719599 - Locação de outros meios de transporte não especificados anteriormente'
  },
  { value: '7721700', name: '7721700 - Aluguel de equipamentos recreativos e esportivos' },
  { value: '7722500', name: '7722500 - Aluguel de fitas de vídeo' },
  { value: '7723300', name: '7723300 - Aluguel de objetos do vestuário' },
  { value: '7729201', name: '7729201 - Aluguel de aparelhos de jogos eletrônicos' },
  { value: '7729202', name: '7729202 - Aluguel de móveis' },
  { value: '7729203', name: '7729203 - Aluguel de material médico' },
  {
    value: '7729299',
    name: '7729299 - Aluguel de outros objetos pessoais e domésticos não especificados anteriormente'
  },
  { value: '7731400', name: '7731400 - Aluguel de máquinas e equipamentos agrícolas sem operador' },
  { value: '7732201', name: '7732201 - Aluguel de máquinas e equipamentos para construção sem operador' },
  { value: '7732202', name: '7732202 - Aluguel de andaimes' },
  { value: '7733100', name: '7733100 - Aluguel de máquinas e equipamentos para escritórios' },
  {
    value: '7739001',
    name: '7739001 - Aluguel de máquinas e equipamentos para extração de minérios e petróleo'
  },
  { value: '7739002', name: '7739002 - Aluguel de equipamentos científicos sem operador' },
  { value: '7739003', name: '7739003 - Aluguel de palcosexceto andaimes"' },
  {
    value: '7739099',
    name: '7739099 - Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente'
  },
  { value: '7740300', name: '7740300 - Gestão de ativos intangíveis não-financeiros' },
  { value: '7810800', name: '7810800 - Seleção e agenciamento de mão-de-obra' },
  { value: '7820500', name: '7820500 - Locação de mão-de-obra temporária' },
  { value: '7830200', name: '7830200 - Fornecimento e gestão de recursos humanos para terceiros' },
  { value: '7911200', name: '7911200 - Agências de viagens' },
  { value: '7912100', name: '7912100 - Operadores turísticos' },
  {
    value: '7990200',
    name: '7990200 - Serviços de reservas e outros serviços de turismo não especificados anteriormente'
  },
  { value: '8011101', name: '8011101 - Atividades de vigilância e segurança privada' },
  { value: '8011102', name: '8011102 - Serviços de adestramento de cães de guarda' },
  { value: '8012900', name: '8012900 - Atividades de transporte de valores' },
  { value: '8020000', name: '8020000 - Atividades de monitoramento de sistemas de segurança' },
  { value: '8020001', name: '8020001 - Atividades de monitoramento de sistemas de segurança eletrônica' },
  { value: '8020002', name: '8020002 - Outras atividades de serviços de segurança' },
  { value: '8030700', name: '8030700 - Atividades de investigação particular' },
  { value: '8111700', name: '8111700 - Serviços combinados para apoio a edifícios' },
  { value: '8112500', name: '8112500 - Condomínios prediais' },
  { value: '8121400', name: '8121400 - Limpeza em prédios e em domicílios' },
  { value: '8122200', name: '8122200 - Imunização e controle de pragas urbanas' },
  { value: '8129000', name: '8129000 - Atividades de limpeza não especificadas anteriormente' },
  { value: '8130300', name: '8130300 - Atividades paisagísticas' },
  { value: '8211300', name: '8211300 - Serviços combinados de escritório e apoio administrativo' },
  { value: '8219901', name: '8219901 - Fotocópias' },
  {
    value: '8219999',
    name: '8219999 - Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente'
  },
  { value: '8220200', name: '8220200 - Atividades de teleatendimento' },
  { value: '8230001', name: '8230001 - Serviços de organização de feiras, exposições e festas' },
  { value: '8230002', name: '8230002 - Casas de festas e eventos' },
  { value: '8291100', name: '8291100 - Atividades de cobranças e informações cadastrais' },
  { value: '8292000', name: '8292000 - Envasamento e empacotamento sob contrato' },
  { value: '8299701', name: '8299701 - Medição de consumo de energia elétrica' },
  { value: '8299702', name: '8299702 - Emissão de vales-alimentação' },
  { value: '8299703', name: '8299703 - Serviços de gravação de carimbos' },
  { value: '8299704', name: '8299704 - Leiloeiros independentes' },
  { value: '8299705', name: '8299705 - Serviços de levantamento de fundos sob contrato' },
  { value: '8299706', name: '8299706 - Casas lotéricas' },
  { value: '8299707', name: '8299707 - Salas de acesso à internet' },
  {
    value: '8299799',
    name: '8299799 - Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente'
  },
  { value: '8411600', name: '8411600 - Administração pública em geral' },
  {
    value: '8412400',
    name: '8412400 - Regulamento das atividades de saúde, serviços culturais e outros serviços sociais'
  },
  { value: '8413200', name: '8413200 - Regulação das atividades econômicas' },
  { value: '8421300', name: '8421300 - Relações exteriores' },
  { value: '8422100', name: '8422100 - Defesa' },
  { value: '8423000', name: '8423000 - Justiça' },
  { value: '8424800', name: '8424800 - Segurança e ordem pública' },
  { value: '8425600', name: '8425600 - Defesa Civil' },
  { value: '8430200', name: '8430200 - Seguridade social obrigatória' },
  { value: '8511200', name: '8511200 - Educação infantil - creche' },
  { value: '8512100', name: '8512100 - Educação infantil - pré-escola' },
  { value: '8513900', name: '8513900 - Ensino fundamental' },
  { value: '8520100', name: '8520100 - Ensino médio' },
  { value: '8531700', name: '8531700 - Educação superior - graduação' },
  { value: '8532500', name: '8532500 - Educação superior - graduação e pós-graduação' },
  { value: '8533300', name: '8533300 - Educação superior - pós-graduação e extensão' },
  { value: '8541400', name: '8541400 - Educação profissional de nível técnico' },
  { value: '8542200', name: '8542200 - Educação profissional de nível tecnológico' },
  { value: '8550301', name: '8550301 - Administração de caixas escolares' },
  { value: '8550302', name: '8550302 - Atividades de apoio à educação' },
  { value: '8591100', name: '8591100 - Ensino de esportes' },
  { value: '8592901', name: '8592901 - Ensino de dança' },
  { value: '8592902', name: '8592902 - Ensino de artes cênicas' },
  { value: '8592903', name: '8592903 - Ensino de música' },
  { value: '8592999', name: '8592999 - Ensino de arte e cultura não especificado anteriormente' },
  { value: '8593700', name: '8593700 - Ensino de idiomas' },
  { value: '8599601', name: '8599601 - Formação de condutores' },
  { value: '8599602', name: '8599602 - Cursos de pilotagem' },
  { value: '8599603', name: '8599603 - Treinamento em informática' },
  { value: '8599604', name: '8599604 - Treinamento em desenvolvimento profissional e gerencial' },
  { value: '8599605', name: '8599605 - Cursos preparatórios para concursos' },
  { value: '8599699', name: '8599699 - Outras atividades de ensino não especificadas anteriormente' },
  { value: '8610101', name: '8610101 - Atividades de atendimento hospitalar' },
  {
    value: '8610102',
    name: '8610102 - Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências'
  },
  { value: '8621601', name: '8621601 - UTI móvel' },
  { value: '8621602', name: '8621602 - Serviços móveis de atendimento a urgências' },
  { value: '8622400', name: '8622400 - Serviços de remoção de pacientes' },
  {
    value: '8630501',
    name: '8630501 - Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos'
  },
  {
    value: '8630502',
    name: '8630502 - Atividade médica ambulatorial com recursos para realização de exames complementares'
  },
  { value: '8630503', name: '8630503 - Atividade médica ambulatorial restrita a consultas' },
  { value: '8630504', name: '8630504 - Atividade odontológica' },
  {
    value: '8630505',
    name: '8630505 - Atividade odontológica sem recursos para realização de procedimentos cirúrgicos'
  },
  { value: '8630506', name: '8630506 - Serviços de vacinação e imunização humana' },
  { value: '8630507', name: '8630507 - Atividades de reprodução humana assistida' },
  { value: '8630599', name: '8630599 - Atividades de atenção ambulatorial não especificadas anteriormente' },
  { value: '8640201', name: '8640201 - Laboratórios de anatomia patológica e citológica' },
  { value: '8640202', name: '8640202 - Laboratórios clínicos' },
  { value: '8640203', name: '8640203 - Serviços de diálise e nefrologia' },
  { value: '8640204', name: '8640204 - Serviços de tomografia' },
  { value: '8640205', name: '8640205 - Serviços de diagnóstico por imagem com uso de radiação ionizante' },
  { value: '8640206', name: '8640206 - Serviços de ressonância magnética' },
  { value: '8640207', name: '8640207 - Serviços de diagnóstico por imagem sem uso de radiação ionizante' },
  { value: '8640208', name: '8640208 - Serviços de diagnóstico por registro gráfico - ECG' },
  {
    value: '8640209',
    name: '8640209 - Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos'
  },
  { value: '8640210', name: '8640210 - Serviços de quimioterapia' },
  { value: '8640211', name: '8640211 - Serviços de radioterapia' },
  { value: '8640212', name: '8640212 - Serviços de hemoterapia' },
  { value: '8640213', name: '8640213 - Serviços de litotripsia' },
  { value: '8640214', name: '8640214 - Serviços de bancos de células e tecidos humanos' },
  {
    value: '8640299',
    name: '8640299 - Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente'
  },
  { value: '8650001', name: '8650001 - Atividades de enfermagem' },
  { value: '8650002', name: '8650002 - Atividades de profissionais da nutrição' },
  { value: '8650003', name: '8650003 - Atividades de psicologia e psicanálise' },
  { value: '8650004', name: '8650004 - Atividades de fisioterapia' },
  { value: '8650005', name: '8650005 - Atividades de terapia ocupacional' },
  { value: '8650006', name: '8650006 - Atividades de fonoaudiologia' },
  { value: '8650007', name: '8650007 - Atividades de terapia de nutrição enteral e parenteral' },
  {
    value: '8650099',
    name: '8650099 - Atividades de profissionais da área de saúde não especificadas anteriormente'
  },
  { value: '8660700', name: '8660700 - Atividades de apoio à gestão de saúde' },
  {
    value: '8690901',
    name: '8690901 - Atividades de práticas integrativas e complementares em saúde humana'
  },
  { value: '8690902', name: '8690902 - Atividades de banco de leite humano' },
  { value: '8690903', name: '8690903 - Atividades de acupuntura' },
  { value: '8690904', name: '8690904 - Atividades de podologia' },
  {
    value: '8690999',
    name: '8690999 - Outras atividades de atenção à saúde humana não especificadas anteriormente'
  },
  { value: '8711501', name: '8711501 - Clínicas e residências geriátricas' },
  { value: '8711502', name: '8711502 - Instituições de longa permanência para idosos' },
  { value: '8711503', name: '8711503 - Atividades de assistência a deficientes físicos' },
  { value: '8711504', name: '8711504 - Centros de apoio a pacientes com câncer e com AIDS' },
  { value: '8711505', name: '8711505 - Condomínios residenciais para idosos e deficientes físicos' },
  {
    value: '8712300',
    name: '8712300 - Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio'
  },
  { value: '8720401', name: '8720401 - Atividades de centros de assistência psicossocial' },
  {
    value: '8720499',
    name: '8720499 - Atividades de assistência psicossocial e saúde a portadores de distúrbios psíquicos'
  },
  { value: '8730101', name: '8730101 - Orfanatos' },
  { value: '8730102', name: '8730102 - Albergues assistenciais' },
  {
    value: '8730199',
    name: '8730199 - Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente'
  },
  { value: '8800600', name: '8800600 - Serviços de assistência social sem alojamento' },
  { value: '9001901', name: '9001901 - Produção teatral' },
  { value: '9001902', name: '9001902 - Produção musical' },
  { value: '9001903', name: '9001903 - Produção de espetáculos de dança' },
  { value: '9001904', name: '9001904 - Produção de espetáculos circenses' },
  { value: '9001905', name: '9001905 - Produção de espetáculos de rodeios' },
  { value: '9001906', name: '9001906 - Atividades de sonorização e de iluminação' },
  { value: '9001999', name: '9001999 - Artes cínicas' },
  { value: '9002701', name: '9002701 - Atividades de artistas plásticos' },
  { value: '9002702', name: '9002702 - Restauro de obras de arte' },
  { value: '9003500', name: '9003500 - Gestão de espaços para artes cênicas' },
  { value: '9101500', name: '9101500 - Atividades de bibliotecas e arquivos' },
  {
    value: '9102301',
    name: '9102301 - Atividades de museus e de exploração de lugares e prédios históricos e atrações similares'
  },
  { value: '9102302', name: '9102302 - Restauro e conservação de lugares e prédios históricos' },
  {
    value: '9103100',
    name: '9103100 - Atividades de jardins botânicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental'
  },
  { value: '9200301', name: '9200301 - Casas de bingo' },
  { value: '9200302', name: '9200302 - Exploração de apostas em corridas de cavalos' },
  {
    value: '9200399',
    name: '9200399 - Exploração de jogos de azar e apostas não especificados anteriormente'
  },
  { value: '9311500', name: '9311500 - Gestão de instalações de esportes' },
  { value: '9312300', name: '9312300 - Clubes sociais' },
  { value: '9313100', name: '9313100 - Atividades de condicionamento físico' },
  { value: '9319101', name: '9319101 - Produção e promoção de eventos esportivos' },
  { value: '9319199', name: '9319199 - Outras atividades esportivas não especificadas anteriormente' },
  { value: '9321200', name: '9321200 - Parques de diversão e parques temáticos' },
  { value: '9329801', name: '9329801 - Discotecassal�es de dan�a e similares"' },
  { value: '9329802', name: '9329802 - Exploração de boliches' },
  { value: '9329803', name: '9329803 - Exploração de jogos de sinuca' },
  { value: '9329804', name: '9329804 - Exploração de jogos eletrônicos recreativos' },
  {
    value: '9329899',
    name: '9329899 - Outras atividades de recreação e lazer não especificadas anteriormente'
  },
  { value: '9411100', name: '9411100 - Atividades de organizações associativas patronais e empresariais' },
  { value: '9412000', name: '9412000 - Atividades de organizações associativas profissionais' },
  { value: '9412001', name: '9412001 - Atividades de fiscalização profissional' },
  { value: '9412099', name: '9412099 - Outras atividades associativas profissionais' },
  { value: '9420100', name: '9420100 - Atividades de organizações sindicais' },
  { value: '9430800', name: '9430800 - Atividades de associações de defesa de direitos sociais' },
  { value: '9491000', name: '9491000 - Atividades de organizações religiosas ou filosóficas' },
  { value: '9492800', name: '9492800 - Atividades de organizações políticas' },
  { value: '9493600', name: '9493600 - Atividades de organizações associativas ligadas à cultura e à arte' },
  { value: '9499500', name: '9499500 - Atividades associativas não especificadas anteriormente' },
  {
    value: '9511800',
    name: '9511800 - Reparação e manutenção de computadores e de equipamentos periféricos'
  },
  { value: '9512600', name: '9512600 - Reparação e manutenção de equipamentos de comunicação' },
  {
    value: '9521500',
    name: '9521500 - Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico'
  },
  { value: '9529101', name: '9529101 - Reparação de calçados' },
  { value: '9529102', name: '9529102 - Chaveiros' },
  { value: '9529103', name: '9529103 - Reparação de relógios' },
  { value: '9529104', name: '9529104 - Reparação de bicicletas' },
  { value: '9529105', name: '9529105 - Reparação de artigos do mobiliário' },
  { value: '9529106', name: '9529106 - Reparação de joias' },
  {
    value: '9529199',
    name: '9529199 - Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente'
  },
  { value: '9601701', name: '9601701 - Lavanderias' },
  { value: '9601702', name: '9601702 - Tinturarias' },
  { value: '9601703', name: '9601703 - Toalheiros' },
  { value: '9602501', name: '9602501 - Cabeleireiros' },
  { value: '9602502', name: '9602502 - Atividades de estética e outros serviços de cuidados com a beleza' },
  { value: '9603301', name: '9603301 - Gestão e manutenção de cemitérios' },
  { value: '9603302', name: '9603302 - Serviços de crematório' },
  { value: '9603303', name: '9603303 - Serviços de sepultamento' },
  { value: '9603304', name: '9603304 - Serviços de funerárias' },
  { value: '9603305', name: '9603305 - Serviços de somatoconservação' },
  {
    value: '9603399',
    name: '9603399 - Atividades funerárias e serviços relacionados não especificados anteriormente'
  },
  { value: '9609201', name: '9609201 - Clínicas de estética e similares' },
  { value: '9609202', name: '9609202 - Agências matrimoniais' },
  { value: '9609203', name: '9609203 - Alojamento' },
  { value: '9609204', name: '9609204 - Exploração de máquinas de serviços pessoais acionadas por moeda' },
  { value: '9609205', name: '9609205 - Atividades de sauna e banhos' },
  { value: '9609206', name: '9609206 - Serviços de tatuagem e colocação de piercing' },
  { value: '9609207', name: '9609207 - Alojamento de animais domésticos' },
  { value: '9609208', name: '9609208 - Higiene e embelezamento de animais domésticos' },
  {
    value: '9609299',
    name: '9609299 - Outras atividades de serviços pessoais não especificadas anteriormente'
  },
  { value: '9700500', name: '9700500 - Serviços domésticos' },
  { value: '9900800', name: '9900800 - Organismos internacionais e outras instituições extraterritoriais' },
  { value: '8888888', name: '8888888 - Atividade Econômica não informada' }
]
