import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import { MdEditCalendar, MdCheck, MdClose } from 'react-icons/md'
import { app } from 'api/feathers'
import { getUserAuthenticationRole } from 'hooks/authService'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import authHeader from 'hooks/authHeader'
const userRole = getUserAuthenticationRole()

// export default function CalendarCareTable() {
//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

//   return (
//     <table {...getTableProps()}>
//       <thead>
//         {headerGroups.map((headerGroup) => (
//           <tr {...headerGroup.getHeaderGroupProps()}>
//             {headerGroup.headers.map((column) => (
//               <th {...column.getHeaderProps()}>{column.render('Header')}</th>
//             ))}
//           </tr>
//         ))}
//       </thead>
//       <tbody {...getTableBodyProps()}>
//         {rows.map((row) => {
//           prepareRow(row)
//           return (
//             <tr {...row.getRowProps()}>
//               {row.cells.map((cell) => (
//                 <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//               ))}
//             </tr>
//           )
//         })}
//       </tbody>
//     </table>
//   )
// }

// subject: '',
// description: '',
// account_id: account_id,
// priority: 5,
// tags: {
//   Atendimento: [],
//   Especies: [],
//   Idade: [],
//   'Status do Cliente': []
// },
// status: 'active',
// start_date: '',
// end_date: ''

// Definindo os dados da planilha

const columnsData = [
  { Header: 'Cadência', accessor: 'name' },
  { Header: 'Campanhas', accessor: 'campaigns_ids' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Abordagens', accessor: '' }
]

export default function CadenceTable() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function buscarCampanhas() {
      try {
        const { data } = await app.service('cadences').find({
          headers: authHeader()
        })
        setTableData(data)
        console.log(data)
      } catch (error) {
        console.error('Erro ao buscar campanhas:', error)
      }
    }

    buscarCampanhas()
  }, [])

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Cadências
        </Text>
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-campaign">
            <Flex flexDirection="rows" alignItems="center" gap={2}>
              <Icon as={MdEditCalendar} color={iconColor} w="26px" h="26px" />

              <Text px="5px" fontSize="sm">
                Nova
              </Text>
            </Flex>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/cadence/${row.original.id}`}>
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.name}
                    </Text>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.campaigns_ids.map((campaign) => (
                        <Tag colorScheme="green" borderRadius="full" size="sm" mr="px" mb="4px">
                          {campaign}
                        </Tag>
                      ))}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.status === 'active' ? (
                      <Icon as={MdCheck} color="green" w="20px" h="20px" />
                    ) : (
                      <Icon as={MdClose} color="red" w="20px" h="20px" />
                    )}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
