import React from 'react'
import { Flex, Link, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'

export default function DealCard({ deal }) {
  return (
    <Link href={`/admin/deal/${deal.id}`} key={deal.id}>
      <Card background="white" boxShadow="md" p="10px 15px" borderRadius="10px" draggable>
        <Flex flexDirection="column">
          <Text fontSize={'14px'} fontWeight={'800'}>
            {deal.name}
          </Text>
          <Text fontSize={'10px'} fontWeight={'500'}>
            {deal.company.name}
          </Text>
          <Text fontSize={'10px'} fontWeight={'500'}>
            {`${deal.contact.first_name} ${deal.contact.last_name} - ${deal.contact.position}`}
          </Text>
        </Flex>
      </Card>
    </Link>
  )
}
