import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import { Button, Flex, Input, Select, SimpleGrid, Tag, Text, Textarea } from '@chakra-ui/react'
import { FaPhone, FaTasks, FaEnvelope, FaComments, FaHandshake, FaWalking } from 'react-icons/fa'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'

export default function Activities({ activity, refreshComponent }) {
  const [newData, setNewData] = useState({
    // account_id: null,
    // owner_id: null,
    // entity: '',
    // entity_id: null,
    // name: '',
    description: activity.description,
    // type: '',
    status: activity.status,
    // coments: activity.coments,
    dead_line: activity.dead_line
  })

  function RenderActivityIcon({ value }) {
    const activity = activityMapping[value]

    if (!activity) {
      return <span>Ops..</span>
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
        <span style={{ marginRight: '8px' }}>{activity.icon}</span>
        <span>{activity.name}</span>
      </div>
    )
  }

  function convertTimestampToDateAndTime(timestamp) {
    const date = new Date(timestamp * 1000)

    // Obtém a data e hora sem ajustar para o fuso horário local
    const year = date.getUTCFullYear()
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0') // Os meses vão de 0 a 11, por isso +1
    const day = date.getUTCDate().toString().padStart(2, '0')
    const hours = date.getUTCHours().toString().padStart(2, '0')
    const minutes = date.getUTCMinutes().toString().padStart(2, '0')
    const seconds = date.getUTCSeconds().toString().padStart(2, '0')

    // Retorna no formato "DD/MM/YYYY HH:MM:SS"
    return `${day}/${month}/${year} ${hours}h${minutes}`
  }

  const activityMapping = {
    call: { icon: <FaPhone />, name: 'Ligação' },
    task: { icon: <FaTasks />, name: 'Tarefa' },
    email: { icon: <FaEnvelope />, name: 'Email' },
    message: { icon: <FaComments />, name: 'Mensagem' },
    meeting: { icon: <FaHandshake />, name: 'Reunião' },
    visit: { icon: <FaWalking />, name: 'Visita' }
  }

  const [status, setStatus] = useState(activity.status)

  async function updateActivity(data) {
    try {
      const response = await axios.patch(`/activities/${activity.id}`, data, { headers: authHeader() })
      console.log('Atividade atualizada!', response)
      refreshComponent()
    } catch (error) {
      console.log('Não foi possível atualizar a atividade.', error)
    }
  }

  function handleChange(event) {
    const { name, value } = event.target
    setNewData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setStatus(value)
    updateActivity({ [name]: value })
  }

  useEffect(() => {
    console.log('newData activity: ', newData)
  }, [newData])

  console.log('activity.dead_line', activity.dead_line)

  return (
    <Flex p="10px" borderRadius="20px" w="100%" flexDirection="column">
      <Flex
        flexDirection="row"
        mb="10px"
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: '1px solid #ccc' }}
      >
        <RenderActivityIcon value={activity.type} />
        <Flex>
          <Text mr="20px" fontSize="0.9rem" width={'fit-content'}>
            {activity.dead_line && convertTimestampToDateAndTime(activity.dead_line)}
          </Text>
        </Flex>
        <Flex>
          <SelectButton
            name="status"
            value={newData.status}
            onChange={handleChange}
            options={[
              { value: 'todo', name: 'Pendente' },
              // { value: 'doing', name: 'Fazendo' },
              // { value: 'blocked', name: 'Impedida' },
              // { value: 'postponed', name: 'Adiada' },
              { value: 'canceled', name: 'Cancelada' },
              { value: 'done', name: 'Realizada' }
            ]}
            optionLabel="name"
            optionValue="value"
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
      </Flex>
      <Text fontSize={'14px'}>{activity.description}</Text>
      {/* <Textarea placeholder="Comentário" /> */}

      {/* <Button bg="#49BFFF" color="white" colorScheme="blue" borderRadius="20px" fontSize={'14px'}>
                Salvar
              </Button> */}
    </Flex>
  )
}
