import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import Card from 'components/card/Card'

export default function AccountForm(props) {
  const textColor = useColorModeValue('navy.700', 'white')

  const { accountData } = props

  // useEffect(() => {
  //   async function getAccountData() {
  //     try {
  //       const { data } = await axios.get(`/accounts?userId=${id}`, { headers: authHeader() })
  //       setAccountData(data.data[0])
  //       setFormData(data.data[0])
  //       console.log('accountForm/getAccountData()/ data: ', data.data[0])
  //     } catch (error) {
  //       console.log('Necessário criar uma nova conta: ', error)
  //     }
  //   }

  //   getAccountData()
  // }, [])

  console.log('accountData: ', accountData)

  const [formData, setFormData] = useState({
    name: '',
    business_data: { cnpj: '', employees: '' },
    address: {},
    contact_data: {
      phones: [
        {
          ddd: '',
          number: '',
          type: ''
        }
      ],
      emails: [
        {
          address: '',
          type: ''
        }
      ],
      website: ''
    },
    status: 'new',
    main_user: {},
    company_logo_url: ''
  })

  useEffect(() => {
    const isAdminNewAccount = () => {
      return window.location.href.includes('/admin/new-account')
    }

    if (!isAdminNewAccount()) {
      setFormData(accountData)
    }
  }, [accountData])

  // useEffect(() => {
  //   const correctedAccountData = {
  //     ...accountData,
  //     profile: Array.isArray(accountData && accountData.profile) ? accountData.profile : ['']
  //   }

  //   setFormData(correctedAccountData)
  // }, [accountData])

  useEffect(() => {
    console.log('formData:', formData)
  }, [formData])

  const [sendAlert, setSendAlert] = useState('')

  const addPhone = () => {
    setFormData((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        phones: [
          ...prevState.contact_data.phones,
          {
            ddd: '',
            number: '',
            type: ''
          }
        ]
      }
    }))
  }

  const addEmail = () => {
    setFormData((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        emails: [
          ...prevState.contact_data.emails,
          {
            address: '',
            type: ''
          }
        ]
      }
    }))
  }

  const handleCheckboxProfileChange = (event) => {
    const value = event.target.value
    setFormData((prevContactProfile) => {
      const newClientProfile = JSON.parse(JSON.stringify(prevContactProfile))

      // Certifica-se de que o array profile existe
      if (!newClientProfile.business_data.profile) {
        newClientProfile.business_data.profile = []
      }

      if (event.target.checked) {
        newClientProfile.business_data.profile.push(value)
      } else {
        newClientProfile.business_data.profile = newClientProfile.business_data.profile.filter(
          (item) => item !== value
        )
      }

      return newClientProfile
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData((prevState) => {
      const updatedFormData = { ...prevState }
      const path = name.split('.')
      let nestedObj = updatedFormData

      path.forEach((key, index) => {
        if (key.includes('[')) {
          const [arrayKey, arrayIndex] = key.split(/\[|\]/).filter(Boolean)
          if (!nestedObj[arrayKey]) nestedObj[arrayKey] = []
          if (index === path.length - 1) {
            nestedObj[arrayKey][arrayIndex] = value
          } else {
            nestedObj = nestedObj[arrayKey][arrayIndex] = {
              ...nestedObj[arrayKey][arrayIndex]
            }
          }
        } else {
          if (index === path.length - 1) {
            nestedObj[key] = value
          } else {
            nestedObj = nestedObj[key] = { ...nestedObj[key] }
          }
        }
      })

      return updatedFormData
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Clone and stringify necessary fields
    const payload = { ...formData }
    payload.business_data = JSON.stringify(formData.business_data)
    payload.address = JSON.stringify(formData.address)
    payload.contact_data = JSON.stringify(formData.contact_data)
    payload.profile = JSON.stringify(formData.profile)
    payload.main_user = JSON.stringify(formData.main_user)
    payload.status = 'active'

    if (accountData.id) {
      try {
        const response = await axios.patch(`/accounts/${accountData.id}`, payload, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
        setTimeout(() => {
          window.location.href = '/admin/painel'
        }, 600)
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao atualizar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/accounts', payload, {
          headers: authHeader()
        })
        console.log('Conta cadastrada com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar a conta:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log('Dados enviados: ', payload)
  }

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
            Dados da Empresa
          </Text>
          <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
            <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                CNPJ
              </FormLabel>
              <Input
                value={formData && formData.business_data && formData.business_data.cnpj}
                name="business_data.cnpj"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="CNPJ da clínica"
                mb="24px"
                fontWeight="500"
                size="md"
              />
            </Flex>
            <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Nome Fantasia
              </FormLabel>
              <Input
                value={formData && formData.name}
                name="name"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Nome da unidade"
                mb="24px"
                fontWeight="500"
                size="md"
              />
            </Flex>

            <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Website
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" minW="70px" fontSize="sm">
                  https://
                </InputLeftElement>
                <Input
                  value={formData && formData.contact_data && formData.contact_data.website}
                  name="contact_data.website"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="www.seusite.com.br"
                  pl="57px"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </InputGroup>
            </Flex>
            <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Instagram
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" w="50px">
                  @
                </InputLeftElement>
                <Input
                  value={formData && formData.contact_data && formData.contact_data.instagram}
                  name="contact_data.instagram"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Informe a conta"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </InputGroup>
            </Flex>
          </SimpleGrid>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Telefone(s)
            </FormLabel>
            {formData &&
              formData.contact_data &&
              formData.contact_data.phones.map((phone, index) => (
                <Flex direction="column" key={index}>
                  <InputGroup>
                    {/* <InputLeftElement pointerEvents="none" w="50px">
                      oi
                    </InputLeftElement> */}
                    <Select
                      value={phone.type}
                      name={`contact_data.phones[${index}].type`}
                      onChange={handleChange}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Selecione"
                      mb="24px"
                      fontWeight="500"
                      size="md"
                      w="200px"
                      borderRadius="16px 0px 0px 16px"
                    >
                      <option key="WhatsApp" value="WhatsApp">
                        WhatsApp
                      </option>
                      <option key="Número Fixo" value="Número Fixo">
                        Número Fixo
                      </option>
                    </Select>
                    <Input
                      as={InputMask}
                      mask="+** (**) *********"
                      maskChar={null}
                      value={phone.number}
                      name={`contact_data.phones[${index}].number`}
                      onChange={handleChange}
                      isRequired={true}
                      type="tel"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Informe o telefone"
                      mb="24px"
                      fontWeight="500"
                      size="md"
                      style={{ borderRadius: '0 16px 16px 0' }}
                    />
                  </InputGroup>
                </Flex>
              ))}
            <Button
              onClick={addPhone}
              style={{ fontSize: '12px', background: '#e8f0fe' }}
              mt="-10px"
              mb="24px"
              padding="5px 30px"
              height="30px"
              borderRadius={50}
            >
              Adicionar Telefone
            </Button>
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Email
            </FormLabel>
            {formData &&
              formData.contact_data &&
              formData.contact_data.emails.map((email, index) => (
                <Input
                  value={email.address}
                  name={`contact_data.emails[${index}].address`}
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="email"
                  type="email"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              ))}
            {/* <Button
              onClick={addEmail}
              style={{ fontSize: '12px', background: '#e8f0fe' }}
              mt="-10px"
              mb="24px"
              padding="5px 30px"
              height="30px"
              borderRadius={50}
            >
              Adicionar Email
            </Button> */}
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Número de Funcionários
            </FormLabel>
            <Select
              value={formData && formData.business_data && formData.business_data.employees}
              name="business_data.employees"
              onChange={handleChange}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Selecione"
              mb="24px"
              fontWeight="500"
              size="md"
            >
              <option key="1-5" velue="1-5">
                1 a 5 funcionários
              </option>
              <option key="6-10" velue="6-10">
                6 a 10 funcionários
              </option>
              <option key="11-20" velue="11-20">
                11 a 20 funcionários
              </option>
              <option key="21-30" velue="21-30">
                21 a 30 funcionários
              </option>
              <option key="31-50" velue="31-50">
                31 a 50 funcionários
              </option>
              <option key="+50" velue="+50">
                mais de 50 funcionários
              </option>
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Sales Inteligence BI-Key
            </FormLabel>
            <Input
              value={formData && formData.business_data && formData.business_data.BIKey}
              name="business_data.BIKey"
              onChange={handleChange}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Selecione"
              mb="24px"
              fontWeight="500"
              size="md"
            />
            <Link
              href={`https://lookerstudio.google.com/s/${formData && formData?.business_data?.BIKey}`}
              target="_blank"
            >
              <Text>Acessar</Text>
            </Link>
          </Flex>
          <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
            Perfil da empresa
          </FormLabel>
          <Stack
            spacing={1}
            style={{ display: 'grid', gap: '10px' }}
            gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            mb={10}
          >
            {['Consultoria', 'Comércio', 'Serviço', 'Atacadista', 'Indústria', 'Varejista'].map((value) => (
              <Checkbox
                key={value}
                value={value}
                isChecked={
                  formData.business_data &&
                  formData.business_data.profile &&
                  formData.business_data.profile.includes(value)
                }
                onChange={handleCheckboxProfileChange}
              >
                {value}
              </Checkbox>
            ))}
          </Stack>

          <Button
            type="submit"
            fontSize="sm"
            background="#49BFFF"
            color="white"
            fontWeight="800"
            w="100%"
            h="50"
            mb="24px"
            borderRadius="50px"
          >
            {/* {client_id ? 'Atualizar dados' : 'Confirmar'} */}
            Atualizar dados
          </Button>
          {sendAlert === 'success' && (
            <Alert status="success">
              <AlertIcon />
              <AlertTitle>Sucesso!</AlertTitle>
              <AlertDescription>Dados enviados com sucesso!</AlertDescription>
            </Alert>
          )}
        </FormControl>
      </form>
    </Card>
  )
}
