import { useParams } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormHelperText,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  Textarea,
  useColorModeValue
} from '@chakra-ui/react'

import { MultiSelect } from 'primereact/multiselect'
import { SelectButton } from 'primereact/selectbutton'
import { FloatLabel } from 'primereact/floatlabel'
import { IconField } from 'primereact/iconfield'
import { InputText } from 'primereact/inputtext'
import { InputIcon } from 'primereact/inputicon'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Slider } from 'primereact/slider'
import { Toast } from 'primereact/toast'
import Card from 'components/card/Card'

import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { FaUserTie, FaHandshake, FaIndustry, FaFileAlt } from 'react-icons/fa'

// Tem que passar o DEAL ID e o para o componente pai, para então chamar as atividades referentes ao DEAL.

export default function CompanyForm(props) {
  const textColor = useColorModeValue('navy.700', 'white')
  const account_id = getUserAccountId()

  const { deal_id: dealIdFromParams } = useParams() || ''
  const { stepId, pipelineId: pipelineIdProps, onUpdate, dealData } = props || null

  const [deal_id, setDealId] = useState(dealIdFromParams)
  const [companyList, setCompanyList] = useState([])
  const [contactList, setContactList] = useState([])
  const [pipelineId, setPipelineId] = useState(pipelineIdProps || null)
  const [pipelineItems, setPipelineItems] = useState([])

  const [formData, setFormData] = useState({
    account_id: account_id,
    company_id: null,
    contact_id: null,
    name: '',
    deal_data: {
      description: ''
    },
    step_id: parseInt(stepId, 10)
  })

  useEffect(() => {
    console.log('formData DealForm', formData)
    console.log('pipelineID DealForm', pipelineId)
    if (account_id && pipelineId) {
      getDealPipelineItems()
    }
  }, [formData, pipelineId])

  useEffect(() => {
    if (deal_id) {
      getDealData()
    }

    if (account_id) {
      getAccountCompanies()
      getAccountContacts()
    }
  }, [])

  async function getDealData() {
    try {
      const { data } = await axios.get(`/deals/${deal_id}`, { headers: authHeader() })
      setFormData({
        account_id: data.account_id,
        company_id: data.company_id,
        contact_id: data.contact_id,
        name: data.name,
        deal_data: {
          description: data.deal_data.description
        },
        step_id: data.step_id
      })
      dealData(data)
      setPipelineId(data.step.pipeline_id)
      console.log('DEAL DATA: ', data)
    } catch (error) {
      console.log('Erro ao carregar dados da empresa: ', error)
    }
  }

  async function getDealPipelineItems() {
    try {
      const response = await axios.get(`/pipelines?account_id=${account_id}&id=${pipelineId}`, {
        headers: authHeader()
      })
      setPipelineItems(response.data.data[0]['steps'])
      console.log('pipelineItems: ', response.data.data[0]['steps'])
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  async function getAccountCompanies() {
    try {
      const response = await axios.get(`/companies?account_id=${account_id}`, {
        headers: authHeader()
      })
      setCompanyList(response.data.data)
      // console.log('Response.Data getDealPipeline', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  async function getAccountContacts() {
    try {
      const response = await axios.get(`/contacts?account_id=${account_id}`, {
        headers: authHeader()
      })
      setContactList(response.data.data)
      // console.log('Response.Data getDealPipeline', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const newState = { ...prevState }

      const keys = name.split('.') // Divide o name em um array
      let temp = newState

      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) {
          temp[keys[i]] = {}
        }
        temp = temp[keys[i]]
      }

      temp[keys[keys.length - 1]] = value

      return newState
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (deal_id) {
      try {
        const response = await axios.patch(`/deals/${deal_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        toastSuccess()
        onUpdate()
      } catch (error) {
        toastError()
        console.error('Ocorreu um erro ao atualizar o negócio:', error)
      }
    } else {
      try {
        const response = await axios.post('/deals', formData, {
          headers: authHeader()
        })
        toastSuccess()
        console.log('Negócio cadastrado com sucesso!', response.data)
        setDealId(response.data.id)
        toastError()
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o negócio:', error.stack)
      }
    }
    console.log(formData)
  }

  const toast = useRef(null)

  const toastSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Dados atualizados!' })
  }
  const toastError = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Houve um erro ao atualizar os dados.'
    })
  }

  return (
    <>
      <Card style={{ height: 'fit-content' }}>
        <Flex>
          <SelectButton
            name="step_id"
            value={formData.step_id}
            onChange={handleChange}
            options={pipelineItems}
            optionLabel="name"
            optionValue="id"
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Flex gap="20px" mb="20px">
              <IconField iconPosition="left">
                <InputIcon className="pi pi-briefcase" style={{ marginRight: '20px' }}></InputIcon>
                <InputText
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="p-inputtext ml-6"
                  v-model="value1"
                  placeholder="Negócio"
                  style={{ fontSize: '1.5rem', fontWeight: '800' }}
                />
              </IconField>
            </Flex>
            <Flex gap="20px" mt="0px" flexDirection={'row'}>
              <Dropdown
                value={formData.company_id}
                name="company_id"
                onChange={handleChange}
                options={companyList}
                optionLabel="name"
                optionValue="id"
                placeholder="Selecione uma empresa"
                filter
                // valueTemplate={selectedCountryTemplate}
                // itemTemplate={countryOptionTemplate}
                className="w-full md:w-16rem text-base"
              />
              <Dropdown
                value={formData.contact_id}
                name="contact_id"
                onChange={handleChange}
                options={contactList}
                optionLabel="first_name"
                optionValue="id"
                placeholder="Selecione um contato"
                filter
                // valueTemplate={selectedCountryTemplate}
                // itemTemplate={countryOptionTemplate}
                className="w-full md:w-16rem text-base"
              />
            </Flex>
            <Flex direction="column">
              <InputTextarea
                name="deal_data.description"
                value={formData.deal_data.description}
                onChange={handleChange}
                placeholder="Descreva o negócio..."
                className="w-full text-md my-3 p-2"
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  token: { style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' } },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
            </Flex>

            <Button
              type="submit"
              className="text-white text-center px-3 py-2"
              style={{
                background: '#49BFFF',
                color: 'white',
                borderRadius: '50px'
              }}
            >
              {deal_id ? 'Atualizar' : 'Adicionar Negócio'}
            </Button>
          </FormControl>
        </form>
        <Toast ref={toast} />
      </Card>
    </>
  )
}
