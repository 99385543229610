import React, { useState } from 'react'
import { IconButton, Flex } from '@chakra-ui/react' // Caso esteja usando o Chakra UI
import { FaToggleOff, FaToggleOn } from 'react-icons/fa' // Importando os ícones

function FocusModeButton({ focusState }) {
  // Estado para gerenciar o ícone e o foco
  const [focus, setFocus] = useState(false)

  // Função para alternar tela cheia e alterar ícone
  const toggleFullScreen = () => {
    // Altera o ícone e o estado de 'focus'
    setFocus(!focus)
    focusState(!focus)

    // Verifica se já está em tela cheia
    if (!document.fullscreenElement) {
      // Solicita o modo tela cheia
      document.documentElement.requestFullscreen()
    } else {
      // Sai do modo tela cheia, se estiver ativo
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  return (
    <Flex justifyContent="center" mb="-30px">
      <IconButton
        aria-label="Toggle Fullscreen"
        icon={focus ? <FaToggleOn /> : <FaToggleOff />}
        color={focus ? '#0d4384' : '#eaeaea'}
        onClick={toggleFullScreen}
        borderRadius={'50px'}
      />
    </Flex>
  )
}

export default FocusModeButton
