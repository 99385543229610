import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { app } from '../../../api/feathers'

import { Box, SimpleGrid } from '@chakra-ui/react'
// import Card from '../../../components/card/Card';
// import ComplexTable from 'views/public/dataTables/components/ComplexTable';
// import { columnsDataComplex } from 'views/public/dataTables/variables/columnsData';
// import tableDataComplex from 'views/public/dataTables/variables/tableDataComplex.json';

import RegistroLigacoes from '../default/components/registroLigacoes'

export default function Settings() {
  const vono_token = '3f1cdc0a-5030-59ed-bd6d-3d5afaa11c02-2746'
  const vono_key = '6c99f6ad-d0bf-5d00-9052-1042d6b367ce-2406'

  const [callsList, setCallsList] = useState([])
  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(
        `https://vono.me/api/recording/${vono_token}/${vono_key}?date_ini=2024-01-01&date_end=2024-12-31&limit=10000`
      )
      setCallsList(request.data.data)
      console.log('request.data', request.data.data)
      return request
    }
    fetchData()
  }, [])

  console.log('callsList', callsList)

  return (
    <>
      <Box mt="80px">
        <RegistroLigacoes
          columnsData={[
            {
              Header: 'Audio',
              accessor: 'download'
            },
            {
              Header: 'Data',
              accessor: 'calldate'
            },

            {
              Header: 'SDR',
              accessor: 'source'
            },
            {
              Header: 'Duração',
              accessor: 'duration2'
            },
            {
              Header: 'Número',
              accessor: 'destination'
            }
          ]}
          tableData={callsList}
          vonoToken={vono_token}
          vonoKey={vono_key}
          resultsPerPage="50"
        />
      </Box>
    </>
  )
}
