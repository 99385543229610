import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAuthentication, getUserAccount } from 'hooks/authService'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import Card from '../../../components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import DealForm from './components/dealForm'
import NewActivityForm from '../formActivities/components/activityForm'
import ActivityList from '../activities/components/activityList'

export default function DealForms() {
  const textColor = useColorModeValue('navy.700', 'white')

  const [dealData, setDealData] = useState()
  const [refresh, setRefresh] = useState()

  useEffect(() => {
    console.log('dealData PAI: ', dealData)
  }, [dealData])

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, xl: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        <DealForm dealData={setDealData} />
        {dealData && (
          <Flex flexDirection="column">
            <NewActivityForm entity={'deals'} entityId={dealData?.id} refreshComponent={setRefresh} />
            <ActivityList
              dealId={dealData?.id}
              entity={'deals'}
              entityId={dealData?.id}
              refreshComponent={refresh}
            />
          </Flex>
        )}
      </SimpleGrid>
      {/* <ClientAnimals /> */}
    </Box>
  )
}
