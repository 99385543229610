// const SERVER_URL =
//   window.location.origin === 'https://app.ottensales.com.br' // é pra rodar no ottensales.com.br
//     ? 'https://api.ottensales.com.br'
//     : 'http://localhost:3035'

const SERVER_URL =
  window.location.origin === 'https://sales.otten.com.br'
    ? 'https://api.otten.com.br'
    : 'http://localhost:3030'

export default SERVER_URL
