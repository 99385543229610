// Chakra imports
import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea
} from '@chakra-ui/react'

// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import Tasks from './components/Tasks'
import MiniStatistics from 'components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineHeight,
  MdPeople,
  MdVerifiedUser
} from 'react-icons/md'
import { logout, getUserAuthentication, getUserAuthenticationRole } from 'hooks/authService'
import EmergencyContact from './components/EmergencyContact'
import VolunteerProfile from './components/VolunteerProfile'
import WatchedProfile from './components/WatchedProfile'
import WatchedIntro from './components/WatchedIntro'
import ActivityItem from './components/ActivityItem'
import TelemedicineRoom from './components/TelemedicineRoom'
import authHeader from 'hooks/authHeader'
import NewAccountForm from '../../admin/formAccount'
import axios from 'api/axios'
import { getUserAccount } from 'hooks/authService'
import Dashboard from 'layouts/admin'
import RegistroLigacoes from './components/registroLigacoes'
import LastApproachs from './components/LastApproachs'
import ClientsDash from './components/ClientsDash'
import { Redirect } from 'react-router-dom'
import Card from 'components/card/Card'

export default function AdminDefault() {
  const textColor = useColorModeValue('navy.700', 'white')

  const vono_token = '3f1cdc0a-5030-59ed-bd6d-3d5afaa11c02-2746'
  const vono_key = '6c99f6ad-d0bf-5d00-9052-1042d6b367ce-2406'
  const [callsList, setCallsList] = useState([])
  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(
        `https://vono.me/api/recording/${vono_token}/${vono_key}?date_ini=2024-01-01&date_end=2024-12-31&limit=10000`
      )
      setCallsList(request.data.data)
      console.log('request.data', request.data.data)
      return request
    }
    fetchData()
  }, [])

  console.log('callsList', callsList)

  const userData = getUserAuthentication()

  let userRole, main_account_id

  if (userData) {
    userRole = userData?.user?.role
    main_account_id = userData?.user?.user_data?.main_account_id
  }

  const [accountData, setAccountData] = useState({})

  useEffect(() => {
    async function getUserAccount() {
      try {
        const response = await axios.get(`/accounts/${main_account_id}`, { headers: authHeader() })
        setAccountData(response.data)
      } catch (error) {
        console.error('Crie uma nova conta.', error)
      }
    }
    getUserAccount()
  }, [])

  useEffect(() => {
    console.log('Conta autenticada:', accountData)
  }, [accountData])

  return (
    <Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
      {accountData && accountData.status === 'active' && (
        <SimpleGrid rows={2} gap="20px">
          <SimpleGrid columns={{ sm: 1, xl: 2 }} gap="20px">
            <Flex flexDirection={'column'} gap="20px">
              <ClientsDash />
              <LastApproachs />
            </Flex>
            <SimpleGrid columns={2} gap="20px">
              <Flex flexDirection={'column'} gap="20px">
                <MiniCalendar h="100%" minW="100%" selectRange={false} />
              </Flex>
              <Tasks accountId={main_account_id} />
              <Card h="fit-content">
                <Flex alignItems="center" w="100%" mb="30px">
                  <Icon as={MdBarChart} width="20px" height="20px" mr={3} color="inherit" />

                  <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                    Sales Inteligence
                  </Text>
                </Flex>
                {accountData && accountData?.business_data?.BIKey && (
                  <Link
                    href={`https://lookerstudio.google.com/s/${accountData.business_data.BIKey}`}
                    target="_blank"
                  >
                    <Text
                      background="#49bfff"
                      style={{
                        fontWeight: '500',
                        padding: '10px 20px',
                        color: 'white',
                        borderRadius: '50px',
                        width: 'fit-content'
                      }}
                      _hover={{
                        background: '#3a9dd6'
                      }}
                    >
                      Acessar relatório
                    </Text>
                  </Link>
                )}
              </Card>
              <Flex></Flex>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, xl: 2 }} gap="20px">
              {/* <ActivityItem /> */}
            </SimpleGrid>
          </SimpleGrid>

          <>
            <SimpleGrid columns={2} gap="20px">
              <RegistroLigacoes
                columnsData={[
                  {
                    Header: 'Audio',
                    accessor: 'download'
                  },
                  {
                    Header: 'Data',
                    accessor: 'calldate'
                  },

                  {
                    Header: 'SDR',
                    accessor: 'source'
                  },
                  {
                    Header: 'Duração',
                    accessor: 'duration2'
                  },
                  {
                    Header: 'Número',
                    accessor: 'destination'
                  }
                ]}
                resultsPerPage={'20'}
                tableData={callsList}
                vonoToken={vono_token}
                vonoKey={vono_key}
              />
              {/* <Flex></Flex> */}
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px">
              <Text>Suporte</Text>
              <Text>Sugestões</Text>
              <Text>Anúncios representantes</Text>
              <Text>Comunicação Otten Sales: Mídias sociais, contrate um atendente</Text>
            </SimpleGrid>
          </>
        </SimpleGrid>
      )}
    </Box>
  )
}
