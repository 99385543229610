import React from 'react'
import ReactDOM from 'react-dom'
import 'assets/css/App.css'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import AuthLayout from 'layouts/auth'
import PublicLayout from 'layouts/public'
import AdminLayout from 'layouts/admin'
import { ChakraProvider } from '@chakra-ui/react'
import { PrimeReactProvider } from 'primereact/api'
import theme from 'theme/theme'
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'

// Adicionamos o PrimeReact
import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <PrimeReactProvider value={{}}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <BrowserRouter>
            <Switch>
              <Route path={`/public`} component={PublicLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Route path={`/auth`} component={AuthLayout} />
              <Redirect from="/" to="/public" />
            </Switch>
          </BrowserRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
    </PrimeReactProvider>
  </ChakraProvider>,
  document.getElementById('root')
)
