import React from 'react'

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import { loginLocal } from 'hooks/authService'

export default function SignInForm() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorBrand = useColorModeValue('brand.700', 'brand.400')
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600')
  const textColorSecondary = 'gray.400'
  const brandStars = useColorModeValue('brand.500', 'brand.400')

  const [show, setShow] = React.useState(false)
  const [loginData, setLoginData] = React.useState(false)
  const handleClick = () => setShow(!show)

  const handleChange = (event) => {
    const { name, value } = event.target

    setLoginData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const loginSubmit = async (event) => {
    event.preventDefault()

    const { email, password } = loginData

    loginLocal(email, password)

    // setTimeout(() => {
    //   window.location.href = '/admin/painel'
    // }, 600)
  }

  return (
    <Flex
      maxW={{ base: '100%', md: 'max-content' }}
      w="100%"
      mx={{ base: 'auto', lg: '0px' }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      px={{ base: '25px', md: '0px' }}
      flexDirection="column"
    >
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          Área Restrita
        </Heading>
        <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
          Informe seu email e senha para acessar a área restrita.
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: '100%', md: '420px' }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: 'auto', lg: 'unset' }}
        me="auto"
        mb={{ base: '20px', md: 'auto' }}
      >
        {/*   <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Acesse com sua conta Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              ou
            </Text>
            <HSeparator />
          </Flex> */}
        <FormControl>
          <form onSubmit={loginSubmit}>
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              name="email"
              placeholder="endereco@email.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={handleChange}
              background="white"
              aria-label="login email"
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Senha<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                name="password"
                fontSize="sm"
                placeholder="Min. 8 caracteres"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                onChange={handleChange}
                background="white"
                aria-label="senha"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox id="remember-login" background="white" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Mantenha-me logado.
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                  Esqueceu a senha?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              borderRadius={'50px'}
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              bg="#162961"
            >
              Acessar
            </Button>
          </form>
        </FormControl>
        <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            Não registrado?
            <NavLink to="/auth/sign-up">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Crie sua conta
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
