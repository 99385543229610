import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import CSVReader from 'react-csv-reader'

const ImportComponent = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleFileLoad = (data) => {
    setLoading(true)
    try {
      // Remova a linha do cabeçalho, se houver
      const headers = data[0]
      const rows = data.slice(1).map((row) =>
        headers.reduce((acc, header, i) => {
          acc[header] = row[i]
          return acc
        }, {})
      )

      setData(rows.slice(0, 10)) // Exibir apenas 10 linhas
      setLoading(false)
      uploadData(rows)
    } catch (err) {
      setError('Erro ao processar o arquivo CSV')
      setLoading(false)
    }
  }

  const uploadData = async (results) => {
    try {
      const clients = results.map((row) => ({
        code: row['Cliente - Código'],
        name: row['Cliente - Nome'],
        cpf: row['Cliente - CPF'],
        rg: row['Cliente - RG'],
        gender: row['Cliente - Sexo'],
        email: row['Cliente - Email'],
        phones: row['Cliente - Telefones'],
        address: row['Cliente - Endereço'],
        neighborhood: row['Cliente - Bairro'],
        city: row['Cliente - Cidade'],
        state: row['Cliente - UF'],
        zipCode: row['Cliente - CEP'],
        inclusionDate: row['Cliente - Data de inclusão'],
        lastUpdate: row['Cliente - Data da última atualização'],
        origin: row['Cliente - Origem'],
        nps: row['Cliente - NPS'],
        ranking: row['Cliente - Ranking ABC'],
        payment30Days: row['Cliente - Valor pago nos últimos 30 dias'],
        payment90Days: row['Cliente - Valor pago nos últimos 90 dias'],
        payment180Days: row['Cliente - Valor pago nos últimos 180 dias'],
        payment365Days: row['Cliente - Valor pago nos últimos 365 dias'],
        averageTicket: row['Cliente - Ticket médio'],
        firstPurchaseDate: row['Cliente - Data da primeira compra'],
        lastSaleDate: row['Cliente - Última venda'],
        lastAccessDate: row['Cliente - Último acesso ao SimplesPet'],
        tags: row['Cliente - Tags']
      }))

      const animals = results.map((row) => ({
        code: row['Animal - Código'],
        name: row['Animal - Nome'],
        species: row['Animal - Espécie'],
        breed: row['Animal - Raça'],
        fur: row['Animal - Pelagem'],
        sterilized: row['Animal - Esterilização'],
        birthDate: row['Animal - Nascimento'],
        age: row['Animal - Idade'],
        gender: row['Animal - Sexo'],
        pedigree: row['Animal - Pedigree'],
        chip: row['Animal - Chip'],
        tags: row['Animal - Tags'],
        status: row['Animal - Status'],
        inclusionDate: row['Animal - Data de inclusão'],
        alive: row['Animal - Vivo/Morto'],
        clientCode: row['Cliente - Código']
      }))

      await axios.post(
        '/clients/import',
        { clients },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      console.log('Clientes importados com sucesso')

      await axios.post(
        '/animals/import',
        { animals },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      console.log('Animais importados com sucesso')
    } catch (error) {
      console.error('Erro ao importar dados:', error)
      setError('Erro ao importar dados')
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex flexDirection="column">
        <Text fontSize="24px">Importar Clientes e Animais</Text>
        <CSVReader
          cssClass="csv-reader-input"
          label="Selecione o arquivo CSV"
          onFileLoaded={handleFileLoad}
          onError={setError}
          parserOptions={{
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) => header.trim()
          }}
        />
        {loading && <p>Processando...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Text fontSize="24px">Pré-visualização dos Dados (10 linhas)</Text>
        {data.length > 0 && (
          <table>
            <thead>
              <tr>
                {Object.keys(data[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, i) => (
                    <td key={i}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Flex>
    </Box>
  )
}

export default ImportComponent
