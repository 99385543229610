import React, { useState, useEffect } from 'react'
import { Box, Flex, Link, Tag, Text } from '@chakra-ui/react'
import {
  FaPhone,
  FaMapMarkerAlt,
  FaTasks,
  FaRegCalendarAlt,
  FaRegEnvelope,
  FaCommentAlt
} from 'react-icons/fa'

export function ActivityCard({ itemData }) {
  const { item, index } = itemData

  function getActivityIcon(activityType) {
    switch (activityType) {
      case 'call':
        return <FaPhone color="white" />
      case 'task':
        return <FaTasks color="white" />
      case 'meeting':
        return <FaRegCalendarAlt color="white" />
      case 'visit':
        return <FaMapMarkerAlt color="white" />
      case 'email':
        return <FaRegEnvelope color="white" />
      case 'message':
        return <FaCommentAlt color="white" />
      default:
        return null
    }
  }
  return (
    <Link href={`/admin/deal/${item.entity_id}`}>
      <Flex
        flexDirection={'row'}
        bg="white"
        borderRadius={'10px 30px 10px 10px'}
        p="10px"
        w="250px"
        justifyContent="space-between"
        boxShadow={index === 0 ? '2xl' : index < 3 ? 'lg' : 'sm'}
        draggable
      >
        <Flex flexDirection="column">
          <Text>{`${item.deal.name}`}</Text>
          <Text
            fontSize="sm"
            /* Não deve ser o owner, é necessário popular o deal e puxar a empresa e o contato do deal e  */
            /* Incluir tbm step do pipeline */
            fontWeight={'800'}
          >{`${item?.deal?.contact?.first_name} ${item?.deal?.contact?.last_name}, ${item?.deal?.contact?.position}`}</Text>
          <Text fontSize="sm">{item?.deal?.company?.name}</Text>
          <Text fontSize="sm">{item?.description}</Text>
          <Tag
            bg="#E2E8F0"
            w={'fit-content'}
            fontSize={'0.75rem'}
            borderRadius={'20px'}
            mt="5px"
          >{`${item?.deal?.step?.name}`}</Tag>
        </Flex>
        <Flex flexDirection="column" w="30%" alignItems="flex-end">
          <Box bg={index === 0 ? '#4dadd7' : 'gray.300'} p="10px" borderRadius="50px">
            {item?.type && getActivityIcon(item.type)}
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}
