import { useEffect, useState } from 'react'
import axios from 'axios'

export function useGender(nomeDoContato) {
  const [gender, setGender] = useState('')

  useEffect(() => {
    async function fetchData() {
      const masculino = await axios.get(
        `https://servicodados.ibge.gov.br/api/v2/censos/nomes/${nomeDoContato}?sexo=M`
      )

      const feminino = await axios.get(
        `https://servicodados.ibge.gov.br/api/v2/censos/nomes/${nomeDoContato}?sexo=F`
      )

      const masculinoCount = masculino?.data[0]?.res ? getFrequenciaTotal(masculino?.data[0]?.res) : 0
      const femininoCount = feminino?.data[0]?.res ? getFrequenciaTotal(feminino?.data[0]?.res) : 0

      console.log('nomeDoContato:', nomeDoContato)
      console.log('masculinoCount:', feminino)
      console.log('femininoCount:', masculino)

      if (masculinoCount > femininoCount) {
        setGender('Sr.')
      } else if (femininoCount > masculinoCount) {
        setGender('Sra.')
      } else {
        setGender('')
      }
    }

    // Adicionando o delay com setTimeout
    const timeoutId = setTimeout(fetchData, 500)

    // Cleanup do timeout
    return () => clearTimeout(timeoutId)
  }, [nomeDoContato])

  function getFrequenciaTotal(data) {
    // console.log('data em frequencia:', data);
    return data.reduce((acc, curr) => {
      return acc + curr.frequencia
    }, 0)
  }

  console.log('Gender: ', gender)
  return gender
}
