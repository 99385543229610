import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Button,
  Stack,
  Text,
  Textarea,
  useToast,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Select,
  SimpleGrid
} from '@chakra-ui/react'
import { Toast } from 'primereact/toast'
import { PhoneIcon } from '@chakra-ui/icons'
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input/input';

import SendWhatsAppApproach from 'components/sendWhatsAppApproach'
import InputMask from 'react-input-mask'
import axios from 'api/axios'
import { capitalizeText } from 'hooks/capitalizeText'
import {
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
  FaHome,
  FaLinkedin,
  FaFacebook,
  FaIndustry,
  FaUserTie,
  FaClock
} from 'react-icons/fa'
import Rating from 'components/rating/rating'
import { useGender } from 'hooks/setGender'
import { getUserAuthentication } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'

const ContactForm = ({ dados }) => {
  console.log('dados:', dados)

  const account_id = getUserAccountId()

  useEffect(() => {
    account_id && setCompanyData((prevState) => ({ ...prevState, account_id }))
  }, [account_id])

  const {
    razao_social,
    estabelecimento: {
      nome_fantasia,
      cnpj,
      capital_social,
      situacao_cadastral,
      email,
      cep,
      ddd1,
      ddd2,
      telefone1: phone1,
      telefone2: phone2,
      tipo_logradouro,
      logradouro,
      numero,
      complemento,
      bairro,
      data_inicio_atividade,
      atividade_principal: { id: cnae_principal, descricao: cnae_principal_descricao },
      cidade: { nome: cidade },
      estado: { sigla: estado },
      pais: { nome: pais }
    },
    natureza_juridica: { descricao: natureza_juridica },
    porte: { descricao: porte },
    socios
  } = dados || {}

  const [socio1, socio2, socio3] = dados?.socios || []
  let { nome: nome1 } = socio1 || ''
  const { nome: nome2 } = socio2 || ''
  const { nome: nome3 } = socio3 || ''

  // Capitalizar variaveis:
  const razao_socialFormatada = razao_social && capitalizeText(razao_social)
  const nome_fantasiaFormatado = nome_fantasia && capitalizeText(nome_fantasia)
  const tipo_logradouroFormatada = tipo_logradouro && capitalizeText(tipo_logradouro)
  const logradouroFormatada = logradouro && capitalizeText(logradouro)
  const complementoFormatado = complemento && capitalizeText(complemento)
  const bairroFormatado = bairro && capitalizeText(bairro)
  let nome1Formatado = nome1 && capitalizeText(nome1)
  let nome2Formatado = nome2 && capitalizeText(nome2)
  let nome3Formatado = nome3 && capitalizeText(nome3)

  const userAuth = getUserAuthentication()
  const userAuthData = userAuth?.user
  const userAuthDataEmail = userAuthData?.email

  // Lista User ID Bitrix24
  const emailToB24UserId = useMemo(
    () => ({
      'carla.campos@otten.com.br': '11'
    }),
    []
  )

  useEffect(() => {
    if (userAuthDataEmail && emailToB24UserId[userAuthDataEmail]) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        b24userId: emailToB24UserId[userAuthDataEmail]
      }))
    }
  }, [userAuthDataEmail, emailToB24UserId])

  if (natureza_juridica === 'Empresário (Individual)') {
    nome1Formatado = razao_socialFormatada
  }

  const [rating, setRating] = useState(0)

  const contato1NomeSegregado = nome1Formatado && nameSegregate(nome1Formatado)
  const contato2NomeSegregado = nome2Formatado && nameSegregate(nome2Formatado)
  const contato3NomeSegregado = nome3Formatado && nameSegregate(nome3Formatado)

  const handleRatingChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const [companyData, setCompanyData] = useState({
    account_id: '',
    name: nome_fantasia ? nome_fantasia : razao_social,
    business_data: {
      cnpj: cnpj,
      razao_social: razao_social,
      situacao_cadastral: situacao_cadastral,
      natureza_juridica: natureza_juridica,
      porte: porte,
      data_abertura: data_inicio_atividade,
      capital_social: capital_social,
      optante_simples: '',
      tipo: '', // matriz/filial
      atividade_principal: {
        cnae: `${cnae_principal} ${cnae_principal_descricao}`
      },
      atividade_secundaria: [
        {
          cnae: ''
        }
      ]
    },
    contact_data: {
      telefone: [
        {
          tipo: 'Fixo', // Fixo, whatsapp, móvel, voip
          numero: `55 ${ddd1 && ddd1} ${phone1 && phone1}`
        },
        {
          tipo: 'Fixo', // Fixo, whatsapp, móvel, voip
          numero: `55 ${ddd2 && ddd2} ${phone2 && phone2}`
        }
      ],
      email: [
        {
          tipo: 'Principal', // principal, suporte, ...
          endereco_email: email
        }
      ]
    },

    address: {
      pais: pais,
      uf: estado,
      municipio: cidade,
      bairro: bairro,
      tipo_logradouro: tipo_logradouro,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      cep: cep
    },
    pipeline: 'new',
    profile: {
      area_atuacao: '',
      funcionarios: '',
      faturamento_anual: '',
      descricao: ''
    },
    company_logo_url: ''
  })

  const [formData, setFormData] = useState({
    website: '',
    nome_fantasia: nome_fantasiaFormatado,
    cnpj,
    situacao_cadastral,
    natureza_juridica,
    porte,
    data_fundacao: data_inicio_atividade,
    capital_social,
    cnae_principal,
    cnae_principal_descricao,
    telefone1: `(${ddd1}) ${phone1 && adiciona9SeForCelular(phone1)}`,
    telefone2: `(${ddd2}) ${phone2 && adiciona9SeForCelular(phone2)}`,
    whatsapp: '',
    email,
    instagram: '',
    facebook: '',
    linkedin: '',
    razao_social: razao_socialFormatada,
    endereco: `${tipo_logradouroFormatada} ${logradouroFormatada}, ${numero}`,
    complemento: complementoFormatado,
    bairro: bairroFormatado,
    cep,
    cidade,
    estado,
    pais,
    socios,
    contato1PrimeiroNome: contato1NomeSegregado?.primeiroNome,
    contato1Sobrenome: contato1NomeSegregado?.sobrenome,
    saudacaoContato1: '',
    contato1: nome1Formatado,
    contato2: nome2Formatado,
    contato2PrimeiroNome: contato2NomeSegregado?.primeiroNome,
    contato2Sobrenome: contato2NomeSegregado?.sobrenome,
    saudacaoContato2: '',
    contato3: nome3Formatado,
    contato3PrimeiroNome: contato3NomeSegregado?.primeiroNome,
    contato3Sobrenome: contato3NomeSegregado?.sobrenome,
    contato1_email: '',
    contato2_email: '',
    contato3_email: '',
    contato1_whatsapp: '',
    contato2_whatsapp: '',
    contato3_whatsapp: '',
    contato1_melhor_horario: '',
    contato2_melhor_horario: '',
    dados_receita_federal: dados,
    instagram_qualidade: 0,
    website_qualidade: 0,
    marketing_digital_qualidade: 0,
    processo_comercial: 0,
    mensagem_personalizada: '',
    erp_clinica: '',
    obs_abordagem: '',
    prioridade: '',
    qtd_colaboradores: '',
    qtd_clientes: '',
    user_email: userAuthDataEmail,
    b24userId: '1',
    faturamento_mes_estimado: 0
  })

  const novaSaudacaoContato1 = useGender(formData?.contato1PrimeiroNome)
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      saudacaoContato1: novaSaudacaoContato1
    }))
  }, [novaSaudacaoContato1, formData.contato1PrimeiroNome])

  const novaSaudacaoContato2 = useGender(formData?.contato2PrimeiroNome)
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      saudacaoContato2: novaSaudacaoContato2
    }))
  }, [novaSaudacaoContato2, formData.contato2PrimeiroNome])

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  //   if (e.target.name === 'qtd_colaboradores') {
  //     setFormData({
  //       ...formData,
  //       faturamento_mes_estimado: e.target.value * 8000,
  //       return
  //     });
  //   }

  //   console.log('formData:', formData);
  //   return
  // };

  const handleChange = (e) => {
    // Callback de atualização do estado para garantir operação no estado mais recente
    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [e.target.name]: e.target.value }

      if (e.target.name === 'qtd_colaboradores') {
        updatedFormData.faturamento_mes_estimado = `R$ ${e.target.value * 5}k a R$ ${e.target.value * 10}k`
      }

      return updatedFormData
    })

    console.log('formData:', formData)
  }

  function nameSegregate(nomeCompleto) {
    // Expressão regular para encontrar o primeiro nome e o restante do nome
    const regex = /^(\S+)\s+(.*)/

    const match = nomeCompleto.match(regex)
    if (match) {
      return {
        primeiroNome: match[1],
        sobrenome: match[2]
      }
    } else {
      return {}
    }
  }

  function phoneCleaner(texto) {
    return texto.replace(/[ -]/g, '')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Dados a serem enviados:', companyData)

    axios
      .post('/companies', companyData, {
        headers: authHeader()
      })
      .then((res) => {
        console.log('Contato adicionado com sucesso: ', res)
        toastSuccess('Adicionado com sucesso.')
      })
      .catch((err) => {
        console.log('Erro ao adicionar contato: ', err)
        toastError('Houve um erro ao adicionar essa empresa.')
      })
  }

  // USANDO O N8N...
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   console.log('Dados a serem enviados:', formData)

  //   axios
  //     .post(
  //       // 'https://ottensales.app.n8n.cloud/webhook/109b7e3e-47a8-4142-9931-d6ef2df774a7',
  //       'https://n8.otten.com.br/webhook/9d6af763-a20d-4f11-957a-aa41d8d95db0',
  //       // 'https://n8.otten.com.br/webhook-test/9d6af763-a20d-4f11-957a-aa41d8d95db0',
  //       formData,
  //       {
  //         headers: {
  //           ottensales: 'cmZkLm90dGVuZmVsc0BnbWFpbC5jb206U2sxcDMqMjAxMQ=='
  //         }
  //       }
  //     )
  //     .then((res) => {
  //       console.log('Contato adicionado com sucesso: ', res)
  //     })
  //     .catch((err) => {
  //       console.log('Erro ao adicionar contato: ', err)
  //     })
  // }

  function adiciona9SeForCelular(numero) {
    let numeroStr = numero.toString()
    if (numeroStr[0] === '8' || numeroStr[0] === '9') {
      numeroStr = '9' + numeroStr
    }
    let numeroComNove = parseInt(numeroStr)
    return numeroComNove
  }

  function formatarTelefone(numeroComDdd) {
    const numeroSemCaracteresEspeciais = numeroComDdd.replace(/[^0-9]/g, '')

    if (numeroSemCaracteresEspeciais.length < 9) {
      throw new Error('Número de telefone inválido. Quantidade mínima de dígitos não atingida.')
    }
    const ddd = numeroSemCaracteresEspeciais.substring(0, 2)
    if (!ddd || ddd === '00') {
      throw new Error('DDD inválido.')
    }
    return `${ddd}${numeroSemCaracteresEspeciais.substring(2)}`
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <SimpleGrid columns={4} columnGap="20px">
          <Stack
            spacing={2}
            style={{
              backgroundColor: '#fff',
              borderRadius: '30px',
              padding: '20px'
            }}
          >
            <FormControl id="nome_fantasia">
              <FormLabel
                style={{
                  fontSize: '14px',
                  marginBottom: '15px',
                  marginLeft: '15px'
                }}
              >
                Dados da Empresa
              </FormLabel>
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaIndustry} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="name"
                  placeholder="Nome Fantasia"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="telefone1">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={PhoneIcon} mr="5px" />
                  </InputLeftAddon>
                  <InputMask
                    type="tel"
                    name="telefone1"
                    placeholder="Telefone Fixo 1"
                    value={formData.telefone1}
                    onChange={handleChange}
                    maskChar=" "
                    mask="(99) 999999999"
                    style={{
                      width: '100%',
                      paddingInlineStart: 'var(--chakra-space-4)',
                      paddingInlineEnd: 'var(--chakra-space-10)',
                      height: 'var(--chakra-sizes-10)',
                      border: '1px solid',
                      borderColor: 'inherit',
                      background: 'inherit',
                      borderRadius: '0 50px 50px 0'
                    }}
                  />
                  <InputRightElement width="2.7rem">
                    <IconButton
                      colorScheme={formData.telefone1 ? 'green' : 'gray'}
                      aria-label="Call Segun"
                      size="sm"
                      onClick={() => {
                        const telefoneCompleto = `0${phoneCleaner(formData.telefone1)}`
                        window.location.href = `callto:${telefoneCompleto}`
                      }}
                      icon={<PhoneIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="telefone2">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={PhoneIcon} mr="5px" />
                  </InputLeftAddon>
                  <InputMask
                    type="tel"
                    name="telefone2"
                    placeholder="Telefone Fixo 2"
                    value={formData.telefone2}
                    onChange={handleChange}
                    maskChar=" "
                    mask="(99) 999999999"
                    style={{
                      width: '100%',
                      borderRadius: '0px 50px 50px 0px',
                      paddingInlineStart: 'var(--chakra-space-4)',
                      paddingInlineEnd: 'var(--chakra-space-10)',
                      height: 'var(--chakra-sizes-10)',
                      border: '1px solid',
                      borderColor: 'inherit',
                      background: 'inherit'
                    }}
                  />
                  <InputRightElement width="2.7rem">
                    <IconButton
                      colorScheme={formData.telefone2 ? 'green' : 'gray'}
                      aria-label="Call Segun"
                      size="sm"
                      onClick={() => {
                        const telefoneCompleto = `0${formData.telefone2}`
                        window.location.href = `callto:${telefoneCompleto}`
                      }}
                      icon={<PhoneIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="whatsapp">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaWhatsapp} mr="5px" />
                  </InputLeftAddon>

                  <InputMask
                    type="tel"
                    name="whatsapp"
                    placeholder="Whatsapp ou Celular"
                    value={formData.whatsapp}
                    onChange={handleChange}
                    maskChar=" "
                    mask="(99) 999999999"
                    style={{
                      width: '100%',
                      paddingInlineStart: 'var(--chakra-space-4)',
                      paddingInlineEnd: 'var(--chakra-space-10)',
                      height: 'var(--chakra-sizes-10)',
                      border: '1px solid',
                      borderColor: 'inherit',
                      background: 'inherit',
                      borderRadius: '0px 50px 50px 0px',
                      borderLeftRadius: '0px',
                      borderRightRadius: '50px'
                    }}
                  />
                  <InputRightElement width="2.7rem">
                    <IconButton
                      colorScheme={formData.whatsapp ? 'green' : 'gray'}
                      aria-label="Call Segun"
                      size="sm"
                      p="1px"
                      onClick={() => {
                        const numerowhatsapp = `55${formData.whatsapp}`
                        window.location.href = `https://api.whatsapp.com/send?phone=${formatarTelefone(
                          numerowhatsapp
                        )}`
                      }}
                      icon={<Icon as={FaWhatsapp} />}
                    />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="email">
              <Stack>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaEnvelope} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                  <InputRightElement width="2.7rem">
                    <IconButton
                      colorScheme={formData.whatsapp ? 'green' : 'gray'}
                      aria-label="Call Segun"
                      size="sm"
                      p="1px"
                      onClick={() => {
                        window.location.href = `#`
                      }}
                      icon={<Icon as={FaEnvelope} />}
                    />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="website">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaHome} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="website"
                    placeholder="https://"
                    value={formData.website}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="instagram">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaInstagram} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="instagram"
                    placeholder="https://instagram.com/"
                    value={formData.instagram}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="facebook">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaFacebook} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="facebook"
                    placeholder="https://facebook.com/"
                    value={formData.facebook}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="linkedin">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaLinkedin} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="linkedin"
                    placeholder="https://linkedin.com/in/"
                    value={formData.linkedin}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="mensagem_personalizada">
              <Textarea
                type="text"
                name="mensagem_personalizada"
                placeholder={`Vi o site da ${nome_fantasia} e achei muito legal...`}
                value={formData.mensagem_personalizada}
                onChange={handleChange}
                style={{ borderRadius: '20px' }}
              />
            </FormControl>
          </Stack>
          <Stack
            style={{
              backgroundColor: '#fff',
              borderRadius: '30px',
              padding: '20px'
            }}
          >
            <FormControl id="contato1">
              <FormLabel
                style={{
                  fontSize: '14px',
                  marginBottom: '15px',
                  marginLeft: '15px'
                }}
              >
                Dados dos Contatos
              </FormLabel>
              <FormHelperText
                style={{
                  fontSize: '11px',
                  marginBottom: '3px',
                  marginLeft: '17px'
                }}
              >
                {socio1?.qualificacao_socio?.descricao
                  ? `${socio1?.qualificacao_socio?.descricao}, ${socio1?.faixa_etaria}`
                  : 'Decisor 1'}
              </FormHelperText>
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px',
                    paddingInlineEnd: '0'
                  }}
                >
                  <Icon as={FaUserTie} mr="16px" />
                </InputLeftAddon>
                <Select
                  name="saudacaoContato1"
                  placeholder="Saudação"
                  value={formData.saudacaoContato1}
                  onChange={handleChange}
                  style={{
                    borderRadius: '0',
                    paddingInlineEnd: '0'
                  }}
                >
                  <option value="Dr.">Dr.</option>
                  <option value="Dra.">Dra.</option>
                  <option value="Sr.">Sr.</option>
                  <option value="Sra.">Sra.</option>
                </Select>
                <Input
                  type="text"
                  name="contato1PrimeiroNome"
                  placeholder="Nome do decisor"
                  value={formData.contato1PrimeiroNome}
                  onChange={handleChange}
                  style={{ borderRadius: '0', width: '310px' }}
                />
                <Input
                  type="text"
                  name="contato1Sobrenome"
                  placeholder="Nome do decisor"
                  value={formData.contato1Sobrenome}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>

            <FormControl id="contato1_email">
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaEnvelope} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="contato1_email"
                  placeholder="Email do contato 1"
                  value={formData.contato1_email}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="contato1_whatsapp">
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaWhatsapp} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="contato1_whatsapp"
                  placeholder="WhatsApp do contato 1"
                  value={formData.contato1_whatsapp}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="contato1_melhor_horario">
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaClock} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="contato1_melhor_horario"
                  placeholder="Melhor horário para contatar"
                  value={formData.contato1_melhor_horario}
                  onChange={handleChange}
                  mb="22px"
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>

            <FormControl id="contato2">
              <FormHelperText
                style={{
                  fontSize: '11px',
                  marginBottom: '3px',
                  marginLeft: '17px'
                }}
              >
                {socio2?.qualificacao_socio?.descricao
                  ? `${socio2?.qualificacao_socio?.descricao}, ${socio2?.faixa_etaria}`
                  : 'Decisor 2'}
              </FormHelperText>
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px',
                    paddingInlineEnd: '0'
                  }}
                >
                  <Icon as={FaUserTie} mr="16px" />
                </InputLeftAddon>
                <Select
                  name="saudacaoContato2"
                  placeholder=""
                  value={formData.saudacaoContato2}
                  onChange={handleChange}
                  style={{
                    borderRadius: '0',
                    paddingInlineEnd: '0'
                  }}
                >
                  <option value=""></option>
                  <option value="Dr.">Dr.</option>
                  <option value="Dra.">Dra.</option>
                  <option value="Sr.">Sr.</option>
                  <option value="Sra.">Sra.</option>
                </Select>
                <Input
                  type="text"
                  name="contato2PrimeiroNome"
                  placeholder="Nome do decisor"
                  value={formData.contato2PrimeiroNome}
                  onChange={handleChange}
                  style={{ borderRadius: '0', width: '310px' }}
                />
                <Input
                  type="text"
                  name="contato2Sobrenome"
                  placeholder="Nome do decisor"
                  value={formData.contato2Sobrenome}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="contato2_email">
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaEnvelope} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="contato2_email"
                  placeholder="Email do contato 2"
                  value={formData.contato2_email}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="contato2_whatsapp">
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaWhatsapp} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="contato2_whatsapp"
                  placeholder="WhatsApp do contato 2"
                  value={formData.contato2_whatsapp}
                  onChange={handleChange}
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="contato2_melhor_horario">
              <InputGroup>
                <InputLeftAddon
                  width="50px"
                  style={{
                    backgroundColor: '#FAFCFE',
                    color: '#A0AEC0',
                    borderRadius: '50px 0 0 50px'
                  }}
                >
                  <Icon as={FaUserTie} mr="5px" />
                </InputLeftAddon>
                <Input
                  type="text"
                  name="contato2_melhor_horario"
                  placeholder="Melhor horário para contatar"
                  value={formData.contato2_melhor_horario}
                  onChange={handleChange}
                  mb="22px"
                  style={{ borderRadius: '0 50px 50px 0' }}
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <Stack
            spacing={2}
            style={{
              backgroundColor: '#fff',
              borderRadius: '30px',
              padding: '20px'
            }}
          >
            <FormLabel
              style={{
                fontSize: '14px',
                marginBottom: '15px',
                marginLeft: '15px'
              }}
            >
              Dados de Qualificação do Negócio
            </FormLabel>

            <FormControl id="qtd_clientes">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="200px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    Clientes cadastrados
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="qtd_clientes"
                    placeholder="Quantos?"
                    value={formData.qtd_clientes}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="erp_clinica">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="200px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    Sistema ERP
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="erp_clinica"
                    placeholder="Qual usa?"
                    value={formData.erp_clinica}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="qtd_colaboradores">
              <Stack spacing={2}>
                <InputGroup>
                  <InputLeftAddon
                    width="200px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    Funcionários
                  </InputLeftAddon>
                  <Input
                    type="text"
                    name="qtd_colaboradores"
                    placeholder="Quantos?"
                    value={formData.qtd_colaboradores}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px'
                    }}
                  />
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="prioridade">
              <Stack>
                <InputGroup>
                  <InputLeftAddon
                    width="200px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    Prioridade em resolver
                  </InputLeftAddon>

                  <Select
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="autor"
                    placeholder="Qual?"
                    fontWeight="500"
                    size="md"
                    name="prioridade"
                    value={formData.prioridade}
                    onChange={handleChange}
                    style={{
                      borderTopRightRadius: '50px',
                      borderBottomRightRadius: '50px',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px'
                    }}
                  >
                    <option value="Essa Semana">Essa Semana</option>
                    <option value="Em 15 dias">Em 15 dias</option>
                    <option value="Em 30 dias">Em 30 dias</option>
                    <option value="Em 2 meses">Em 2 meses</option>
                    <option value="Em 3 meses">Em 3 meses</option>
                    <option value="Em 6 meses">Em 6 meses</option>
                    <option value="Em 12 meses">Em 12 meses</option>
                  </Select>
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="obs_abordagem">
              <Textarea
                type="text"
                name="obs_abordagem"
                placeholder={`Informações coletadas na abordagem`}
                value={formData.obs_abordagem}
                onChange={handleChange}
                style={{ borderRadius: '20px' }}
              />
            </FormControl>
            <Text style={{ color: '#A0AEC0', marginBottom: '24px' }}>
              Faturamento mensal estimado:{' '}
              <span style={{ color: '#2D3748' }}>{formData?.faturamento_mes_estimado}</span>
            </Text>
            <SimpleGrid columns={2} columnGap="20px">
              <FormControl id="instagram_qualidade">
                <Stack direction="row">
                  <Rating
                    name="instagram_qualidade"
                    defaultValue={formData.website_qualidade}
                    onChange={handleRatingChange}
                  />
                  <Text>Instagram</Text>
                </Stack>
                {/* <RadioGroup
                onChange={(e) => handleRadioChange(e, 'instagram_qualidade')}
                value={formData.instagram_qualidade}
                name='instagram_qualidade'
              >
                <Text mb='5px'>Instagram</Text>
                <Stack direction='row'>
                  <Radio value='ruim'>Ruim</Radio>
                  <Radio value='bom'>Bom</Radio>
                  <Radio value='muito bom'>Muito bom</Radio>
                </Stack>
              </RadioGroup> */}
              </FormControl>
              <FormControl id="marketing_digital_qualidade">
                <Stack direction="row">
                  <Rating
                    name="marketing_digital_qualidade"
                    defaultValue={formData.website_qualidade}
                    onChange={handleRatingChange}
                  />
                  <Text>Marketing</Text>
                </Stack>
                {/* <RadioGroup
                name='marketing_digital_qualidade'
                onChange={(e) =>
                  handleRadioChange(e, 'marketing_digital_qualidade')
                }
                value={formData?.marketing_digital_qualidade}
              >
                <FormLabel
                  style={{
                    fontSize: '14px',
                    marginBottom: '15px',
                    marginLeft: '15px',
                  }}
                >
                  Marketing
                </FormLabel>
                <Stack direction='row'>
                  <Radio value='N/D'>N/D</Radio>
                  <Radio value='Ruim'>Ruim</Radio>
                  <Radio value='Bom'>Bom</Radio>
                  <Radio value='Muito bom'>Muito bom</Radio>
                </Stack>
              </RadioGroup> */}
              </FormControl>
              <FormControl id="website_qualidade">
                <Stack direction="row">
                  <Rating
                    name="website_qualidade"
                    defaultValue={formData.website_qualidade}
                    onChange={handleRatingChange}
                  />
                  <Text>Website</Text>
                </Stack>

                {/* <RadioGroup
                onChange={(e) => handleRadioChange(e, 'website_qualidade')}
                value={formData.website_qualidade}
              >
                <Text mb='5px'>Site</Text>
                <Stack direction='row'>
                  <Radio value='N/D'>N/D</Radio>
                  <Radio value='Bom'>Bom</Radio>
                  <Radio value='Muito bom'>Muito bom</Radio>
                </Stack>
              </RadioGroup> */}
              </FormControl>
              <FormControl id="processo_comercial">
                <Stack direction="row">
                  <Rating
                    name="processo_comercial"
                    defaultValue={formData.processo_comercial}
                    onChange={handleRatingChange}
                  />
                  <Text>Comercial</Text>
                </Stack>

                {/* <RadioGroup
                onChange={(e) => handleRadioChange(e, 'website_qualidade')}
                value={formData.website_qualidade}
              >
                <Text mb='5px'>Site</Text>
                <Stack direction='row'>
                  <Radio value='N/D'>N/D</Radio>
                  <Radio value='Bom'>Bom</Radio>
                  <Radio value='Muito bom'>Muito bom</Radio>
                </Stack>
              </RadioGroup> */}
              </FormControl>
            </SimpleGrid>

            <Button type="submit" colorScheme="blue" style={{ marginTop: '30px', borderRadius: '100px' }}>
              Adicionar ao CRM
            </Button>
          </Stack>
          <Stack
            style={{
              backgroundColor: '#fff',
              borderRadius: '30px',
              padding: '20px'
            }}
          >
            <SendWhatsAppApproach
              responsavel={formData.contato1}
              whatsappEmpresa={formData.whatsapp}
              nomeFantasia={formData.nome_fantasia}
            />
            <Text
              style={{
                fontSize: '14px',
                marginBottom: '15px',
                marginLeft: '15px'
              }}
            >
              Script de abordagem
            </Text>
            <p
              style={{
                fontSize: '8px',
                color: '#ccc',
                lineHeight: '8px',
                marginTop: '30px'
              }}
            >
              Possui um trabalho comercial profissional?
            </p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>NTBA</p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>Necessidade</p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>Prioridade</p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>Orçamento</p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>Decisor</p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>AGENDAR Apresentacao</p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>
              Note p Rolf. Nome, Celular e Email do Decisor: adicionar ao publico do Meta/Linkedin
            </p>
            <p style={{ fontSize: '8px', color: '#ccc', lineHeight: '8px' }}>Integração com Apolo/Snovio</p>
          </Stack>
        </SimpleGrid>
        <Toast ref={toast} />
      </Box>
    </form>
  )
}

export default ContactForm
