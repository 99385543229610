import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormHelperText,
  InputRightElement,
  IconButton,
  Icon,
  Button,
  Checkbox,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import {
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
  FaHome,
  FaLinkedin,
  FaFacebook,
  FaBuilding,
  FaIndustry,
  FaPhone,
  FaCity,
  FaUserTie,
  FaClock,
  FaBriefcase,
  FaMapPin,
  FaUsers,
  FaMapMarkerAlt,
  FaMapSigns,
  FaGlobe,
  FaSearch,
  FaInfoCircle,
  FaDollarSign,
  FaGavel,
  FaCalendarAlt,
  FaCheckCircle,
  FaFileAlt,
  FaPlus
} from 'react-icons/fa'
import DailyTasks from './dailyTasks'

export default function CompanyForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()
  const [sendAlert, setSendAlert] = useState('')

  return (
    <Flex flexDirection={'column'} gap="20px">
      <DailyTasks />
      {/* <NextTasks /> */}
      {/* <DailyTasksGoal /> // Lista de objetivos das tarefas to dia */}
      {/* <DailyInsights /> //Dicas para o seller cumprir as metas do dia */}
    </Flex>
  )
}
