import React, { useState, useEffect } from 'react'
import { Flex, Progress, Select, useColorModeValue, Tag, Text } from '@chakra-ui/react'
import { ActivityCard } from './ActivityCard'
import { ActiveActivityCard } from './ActiveActivityCard'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
// import { taskList } from './fakeTaskData' // FAKE DATA FOR LAYOUT
import {
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
  FaHome,
  FaLinkedin,
  FaFacebook,
  FaBuilding,
  FaIndustry,
  FaPhone,
  FaCity,
  FaUserTie,
  FaClock,
  FaBriefcase,
  FaMapPin,
  FaUsers,
  FaMapMarkerAlt,
  FaMapSigns,
  FaGlobe,
  FaSearch,
  FaInfoCircle,
  FaDollarSign,
  FaGavel,
  FaCalendarAlt,
  FaCheckCircle,
  FaFileAlt,
  FaPlus,
  FaTasks,
  FaRegCalendarAlt,
  FaRegEnvelope,
  FaCommentAlt
} from 'react-icons/fa'
import { SelectButton } from 'primereact/selectbutton'

export default function CompanyForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()
  const [typeFilter, setTypeFilter] = useState('')
  const [activityList, setActivityList] = useState([])

  const [selectedActivity, setSelectedActivity] = useState({
    activity_id: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_position: '',
    contact_phone: '',
    contact_phone_type: '',
    contact_email: '',
    company_name: '',
    step: '',
    status: '',
    type: '',
    deal_name: '',
    deal_description: '',
    deal_id: ''
  })

  const statusList = [
    {
      name: 'Fazer',
      value: 'todo'
    },
    {
      name: 'Fazendo',
      value: 'doing'
    },
    {
      name: 'Impedida',
      value: 'blocked'
    },
    {
      name: 'Adiada',
      value: 'postponed'
    },
    {
      name: 'Cancelada',
      value: 'canceled'
    },
    {
      name: 'Realizada',
      value: 'done'
    }
  ]

  const taskTypes = [
    {
      name: 'Ligações',
      value: 'call'
    },
    {
      name: 'Mensagens',
      value: 'message'
    },
    {
      name: 'Emails',
      value: 'email'
    },
    {
      name: 'Tarefas',
      value: 'task'
    },
    {
      name: 'Reuniões',
      value: 'meeting'
    },
    {
      name: 'Visitas',
      value: 'visit'
    }
  ]

  function getActivityIcon(activityType) {
    switch (activityType) {
      case 'call':
        return <FaPhone color="white" />
      case 'task':
        return <FaTasks color="white" />
      case 'meeting':
        return <FaRegCalendarAlt color="white" />
      case 'visit':
        return <FaMapMarkerAlt color="white" />
      case 'email':
        return <FaRegEnvelope color="white" />
      case 'message':
        return <FaCommentAlt color="white" />
      default:
        return null
    }
  }

  function sortAndFilterTasks(tasks, type) {
    return tasks
      .filter((task) => !type || task.type === type)
      .sort((a, b) => new Date(b.dead_line) - new Date(a.dead_line))
  }

  async function getAccountActivities(account_id) {
    try {
      const { data } = await axios.get(`/activities?account_id=${account_id}`, { headers: authHeader() })
      const filtredTaskList = sortAndFilterTasks(data.data, typeFilter)
      setActivity(filtredTaskList[0])
      console.log('accountActivities: ', filtredTaskList)
      return setActivityList(filtredTaskList, typeFilter)
    } catch (err) {
      console.log('Erro ao carregar as atividades', err)
    }
  }

  async function handleChange(event) {
    const { name, value } = event.target
    setActivity((prevActivity) => ({
      ...prevActivity,
      [name]: value
    }))
  }

  async function patchActiveActivity(event) {
    const { name, value } = event.target
    console.log('patchActiveActivity: ', name, value)

    setSelectedActivity((prevState) => ({ ...prevState, state: value }))

    try {
      const response = await axios.patch(
        `/activities/${selectedActivity.activity_id}`,
        { status: value },
        {
          headers: authHeader()
        }
      )
      getAccountActivities(account_id)
      console.log('Atividade atualizada!', response)
    } catch (err) {
      console.log('Não foi possível atualizar a atividade', err)
    }
  }

  useEffect(() => {
    console.log('SelectedActivity', selectedActivity)
  }, [selectedActivity])

  useEffect(() => {
    console.log('activityList:::', activityList)
  }, [activityList])

  useEffect(() => {
    account_id && getAccountActivities(account_id)
  }, [account_id])

  function setActivity(data) {
    console.log('datadatadata', data)
    setSelectedActivity((prevState) => ({
      ...prevState,
      activity_id: data.id,
      contact_first_name: data.deal.contact.first_name,
      contact_last_name: data.deal.contact.last_name,
      contact_position: data.deal.contact.position,
      contact_phone: data.deal.contact.contact_data.telefone[0].numero,
      contact_phone_type: data.deal.contact.contact_data.telefone[0].tipo,
      contact_email: data.deal.contact.contact_data.email,
      company_name: data.deal.company.name,
      step: data.deal.step.name,
      status: data.status,
      type: data.type,
      deal_name: data.deal.name,
      deal_description: data.description,
      deal_id: data.entity_id
    }))
    console.log('data setActivity: ', data)
  }

  return (
    <Flex flexDirection={'row'}>
      <Flex flexDirection={'column'} gap="20px" p="20px">
        <Flex flexDirection="column" bg="white" p="20px" borderRadius={'20px'} boxShadow={'lg'}>
          <Flex flexDirection="row" alignItems="center">
            <Text fontSize={'lg'} fontWeight={'800'} mb="0px">
              Tarefas do dia
            </Text>
            <Text fontWeight="400" ml="10px">{` 4/${activityList && activityList.length}`}</Text>
          </Flex>
          <Progress colorScheme="linkedin" size="sm" value={20} w="100%" hasStripe />
          <Select
            variant="auth"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            mt="10px"
            borderRadius="20px"
          >
            <option key="" value="">
              Ver todas
            </option>
            {taskTypes.map((task) => (
              <option key={task.value} value={task.value}>
                {task.name}
              </option>
            ))}
          </Select>
        </Flex>

        {activityList && activityList.map((item, index) => <ActivityCard itemData={{ item, index }} />)}
      </Flex>
      <ActiveActivityCard selectedActivity={selectedActivity} handleChange={patchActiveActivity} />
    </Flex>
  )
}
