import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import { MdEditCalendar, MdCheck, MdClose } from 'react-icons/md'
import { app } from 'api/feathers'
import { getUserAccountId } from 'hooks/authService'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import authHeader from 'hooks/authHeader'
const account_id = getUserAccountId()

const columnsData = [
  { Header: 'Campanha', accessor: 'QtdAnimais' },
  { Header: 'Pipeline', accessor: 'tags.Idade[0]' },
  { Header: 'Inicío', accessor: 'atendimento' },
  { Header: 'Fim', accessor: 'RotinaMensal' },
  { Header: 'Status', accessor: '' }
]

export default function CampaignsTable() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function buscarCampanhas() {
      try {
        const { data } = await app.service('campaigns').find({
          query: {
            account_id: account_id
          },
          headers: authHeader()
        })
        setTableData(data)
        console.log(data)
      } catch (error) {
        console.error('Erro ao buscar campanhas:', error)
      }
    }

    buscarCampanhas()
  }, [])

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Campanhas
        </Text>
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-campaign">
            <Flex flexDirection="rows" alignItems="center" gap={2}>
              <Icon as={MdEditCalendar} color={iconColor} w="26px" h="26px" />

              <Text px="5px" fontSize="sm">
                Criar
              </Text>
            </Flex>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/campaign/${row.original.id}`}>
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.subject}
                    </Text>
                    <Text>{row.original.description}</Text>
                  </Link>
                </Td>

                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.pipeline.name}
                  </Text>
                </Td>
                {/* <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex alignItems="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {row.original.pipeline.steps.map((step) => (
                        <Tag colorScheme="green" borderRadius="full" size="sm" mr="px" mb="4px">
                          {step}
                        </Tag>
                      ))}
                    </Text>
                  </Flex>
                </Td> */}
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.start_date}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.end_date}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.status === 'active' ? (
                      <Icon as={MdCheck} color="green" w="20px" h="20px" />
                    ) : (
                      <Icon as={MdClose} color="red" w="20px" h="20px" />
                    )}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
