import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  Switch,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Textarea,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderTrack,
  SliderThumb,
  Select,
  Stack,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import formatDate from 'hooks/formatDate'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import ApproachForm from '../../newAproach/components/newApproachForm'
import NewCampaignScript from './campaignScript'
import { getUserAccountId } from 'hooks/authService'
import IntervalInput from 'components/intervalInput'

export default function CampaignForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { campaignId: campaignIdFromParams } = useParams() || ''
  const [campaign_id, setCampaignId] = useState(parseInt(campaignIdFromParams, 10))
  const [pipelineList, setPipelineList] = useState(null)
  const [pipelineSteps, setPipelineSteps] = useState(null)
  // const [addActivity, setAddActivity] = useState(null)

  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    account_id: account_id,
    pipeline_id: null,
    priority: 5,
    pipeline_activities: {},
    status: 'active',
    intervals_msec: 15780096000,
    start_date: '',
    end_date: ''
  })

  function getToday() {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1 // Months are 0-indexed
    let day = today.getDate()

    // Add leading zeros if necessary
    if (month < 10) month = `0${month}`
    if (day < 10) day = `0${day}`

    return { today: `${year}-${month}-${day}`, years: `${year + 2}-${month}-${day}` } // Format for input type="date"
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      start_date: getToday().today,
      end_date: getToday().years
    }))
  }, [])

  const [interval, setInterval] = useState(15780096000.000004)

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      intervals_msec: parseInt(interval, 10)
    }))
  }, [interval])

  useEffect(() => {
    console.log('campaign formData: ', formData)
    console.log('pipelineSteps atualizado: ', pipelineSteps)
  }, [account_id, formData, pipelineSteps, campaignIdFromParams])

  const [sendAlert, setSendAlert] = useState(false)

  useEffect(() => {
    if (sendAlert === 'success') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  useEffect(() => {
    async function getCampaign() {
      try {
        const response = await axios.get(`/campaigns/${campaign_id}`, {
          headers: authHeader()
        })
        setFormData(response.data)
        console.log('Campanha: ', response.data)
      } catch (error) {
        console.log('Erro: ', error.response.data)
      }
    }

    getCampaign()
  }, [])

  useEffect(() => {
    async function getPipelines() {
      try {
        const response = await axios.get(`/pipelines?account_id=${account_id}`, {
          headers: authHeader()
        })
        setPipelineList(response.data.data)
      } catch (error) {
        console.log('Erro: ', error.response.data)
      }
    }

    if (account_id) {
      getPipelines()
    }
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSelect = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pipeline_id: parseInt(event.target.value, 10)
    }))

    pipelineList.map((item) => {
      console.log('item pipeline:', item.steps)
      if (item.id === parseInt(event.target.value, 10)) {
        setPipelineSteps(item.steps)
      }
    })
  }

  const handleSlideChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priority: value
    }))
  }

  const handleCheckboxCategoryChange = (event, groupName) => {
    const value = event.target.value
    setFormData((prevFormData) => {
      const newCategories = { ...prevFormData.tags }

      if (!Array.isArray(newCategories[groupName])) {
        newCategories[groupName] = []
      }

      if (event.target.checked) {
        newCategories[groupName] = [...newCategories[groupName], value]
      } else {
        newCategories[groupName] = newCategories[groupName].filter((item) => item !== value)
      }

      return {
        ...prevFormData,
        tags: newCategories
      }
    })
  }

  const [activities, setActivities] = useState({})

  const handleAddActivity = (stepName, activity) => {
    if (activity) {
      setFormData((prev) => ({
        ...prev,
        pipeline_activities: {
          ...prev.pipeline_activities,
          [stepName]: [...(prev.pipeline_activities[stepName] || []), { activity, note: '' }]
        }
      }))
    }
  }

  const handleRemoveActivity = (stepName, index) => {
    setFormData((prev) => {
      const updatedActivities = [...prev.pipeline_activities[stepName]]
      updatedActivities.splice(index, 1)
      return {
        ...prev,
        pipeline_activities: {
          ...prev.pipeline_activities,
          [stepName]: updatedActivities
        }
      }
    })
  }

  const handleNoteChange = (stepName, index, note) => {
    setFormData((prev) => {
      const updatedActivities = [...prev.pipeline_activities[stepName]]
      updatedActivities[index] = { ...updatedActivities[index], note }
      return {
        ...prev,
        pipeline_activities: {
          ...prev.pipeline_activities,
          [stepName]: updatedActivities
        }
      }
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (campaign_id) {
      try {
        const response = await axios.patch(`/campaigns/${campaign_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/campaigns', formData, {
          headers: authHeader()
        })
        console.log('Campanha cadastrada com sucesso!', response.data)
        setCampaignId(response.data.id)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  const checkboxGroups = {
    Atendimento: ['Preventivo', 'Campanha'],
    Especies: ['Canino', 'Felino'],
    Idade: ['Filhote 1 ano', 'Filhote 2 anos', 'Adulto 2 a 6 anos', 'Idoso 6+ anos'],
    'Status do Cliente': ['Ativo', 'Estagnado', 'Inativo']
  }

  const CheckboxGrid = () => {
    return (
      <SimpleGrid columns={2} gap={5}>
        {Object.entries(checkboxGroups).map(([groupName, values]) => (
          <Flex
            key={groupName}
            style={{ marginBottom: '16px' }}
            direction="column"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
          >
            <strong>{groupName}</strong>
            <Flex direction="column">
              {values.map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData.tags[groupName]?.includes(value)}
                  onChange={(e) => handleCheckboxCategoryChange(e, groupName)}
                  style={{ marginLeft: '8px' }}
                  fontSize="sm"
                  fontWeight="400"
                  color={textColor}
                >
                  {value}
                </Checkbox>
              ))}
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid rows={2} gap={5}>
        <Card>
          <FormControl>
            <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
              Nova Campanha
            </Text>
            <Flex direction="column">
              {/* <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Tema
              </FormLabel> */}
              <Input
                value={formData.subject}
                name="subject"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Nome"
                mb="24px"
                borderRadius="50px"
                fontWeight="500"
                size="md"
              />
            </Flex>
            <Flex direction="column">
              {/* <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Descrição
              </FormLabel> */}
              <Textarea
                value={formData.description}
                name="description"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Descrição da campanha"
                mb="24px"
                fontWeight="500"
                size="md"
                border="1px solid #E2E8F0"
                borderRadius="16px"
              />
            </Flex>
            <SimpleGrid columns={{ base: '1', md: '3' }} gap="0px 20px">
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Data de Início
                </FormLabel>
                <Input
                  value={formatDate(formData.start_date)}
                  name="start_date"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  type="date"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  borderRadius="50px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Data de Finalização
                </FormLabel>
                <Input
                  value={formatDate(formData.end_date)}
                  name="end_date"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  type="date"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  borderRadius="50px"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex flexDirection="column" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm">
                  Intervalo entre repetições
                </FormLabel>
                <IntervalInput onChange={setInterval} value={formData.intervals_msec} />
              </Flex>
              <Flex flexDirection="column" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm">
                  Funil
                </FormLabel>
                <Select
                  name="pipeline_id"
                  value={formData.pipeline_id}
                  onChange={handleSelect}
                  borderRadius="50px"
                  placeholder="Selecione"
                >
                  {pipelineList &&
                    pipelineList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </Flex>
              <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm" mb="8px">
                  Status da Campanha
                </FormLabel>
                <Flex alignItems="center">
                  <Switch
                    id="status"
                    checked={formData.status === 'active'} // Determina o estado inicial do switch
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: event.target.checked ? 'active' : 'inactive' // Inverte o status ao alternar
                      }))
                    }
                    defaultChecked
                    ml="10px"
                  />
                  <Text ml="10px">{formData.status === 'active' ? 'Ativo' : 'Inativo'}</Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm">
                  Prioridade da campanha
                </FormLabel>
                <Slider
                  name="priority"
                  focusThumbOnChange={false}
                  value={formData.priority}
                  onChange={handleSlideChange}
                  defaultValue={5}
                  min={1}
                  max={10}
                  mt="-8px"
                  mb="15px"
                >
                  <SliderMark value={2} fontSize="sm" mt="5px">
                    Baixa
                  </SliderMark>
                  <SliderMark value={8} fontSize="sm" mt="5px">
                    Alta
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb fontSize="sm" boxSize="32px" children={formData.priority} />
                </Slider>
              </Flex>
            </SimpleGrid>
          </FormControl>
        </Card>
        {pipelineSteps && (
          <Card>
            <Text display="flex" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
              Atividades do funil
            </Text>
            {pipelineSteps.map((step) => (
              <Box key={step.name}>
                <Flex mb="10px" flexDirection="row" justifyContent="space-between">
                  <Text fontWeight="600" color="gray.600" fontSize={'16px'} pl="10px">
                    {step.name}
                  </Text>

                  <Flex>
                    <InputGroup>
                      <Select
                        borderRadius="50px"
                        fontSize="14px"
                        size="md"
                        color="gray.700"
                        width="170px"
                        placeholder="Nova atividade"
                        onChange={(e) => handleAddActivity(step.name, e.target.value)}
                      >
                        <option value="Ligação">Ligação</option>
                        <option value="Mensagem">Mensagem</option>
                        <option value="Email">Email</option>
                        <option value="Agendar reunião">Agendar reunião</option>
                      </Select>
                      <InputRightElement>
                        <IconButton
                          aria-label="Adicionar atividade"
                          icon={<FaPlus />}
                          borderRadius="50px"
                          bg="gray.200"
                          size="xs"
                          onClick={() => handleAddActivity(step.name)}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                </Flex>

                {/* Renderizar a lista de atividades adicionadas */}
                <List spacing={3} pl="10px" mb="24px">
                  {formData.pipeline_activities[step.name]?.map((activityObj, index) => (
                    <ListItem
                      key={index}
                      fontSize="14px"
                      color="gray.600"
                      display="flex"
                      flexDirection="column"
                      mb="10px"
                    >
                      <Flex justifyContent="space-between">
                        <span>{activityObj.activity}</span>
                        <IconButton
                          aria-label="Remover atividade"
                          icon={<FaTimes />}
                          size="xs"
                          color="red.500"
                          onClick={() => handleRemoveActivity(step.name, index)}
                        />
                      </Flex>
                      <Textarea
                        mt="5px"
                        placeholder="Adicionar nota"
                        size="sm"
                        value={activityObj.note}
                        onChange={(e) => handleNoteChange(step.name, index, e.target.value)}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Card>
        )}

        {sendAlert === 'success' && (
          <Alert status="success">
            <AlertIcon />
            <AlertTitle>Sucesso!</AlertTitle>
            <AlertDescription>Dados enviados com sucesso!</AlertDescription>
          </Alert>
        )}

        {/* {campaign_id && <NewCampaignScript campaign_id={campaign_id} campaignName={formData.subject} />} */}
      </SimpleGrid>
      <Button
        type="submit"
        fontSize="sm"
        background="#49BFFF"
        color="white"
        fontWeight="800"
        w="100%"
        h="50"
        mt="20px"
        mb="24px"
        borderRadius="50px"
      >
        Salvar
      </Button>
    </form>
  )
}
