import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

import { MultiSelect } from 'primereact/multiselect'
import { SelectButton } from 'primereact/selectbutton'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Flex, Text, VStack } from '@chakra-ui/react'
import { cnaes, porteEmpresas, naturezasJuridicas, estadosRegioes } from 'contexts/dadosBrasileiros'
import { Slider } from 'primereact/slider'
import { Toast } from 'primereact/toast'

export default function ICPForm({ accountData }) {
  const [profile, setProfile] = useState({
    cnaes: '',
    companySize: '',
    capitalSocial: [1000, 1500000], // Valor padrão
    employeesNumber: '',
    location: '',
    region: '',
    legalNature: '',
    decisionMakers: '',
    challenges: '',
    purchaseCycle: '',
    budget: '',
    businessModel: '',
    successCompanySize: ''
  })

  const toast = useRef(null)
  const toastSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Dados atualizados!' })
  }
  const toastError = () => {
    toast.current.show({
      severity: 'danger',
      summary: 'Error',
      detail: 'Houve um erro ao atualizar os dados.!'
    })
  }

  async function getProfileData() {
    try {
      const response = await axios.get(`/accounts/${accountData.id}`, {
        headers: authHeader()
      })
      // console.log('setProfile(): ', response.data.profile)
      setProfile(response.data.profile)
    } catch (error) {
      console.log('Erro ao carregar perfil de cliente ideal. ', error)
    }
  }

  useEffect(() => {
    // console.log('accountData: ICP', accountData)

    if (accountData.id) {
      getProfileData()
    }
  }, [accountData])

  const handleChange = (e) => {
    const { name, value } = e.target
    setProfile({
      ...profile,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    accountData.profile = profile
    console.log('accountData atualizado: ', accountData)
    try {
      const response = await axios.patch(`/accounts/${accountData.id}`, accountData, {
        headers: authHeader()
      })
      console.log('Dados atualizados com sucesso!', response.data)
      toastSuccess()
      // setTimeout(() => {
      //   window.location.href = '/admin/painel'
      // }, 600)
    } catch (error) {
      toastError()
      console.error('Ocorreu um erro ao atualizar os dados:', error.response.data)
    }
  }

  return (
    <Flex bg="white" p={'40px'} boxShadow="lg" borderRadius="20px" flexDirection={'column'}>
      <Text fontSize="18px" fontWeight="800" mb="34px">
        Perfil de Cliente Ideal
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={2} align="stretch">
          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>Atuação / CNAE</label>
          <MultiSelect
            name="cnaes"
            value={profile.cnaes}
            onChange={handleChange}
            options={cnaes}
            optionLabel="name"
            display="chip"
            maxSelectedLabels={10}
            filter
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: {
                style: {
                  margin: '0 6px 6px 0',
                  padding: '5px 5px 5px 10px',
                  background: 'rgb(240, 245, 250)'
                }
              },
              wrapper: { style: { maxWidth: '600px', minHeight: '60vh' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>Porte da Empresa</label>
          <MultiSelect
            value={profile.companySize}
            name="companySize"
            onChange={handleChange}
            options={porteEmpresas}
            optionLabel="name"
            display="chip"
            maxSelectedLabels={0}
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: {
                style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px', background: 'rgb(240, 245, 250)' }
              },
              wrapper: { style: { maxWidth: '600px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
            Número de colaboradores
          </label>
          <Dropdown
            value={profile.employeesNumber}
            onChange={handleChange}
            options={[
              { value: '0-9', name: '0-9 funcionários' },
              { value: '10-49', name: '10-49 funcionários' },
              { value: '50-249', name: '50-249 funcionários' },
              { value: '250-499', name: '250-499 funcionários' },
              { value: '+500', name: '+500 funcionários' }
            ]}
            name="employeesNumber"
            optionLabel="name"
            display="chip"
            maxSelectedLabels={0}
            // className={'mt-4'}
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0px 0 10px 0'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: {
                style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' }
              },
              wrapper: { style: { maxWidth: '600px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
            Capital Social
            <span
              style={{ fontSize: '14px', fontWeight: '500', marginTop: '30px', marginLeft: '20px' }}
            >{` R$ ${profile.capitalSocial && profile.capitalSocial[0] && profile.capitalSocial[0].toLocaleString('pt-BR')} a R$ ${profile.capitalSocial && profile.capitalSocial[1] && profile.capitalSocial[1].toLocaleString('pt-BR')}`}</span>
          </label>
          <Slider
            name="capitalSocial"
            min={0}
            max={20000000}
            step={10000}
            value={profile.capitalSocial}
            onChange={(e) => setProfile({ ...profile, capitalSocial: e.value })}
            range
            style={{ marginBottom: '20px' }}
          />
          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>Regiões</label>
          <MultiSelect
            type="text"
            name="region"
            value={profile.region}
            onChange={handleChange}
            options={estadosRegioes}
            display="chip"
            maxSelectedLabels={0}
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionLabel="label"
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: {
                style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px', background: 'rgb(240, 245, 250)' }
              },
              wrapper: { style: { maxWidth: '600px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
            Natureza Jurídica Predominante
          </label>
          <MultiSelect
            name="legalNature"
            value={profile.legalNature}
            onChange={handleChange}
            options={naturezasJuridicas}
            placeholder="Selecione a natureza jurídica"
            optionLabel="name"
            display="chip"
            maxSelectedLabels={0}
            filter
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: {
                style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px', background: 'rgb(240, 245, 250)' }
              },
              wrapper: { style: { maxWidth: '600px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
            Faixa etária dos decisores
          </label>
          <MultiSelect
            name="decisionMakers"
            value={profile.decisionMakers}
            onChange={handleChange}
            options={[
              { value: '20-30', name: '20 a 30 anos' },
              { value: '30-40', name: '30 a 40 anos' },
              { value: '40-50', name: '40 a 50 anos' },
              { value: '50-60', name: '50 a 60 anos' },
              { value: '60-70', name: '60 a 70 anos' },
              { value: '70-80', name: '70 a 80 anos' },
              { value: '80-90', name: '80 a 90 anos' }
            ]}
            optionLabel="name"
            display="chip"
            maxSelectedLabels={0}
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: {
                style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px', background: 'rgb(240, 245, 250)' }
              },
              wrapper: { style: { maxWidth: '600px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
            Principais problemas que a empresa enfrenta
          </label>
          <InputTextarea
            name="challenges"
            value={profile.challenges}
            onChange={handleChange}
            placeholder="Escreva..."
            style={{
              width: '100%',
              padding: '0 10px',
              margin: '0 0 10px 0',
              fontSize: '14px'
            }}
            pt={{
              label: { style: { whiteSpace: 'normal' } },
              token: { style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' } },
              wrapper: { style: { maxWidth: '600px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>Ciclo de vendas</label>
          <SelectButton
            name="purchaseCycle"
            value={profile.purchaseCycle}
            onChange={handleChange}
            options={[
              { value: 'Horas', name: 'Horas' },
              { value: 'Dias', name: 'Dias' },
              { value: 'Semanas', name: 'Semanas' },
              { value: 'Meses', name: 'Meses' },
              { value: 'Semestres', name: 'Semestres' },
              { value: 'Anos', name: 'Anos' }
            ]}
            optionLabel="name"
            multiple
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              button: { style: { fontSize: '12px', padding: '5px 10px' } }
            }}
          />

          <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
            Modelo de negócios
          </label>
          <SelectButton
            name="businessModel"
            value={profile.businessModel}
            onChange={handleChange}
            options={[
              { value: 'B2B', name: 'B2B' },
              { value: 'B2C', name: 'B2C' },
              { value: 'B2B2C', name: 'B2B2C' }
            ]}
            optionLabel="name"
            multiple
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0'
            }}
            pt={{
              button: { style: { fontSize: '12px', padding: '5px 10px' } }
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              label="Salvar"
              icon="pi pi-check"
              rounded
              style={{
                background: '#49BFFF',
                width: '150px',
                margin: '30px 0 0 0',
                padding: '10px 20px',
                color: 'white'
              }}
            />
            <Toast ref={toast} />
          </div>
        </VStack>
      </form>
    </Flex>
  )
}
