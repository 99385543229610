// Chakra imports
import { Box, Flex, Text, Icon, useColorModeValue, Checkbox, Tag } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import Menu from 'components/menu/MainMenu'
import IconBox from 'components/icons/IconBox'

// Assets
import {
  MdDone,
  MdDragIndicator,
  MdCallMade,
  MdCallReceived,
  MdSlowMotionVideo,
  MdEmail,
  MdChat,
  MdAnnouncement
} from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import axios from 'api/axios'
import { SimpleGrid } from '@chakra-ui/react'
import ExpandableText from './ExpandableText'
import ActivityItem from './ActivityItem'
import LastApproachs from './LastApproachs'
import authHeader from 'hooks/authHeader'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'

export default function Conversion(props) {
  const { accountId, ...rest } = props

  const [feedData, setFeedData] = useState()

  const lastApproachs = [
    {
      owner_id: 2,
      animal_id: 3,
      cadence_id: 1,
      status: 'readed',
      media: 'whatsApp',
      message:
        'Olá Rolf, tudo bem? Foi ótimo ter recebido o Iron aqui na clínica. Estamos sempre à postos para recebê-los!',
      animal: {
        name: 'Iron',
        species: 'Canino',
        Breed: 'Hotweiller',
        birthdate: '2016-13-12'
      },
      cadence: {
        name: 'Agradecimento'
      },
      client: {
        first_name: 'Rolf',
        whatsapp: '551151283600'
      },
      created_at: '2024-07-26T08:52:22'
    }
  ]

  // useEffect(() => {
  //   async function fetchData() {
  //     const request = await axios.get(
  //       `https://otten.bitrix24.com.br/rest/1/7d0cb9ltfwp34kvp/crm.activity.list?order[ID]=DESC`
  //     )
  //     setFeedData(request.data.result)
  //     console.log('FeedData', request.data.result)
  //     return request
  //   }
  //   fetchData()
  // }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(`/approachs?account_id=${accountId}`, {
          headers: authHeader()
        })
        setFeedData(data.data)
        console.log('Últimas Abordagens', data.data)
        return data
      } catch (error) {
        console.log('Erro:', error.stack)
      }
    }
    fetchData()
  }, [])

  const [aproachLists, setAproachList] = useState()

  async function getAccountActivities(account_id) {
    try {
      const {
        data: { data }
      } = await axios.get(`/activities?account_id=${account_id}`, { headers: authHeader() })
      console.log('approachList response', data.data)
      if (data) {
        const activitiesFiltered = filterActivitiesByTypeAndDate(data)
        setAproachList(activitiesFiltered)
      }
    } catch (err) {
      console.log('Erro ao carregar as atividades', err)
    }
  }

  function filterActivitiesByTypeAndDate(activities) {
    const now = new Date()

    const filteredActivities = activities.filter((activity) => {
      const typesAllowed = ['call', 'message', 'email']
      const updatedAt = new Date(activity.updated_at)

      return typesAllowed.includes(activity.type) && updatedAt < now
    })

    const sortedActivities = filteredActivities.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    )

    return sortedActivities
  }

  useEffect(() => {
    accountId && getAccountActivities(1)
  }, [])
  useEffect(() => {
    console.log('aproachLists', aproachLists)
  }, [aproachLists])

  const formatDate = (date) => {
    const [datePart, timePart] = date.split('T')
    const [hourPart, GTM] = timePart.split('+')

    return (
      <span>
        <span style={{ fontSize: 'smaller', fontWeight: '500' }}>{datePart}</span>
        <br />
        <span>{hourPart}</span>
      </span>
    )
  }

  const ActivityType = ({ value }) => {
    switch (value) {
      case 'email':
        return <Icon as={MdEmail} width="20px" height="20px" mr={3} color="inherit" />
      case 'message':
        return <Icon as={MdChat} width="20px" height="20px" mr={3} color="inherit" />
      case 'task':
        return <Icon as={MdAnnouncement} width="20px" height="20px" mr={3} color="inherit" />
      case 'call':
        return <Icon as={MdAnnouncement} width="20px" height="20px" mr={3} color="inherit" />

      default:
        // return null;
        return value
    }
  }
  const DirectionIcon = ({ value }) => {
    switch (value) {
      case '0':
        return <Icon as={MdSlowMotionVideo} width="20px" height="20px" mr={3} color="inherit" />
      case '1':
        return <Icon as={MdCallReceived} width="20px" height="20px" mr={3} color="inherit" />
      case '2':
        return <Icon as={MdCallMade} width="20px" height="20px" mr={3} color="inherit" />
      default:
        return null
    }
  }

  const UserOwner = ({ value }) => {
    switch (value) {
      case '1':
        return (
          <Text color={textColor} fontSize="xs" fontWeight="700" alignItems="center" pr={2}>
            Rolf
          </Text>
        )
      case '11':
        return (
          <Text color={textColor} fontSize="xs" fontWeight="700" alignItems="center" pr={2}>
            Carla C.
          </Text>
        )
      default:
        return null
    }
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const boxBg = useColorModeValue('secondaryGray.300', 'navy.700')
  const brandColor = useColorModeValue('brand.500', 'brand.400')

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex alignItems="center" w="100%" mb="30px">
        <Icon as={MdDone} width="20px" height="20px" mr={3} color="inherit" />

        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Últimas abordagens
        </Text>
        <Menu ms="auto" />
      </Flex>
      <Box>
        {aproachLists &&
          aproachLists.map((approach) => (
            <Flex mb="8px" flexDirection={'column'} alignItems={'flex-start'}>
              <Flex justifyContent={'space-between'}>
                <Text mr="5px"> {ActivityType(approach.type)}</Text>
                <Text mr="5px">{approach.deal.company.name}</Text>
                <Tag mr="5px" w={'fit-content'} borderRadius={'20px'}>
                  {approach.deal.step.name}
                </Tag>
              </Flex>
              <Text fontSize={'0.7rem'} mr="5px">
                {formatDateDDMMYYYY(approach.updated_at)}
              </Text>
            </Flex>
          ))}
      </Box>
      {/* <Box px="11px" overflowY="scroll" maxHeight="455px">
        {feedData &&
          feedData.map((item) => {
            return (
              <>
                <ActivityItem
                  lastUpdated={item.LAST_UPDATED}
                  dealOwner={item.RESPONSIBLE_ID}
                  entityId={item.OWNER_ID}
                  entityType={item.OWNER_TYPE_ID}
                  providerId={item.PROVIDER_ID}
                  direction={item.DIRECTION}
                  description={item.DESCRIPTION}
                />
                <Flex textAlign="left" mb={4}>
                  <SimpleGrid>
                    <Text color={textColor} fontSize="sm" fontWeight="700" alignItems="center" pr={2}>
                      <span style={{ textWrap: 'nowrap' }}>{formatDate(item.LAST_UPDATED)}</span>
                    </Text>
                    <UserOwner value={item.RESPONSIBLE_ID} mt="-15px" />
                  </SimpleGrid>
                  <SimpleGrid>
                    <SimpleGrid columns={3} display="flex" justifyContent="flex-start">
                      <ActivityType value={item.PROVIDER_ID} />
                      <DirectionIcon value={item.DIRECTION} />
                      <Text>{`${item.OWNER_ID} ${item.OWNER_TYPE_ID} `}</Text>
                    </SimpleGrid>
                    <ExpandableText text={item.DESCRIPTION} />
                  </SimpleGrid>
                </Flex>
              </>
            )
          })}
      </Box> */}
    </Card>
  )
}
