import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  useDisclosure
} from '@chakra-ui/react'
import DealForm from '../../formDeal/components/dealForm'

export default function NewStepDeal({ onClose, isOpen, stepId, pipelineId, stepName, onUpdate }) {
  const focusInicial = React.useRef()

  return (
    <Modal isOpen={isOpen} initialFocusRef={focusInicial} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="800px" borderRadius={'20px'}>
        <ModalCloseButton />
        <ModalBody bg={'whiteAlpha.200'} pt="30px" borderRadius={'20px'}>
          <DealForm newDealStep={stepName} pipelineId={pipelineId} stepId={stepId} onUpdate={onUpdate} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
