import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MdStar } from 'react-icons/md'
import { Spinner } from '@chakra-ui/react'
import { GoogleGenerativeAI } from '@google/generative-ai'
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  SimpleGrid,
  Stack,
  Text,
  InputRightElement,
  useColorModeValue
} from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import ReactMarkdown from 'react-markdown'
import CopyToClipboardButton from 'components/copyToClipboard'

export default function TotalSpent(props) {
  const { ...rest } = props
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  const [formData, setFormData] = useState({
    contents: [
      {
        parts: [
          {
            text: ''
          }
        ]
      }
    ]
  })

  const [geminiResponse, setGeminiResponse] = useState('')
  const [loadingStatus, setLoadingStatus] = useState(false)

  const handleChange = (event) => {
    const { value } = event.target
    const contentIndex = 0 // Índice do item 'contents'
    const partIndex = 0 // Índice do item 'parts'

    setFormData((prevFormData) => {
      // Cria uma cópia profunda de formData
      const newFormData = JSON.parse(JSON.stringify(prevFormData))

      // Modifica o valor do text
      newFormData.contents[contentIndex].parts[partIndex].text = value

      console.log(`Requisição: ${JSON.stringify(newFormData)}`)

      return newFormData
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoadingStatus(true)

    try {
      const response = await axios.post(
        `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyCbGYGn_zCFLHNxsEVmmcbU9Q6tQVvGICE`,
        formData
      )
      setGeminiResponse(response.data.candidates[0].content.parts[0].text)

      console.log('Sucesso! Retorno:', JSON.stringify(response.data))
      console.log(`geminiResponse: ${JSON.stringify(geminiResponse)}`)
      setLoadingStatus(false)
    } catch (error) {
      console.error('Deu erro:', error.response)
    }
  }

  // Access your API key (see "Set up your API key" above)
  const genAI = new GoogleGenerativeAI('AIzaSyCbGYGn_zCFLHNxsEVmmcbU9Q6tQVvGICE')

  // async function run(event, nome) {
  //   event.preventDefault();

  //   // For text-only input, use the gemini-pro model
  //   const model = genAI.getGenerativeModel({ model: 'gemini-pro' });

  //   const chat = model.startChat({
  //     // history: [
  //     //   {
  //     //     role: 'user',
  //     //     parts: 'Hello, I have 2 dogs at my house.',
  //     //   },
  //     //   {
  //     //     role: 'model',
  //     //     parts: 'Great to meet you. What would you like to know?',
  //     //   },
  //     // ],
  //     generationConfig: {
  //       maxOutputTokens: 100,
  //     },
  //   });

  //   const result = await chat.sendMessage(nome);
  //   const response = await result.response;
  //   // const text = response.text();
  //   console.log(response);
  // }

  // const [content] = geminiResponse.candidates[0];

  return (
    <Card justifyContent="left" align="left" direction="column" w="100%" mb="0px" {...rest}>
      <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%" mb="24px">
        Otten AI
      </Text>
      <Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }}>
        <Box minH="60px" minW="100%" mt="auto">
          <SimpleGrid rows={2}>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Stack>
                  <InputGroup>
                    <Input
                      value={formData.nome}
                      name="nome"
                      onChange={handleChange}
                      isRequired={false}
                      variant="auth"
                      fontSize="sm"
                      placeholder="Digite o que quiser..."
                      fontWeight="500"
                      size="md"
                      borderRadius="100px"
                      mb="20px"
                    />
                    <InputRightElement>
                      <IconButton
                        colorScheme="gray"
                        aria-label="Call Segun"
                        size="sm"
                        type="submit"
                        icon={
                          loadingStatus ? (
                            <Spinner color="blue.500" />
                          ) : (
                            <Icon as={MdStar} width="20px" height="20px" color="inherit" />
                          )
                        }
                      />
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </FormControl>
            </form>

            {/* <Text>{geminiResponse}</Text> */}
            {geminiResponse && <CopyToClipboardButton text={geminiResponse} mb="20px" />}
            <Box
              style={{
                padding: '20px',
                textAlign: 'justify',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <ReactMarkdown>{geminiResponse}</ReactMarkdown>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </Card>
  )
}

/* 
  
*/
