import React from 'react';
import { MdContentCopy } from 'react-icons/md';

function CopyToClipboardButton({ text }) {
  function copyToClipboard() {
    // Cria um elemento de texto oculto para armazenar o texto a ser copiado
    var textarea = document.createElement('textarea');
    textarea.value = text;

    // Adiciona o elemento de texto ao corpo do documento
    document.body.appendChild(textarea);

    // Seleciona o texto dentro do elemento de texto
    textarea.select();

    try {
      // Tenta copiar o texto selecionado para a área de transferência
      var successful = document.execCommand('copy');
      var msg = successful
        ? 'Texto copiado com sucesso!'
        : 'Erro ao copiar texto';
      console.log(msg);
    } catch (err) {
      console.error('Erro ao copiar texto:', err);
    }

    // Remove o elemento de texto do corpo do documento
    document.body.removeChild(textarea);
  }

  return (
    <button onClick={copyToClipboard}>
      <MdContentCopy />
    </button>
  );
}

export default CopyToClipboardButton;
