import React, { useState, useEffect } from 'react'
import {
  Flex,
  HStack,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  Tag,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import Steps from './pipelineStep'
import PipelineForm from '../../formPipeline/components/formPipeline'
import { FaPlus } from 'react-icons/fa'
import NewPipelineStepForm from './newPipelineStep'
import { PipelineProvider } from 'contexts/pipeline-context'

export default function Pipeline(props) {
  const { accountPipelines, onUpdate } = props // lista para formar o menu e definir o pipeline padrão [order: 0]

  const location = useLocation()
  const pipelineId = parseInt(location.pathname.replace('/admin/pipeline/', ''), 10)

  // Preciso definir o pipelineId:
  // Situações: Carregou a página do funil => carrega steps do accountPipelines[0]
  // UseParams? então carrega os steps do useParams.

  const textColor = useColorModeValue('navy.700', 'white')

  const [pipelineList, setPipelineList] = useState(accountPipelines)
  const [orderedSteps, setOrderedSteps] = useState()

  useEffect(() => {
    console.log('### accountPipelines: ', accountPipelines)
    console.log('pipelineId: ', pipelineId)
    console.log('accountPipelines[0].id: ', accountPipelines[0] && accountPipelines[0].id)
    console.log('pipelineList: ', pipelineList)
    console.log('orderedSteps: ', orderedSteps)
  }, [pipelineList, accountPipelines, orderedSteps])

  useEffect(() => {
    if (!isNaN(pipelineId)) {
      getPipelineSteps(pipelineId)
    } else if (accountPipelines.length > 0) {
      getPipelineSteps(accountPipelines[0]?.id)
    }
  }, [pipelineId, accountPipelines])

  async function getPipelineSteps(id) {
    try {
      const response = await axios.get(`/steps?pipeline_id=${id}`, { headers: authHeader() })
      console.log('This pipeline Steps', response.data.data)

      if (response.status === 200 && response.data && response.data.data) {
        const orderedPipelineSteps = getOrderedSteps(response.data.data)
        setOrderedSteps(orderedPipelineSteps)
      } else {
        console.error(
          'Erro ao carregar as etapas do pipeline: Dados inválidos ou ausentes na resposta da API'
        )
      }
    } catch (error) {
      console.log('Erro ao carregar as etapas do pipeline', error)
    }
  }

  function getOrderedSteps(pipelines) {
    if (Array.isArray(pipelines) && pipelines.length === 1) {
      return pipelines
    } else if (Array.isArray(pipelines)) {
      return pipelines.sort((a, b) => a.order - b.order)
    } else {
      return [] // Ou lance um erro, dependendo da sua lógica
    }
  }

  return (
    <PipelineProvider>
      <Flex flexDirection={'column'} gap="20px">
        <Card borderRadius="50px" p="10px">
          <Flex flexDirection={'row'}>
            {accountPipelines.length > 0 &&
              accountPipelines.map((pipeline) => (
                <Link href={`/admin/pipeline/${pipeline.id}`} key={pipeline.id} cursor={'pointer'}>
                  <Tag colorScheme="blue" p="10px 20px" borderRadius={'50px'} mr="10px">
                    {pipeline.name}
                  </Tag>
                </Link>
              ))}
            <Link href="#">
              <Tag colorScheme="blue" p="10px 20px" borderRadius={'50px'}>
                <FaPlus />
              </Tag>
            </Link>
          </Flex>
        </Card>

        {
          accountPipelines.length == 0 && (
            <PipelineForm />
          ) /* Caso não tenha nenhum pipeline, abre o form de criação de pipeline*/
        }

        {accountPipelines.length > 0 && (
          <Flex flexDirection="row" gap="10px">
            {orderedSteps && orderedSteps.length > 0
              ? orderedSteps.map((step, index) => (
                  <Steps
                    name={step.name}
                    objective={step.objective}
                    pipelineId={pipelineId ? pipelineId : accountPipelines[0]?.id}
                    stepId={step.id}
                    index={index}
                    onUpdate={onUpdate}
                    key={step.id}
                  />
                ))
              : // Renderiza os steps do accountPipelines[0] se orderedSteps não estiver disponível
                accountPipelines[0].steps &&
                accountPipelines[0].steps.map((step, index) => (
                  <Steps
                    name={step.name}
                    objective={step.objective}
                    pipelineId={accountPipelines[0].id}
                    stepId={step.id}
                    index={index}
                    onUpdate={onUpdate}
                    key={step.id}
                  />
                ))}
            <NewPipelineStepForm
              pipelineId={pipelineId ? pipelineId : accountPipelines[0]?.id}
              stepsLength={orderedSteps ? orderedSteps.length : accountPipelines[0].steps.length}
              onUpdate={getPipelineSteps}
              setOrderedSteps={setOrderedSteps}
              orderedSteps={orderedSteps}
            />
          </Flex>
        )}

        {/* <PipelineForm /> */}
      </Flex>
    </PipelineProvider>
  )
}
