// Chakra imports
import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea
} from '@chakra-ui/react'

// Custom components
import React, { useState, useEffect } from 'react'
import IconBox from 'components/icons/IconBox'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
import { logout, getUserAuthentication, getUserAuthenticationRole } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import axios from 'api/axios'
import Action from './components/action'

export default function SellerActionDash() {
  const textColor = useColorModeValue('navy.700', 'white')

  const userData = getUserAuthentication()

  let userRole, main_account_id

  if (userData) {
    userRole = userData?.user?.role
    main_account_id = userData?.user?.user_data?.main_account_id
  }

  const [accountData, setAccountData] = useState({})

  useEffect(() => {
    async function getUserAccount() {
      try {
        const response = await axios.get(`/accounts/${main_account_id}`, { headers: authHeader() })
        setAccountData(response.data)
      } catch (error) {
        console.error('Crie uma nova conta.', error)
      }
    }
    getUserAccount()
  }, [])

  useEffect(() => {
    console.log('Conta autenticada:', accountData)
  }, [accountData])

  return (
    <Box pt={{ base: '50px', md: '50px', xl: '50px' }}>
      <SimpleGrid rows="2">
        <Flex flexDirection={'row'}>
          <Action />
        </Flex>
      </SimpleGrid>
    </Box>
  )
}
