import React, { useEffect, useState } from 'react'

import { Icon } from '@chakra-ui/react'
import {
  MdBarChart,
  MdPerson,
  MdGroup,
  MdHome,
  MdLock,
  MdCreditCard,
  MdVideocam,
  MdTrackChanges,
  MdAssistant,
  MdChat,
  MdPersonAdd,
  MdPlaylistPlay,
  MdPets,
  MdContacts,
  MdBusiness,
  MdNotInterested,
  MdSettings,
  MdLogout,
  MdViewWeek,
  MdWebStories,
  MdPhone,
  MdFilterListAlt,
  MdCached,
  MdWhatshot
} from 'react-icons/md'

// Public Imports
import Sobre from 'views/public/sobre'
import ImportComponent from 'views/admin/importClients/components/importClientsCSV'
import MainDashboard from 'views/public/default'
import BI from 'views/admin/default'
import MeetingRoom from 'views/admin/meetingRoom'

// Admin Imports
import OttenAI from 'views/admin/ottenAi'
import formConsulta from 'views/admin/formConsulta'
import User from 'views/admin/formUser'
import Clients from 'views/admin/clients'
import Contacts from 'views/admin/contacts'
import NewClients from 'views/admin/formClient'
import NewAccount from 'views/admin/formAccount'
import Usuarios from 'views/admin/formProjetoLei'
import Ligacoes from 'views/admin/calls'
import Logout from 'views/admin/logout'
import CalendarCareTable from 'views/admin/calendarCare'
import Campaigns from 'views/admin/campaignTable'
import FormCampaign from 'views/admin/formCampaing'
import FormContact from 'views/admin/formContacts'
import FormDeal from 'views/admin/formDeal'
import FormScripts from 'views/admin/formScripts'
import FormAccount from 'views/admin/formAccount'
import NewApproachForm from 'views/admin/newAproach'
import Approach from 'views/admin/formApproach'
import AccountsManagement from 'views/admin/accountsManagement'
import Queues from 'views/admin/queues'
import SellerDash from 'views/admin/sellerDash'
import CRMDash from 'views/admin/crmDash'
import ManagerDash from 'views/admin/managerDash'
import ActionDash from 'views/admin/sellerActionDash'

// Auth Imports
import SignInCentered from 'views/auth/signIn'
import SignUp from 'views/auth/signUp'

import { getUserAuthentication } from './hooks/authService'
import { TriangleUpIcon } from '@chakra-ui/icons'
import FakeComponent from 'views/admin/fakeComponent'
import CampaignsTable from 'views/admin/calendarCare/components/calendarCareTable'
import CadenceFormComponent from 'views/admin/formCadence'

const userData = getUserAuthentication()

let role, userId, accountId

if (userData && userData.user) {
  ;({
    user: {
      role,
      id: userId,
      user_data: { main_account_id: accountId }
    }
  } = userData)
}

const routes = [
  // {
  //   name: 'Assistidos',
  //   layout: '/auth',
  //   path: '/preciso-de-ajuda',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: CadastroAssistidos,
  //   secondary: true,
  //   show: false
  // }
]

if (!userId) {
  routes.push(
    {
      name: 'Otten Sales',
      layout: '/public',
      path: '/home',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      component: MainDashboard,
      show: TriangleUpIcon
    },
    {
      name: 'Criar conta',
      layout: '/auth',
      path: '/sign-up',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      component: SignUp,
      show: true
    }
  )
}

if (role && !accountId) {
  routes.push({
    name: 'Nova Conta',
    layout: '/admin',
    path: '/new-account',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: NewAccount,
    show: true
  })
}

if (role) {
  routes.push(
    {
      name: 'início',
      layout: '/admin',
      path: '/painel',
      icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
      component: BI,
      show: true
    },
    // {
    //   name: 'Dash Pipelines',
    //   layout: '/admin',
    //   path: '/manager-dash',
    //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    //   component: ManagerDash,
    //   show: true
    // },
    // {
    //   name: 'CRM',
    //   layout: '/admin',
    //   path: '/crm',
    //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    //   component: CRMDash,
    //   show: true
    // },
    {
      name: 'Funil',
      layout: '/admin',
      path: '/pipeline',
      icon: <Icon as={MdViewWeek} width="20px" height="20px" color="inherit" />,
      component: SellerDash,
      show: true
    },
    {
      name: 'Funil',
      layout: '/admin',
      path: '/pipeline/:id',
      icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
      component: SellerDash,
      show: false
    },
    {
      name: 'Sala de vendas',
      layout: '/admin',
      path: '/ActionDash',
      icon: <Icon as={MdWhatshot} width="20px" height="20px" color="inherit" />,
      component: ActionDash,
      show: true
    },
    {
      name: 'Empresas',
      layout: '/admin',
      path: '/companies',
      icon: <Icon as={MdBusiness} width="20px" height="20px" color="inherit" />,
      component: Clients,
      show: true
    },
    {
      name: 'Nova Empresa',
      layout: '/admin',
      path: '/new-company',
      icon: <Icon as={MdPersonAdd} width="20px" height="20px" color="inherit" />,
      component: NewClients,
      show: false
    },
    {
      name: 'Editar Empresa',
      layout: '/admin',
      path: '/company/:company_id',
      icon: <Icon as={MdPersonAdd} width="20px" height="20px" color="inherit" />,
      component: NewClients,
      show: false
    },
    {
      name: 'Importar Empresa',
      layout: '/admin',
      path: '/import-companies',
      icon: <Icon as={MdPersonAdd} width="20px" height="20px" color="inherit" />,
      component: ImportComponent,
      show: false
    },
    {
      name: 'Contatos',
      layout: '/admin',
      path: '/contacts',
      icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
      component: Contacts,
      show: true
    },
    {
      name: 'Novo Contato',
      layout: '/admin',
      path: '/new-contact',
      icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
      component: FormContact,
      show: false
    },
    {
      name: 'Editar Contato',
      layout: '/admin',
      path: '/contact/:contact_id',
      icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
      component: FormContact,
      show: false
    },
    {
      name: 'Novo Negócio',
      layout: '/admin',
      path: '/new-deal',
      icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
      component: FormDeal,
      show: false
    },
    {
      name: 'Editar Negócio',
      layout: '/admin',
      path: '/deal/:deal_id',
      icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
      component: FormDeal,
      show: false
    },
    {
      name: 'Campanhas',
      layout: '/admin',
      path: '/careTable',
      icon: <Icon as={MdTrackChanges} width="20px" height="20px" color="inherit" />,
      component: Campaigns,
      show: true
    },
    {
      name: 'Campanha',
      layout: '/admin',
      path: '/new-campaign',
      icon: <Icon as={MdTrackChanges} width="20px" height="20px" color="inherit" />,
      component: FormCampaign,
      show: false
    },
    {
      name: 'Campanha',
      layout: '/admin',
      path: '/campaign/:campaignId',
      icon: <Icon as={MdTrackChanges} width="20px" height="20px" color="inherit" />,
      component: FormCampaign,
      show: false
    },
    {
      name: 'Cadências',
      layout: '/admin',
      path: '/new-cadence',
      icon: <Icon as={MdWebStories} width="20px" height="20px" color="inherit" />,
      component: CadenceFormComponent,
      show: true
    },
    {
      name: 'Cadências',
      layout: '/admin',
      path: '/cadence/:cadence_id',
      icon: <Icon as={MdTrackChanges} width="20px" height="20px" color="inherit" />,
      component: CadenceFormComponent,
      show: false
    },
    {
      name: 'Ligações',
      layout: '/admin',
      path: '/calls',
      icon: <Icon as={MdPhone} width="20px" height="20px" color="inherit" />,
      component: Ligacoes,
      show: true
    },
    {
      name: 'Listas de Empresas',
      layout: '/admin',
      path: '/novaConsulta',
      icon: <Icon as={MdFilterListAlt} width="20px" height="20px" color="inherit" />,
      component: formConsulta,
      show: true
    },
    {
      name: 'Não Qualificadas',
      layout: '/admin',
      path: '/no-fit',
      icon: <Icon as={MdNotInterested} width="20px" height="20px" color="inherit" />,
      component: FakeComponent,
      show: true
    },
    {
      name: 'Scripts',
      layout: '/admin',
      path: '/script/:script_id',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      component: FormScripts,
      show: true
    },
    {
      name: 'Novo Script',
      layout: '/admin',
      path: '/new-script',
      icon: <Icon as={MdPlaylistPlay} width="20px" height="20px" color="inherit" />,
      component: FormScripts,
      show: false
    },
    {
      name: 'Abordagens',
      layout: '/admin',
      path: '/approachs',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      component: Queues,
      show: true
    },
    {
      name: 'Nova Abordagem',
      layout: '/admin',
      path: '/approach',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      component: Approach,
      show: true
    },
    {
      name: 'Disparo em massa',
      layout: '/admin',
      path: '/new-approachs',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      component: NewApproachForm,
      show: true
    },

    {
      name: 'Minha clínica',
      layout: '/admin',
      path: '/clinic-profile',
      icon: <Icon as={MdPets} width="20px" height="20px" color="inherit" />,
      component: FormAccount,
      show: false
    },

    {
      name: 'Financeiro',
      layout: '/admin',
      path: '/payments',
      icon: <Icon as={MdCreditCard} width="20px" height="20px" color="inherit" />,
      component: FakeComponent,
      show: false
    },
    {
      name: 'Teleatendimento',
      layout: '/admin',
      path: '/tele-medicine',
      icon: <Icon as={MdVideocam} width="20px" height="20px" color="inherit" />,
      component: MeetingRoom,
      show: true
    },
    {
      name: 'Meus dados',
      layout: '/admin',
      path: '/user/',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      component: User,
      show: false
    },
    {
      name: 'Configurações',
      layout: '/admin',
      path: '/settings/',
      icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
      component: User,
      show: false
    },
    {
      name: 'Sair',
      layout: '/public',
      path: '/logout',
      icon: <Icon as={MdLogout} width="20px" height="20px" color="inherit" />,
      component: Logout,
      show: true
    }
  )
}

if (role === 'super-admin') {
  routes.push(
    {
      name: 'Assistência AI',
      layout: '/admin',
      icon: <Icon as={MdAssistant} width="20px" height="20px" color="inherit" />,
      path: '/chat',
      component: OttenAI,
      show: true
    },
    {
      name: 'Usuários',
      layout: '/admin',
      path: '/users',
      icon: <Icon as={MdGroup} width="20px" height="20px" color="inherit" />,
      component: Usuarios,
      show: true
    },
    {
      name: 'Gestão de Contas',
      layout: '/admin',
      path: '/account-management',
      icon: <Icon as={MdGroup} width="20px" height="20px" color="inherit" />,
      component: AccountsManagement,
      show: true
    },
    {
      name: 'Nova Conta',
      layout: '/admin',
      path: '/new-account',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      component: NewAccount,
      show: true
    }
  )

  // if (userRole === 'volunteer' || 'super-admin') {
  //   routes.push({
  //     name: 'Sala de Atendimento',
  //     layout: '/public',
  //     path: '/safe-room',
  //     icon: <Icon as={MdVideocam} width="20px" height="20px" color="inherit" />,
  //     component: MeetingRoom,
  //     show: true
  //   })
  // }
}

export default routes
