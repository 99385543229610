import React from 'react'
import Card from 'components/card/Card'
import { MdPersonAdd } from 'react-icons/md'
import { Box } from '@chakra-ui/react'
import QueuesComponent from './components/queuesTable'

const App = () => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <QueuesComponent />
    </Box>
  )
}

// Analisar como vamos monitorar as filas e ver resultados.

export default App
