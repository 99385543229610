import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormHelperText,
  InputRightElement,
  IconButton,
  Icon,
  Button,
  Checkbox,
  FormControl,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Tag,
  Text,
  Grid,
  GridItem
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import AnimalsForm from './animaisForm'
import { getUserAccountId } from 'hooks/authService'
import pipelineStatus from 'components/setPipelineStatusName'
import {
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
  FaHome,
  FaLinkedin,
  FaFacebook,
  FaBuilding,
  FaIndustry,
  FaPhone,
  FaCity,
  FaUserTie,
  FaClock,
  FaBriefcase,
  FaMapPin,
  FaUsers,
  FaMapMarkerAlt,
  FaMapSigns,
  FaGlobe,
  FaSearch,
  FaInfoCircle,
  FaDollarSign,
  FaGavel,
  FaCalendarAlt,
  FaCheckCircle,
  FaFileAlt
} from 'react-icons/fa'
import { InputText } from 'primereact/inputtext'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { FloatLabel } from 'primereact/floatlabel'
import { Dropdown } from 'primereact/dropdown'
import { porteEmpresas } from 'contexts/dadosBrasileiros'
import { InputTextarea } from 'primereact/inputtextarea'

export default function CompanyForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  // const [addCompanyAnimal, setAddCompanyAnimal] = useState(false)

  const { company_id: companyIdFromParams } = useParams() || ''
  const [company_id, setCompanyId] = useState(companyIdFromParams)

  const [formData, setFormData] = useState({
    account_id: account_id,
    name: '',
    business_data: {
      cnpj: '',
      razao_social: '',
      situacao_cadastral: '',
      natureza_juridica: '',
      porte: '',
      data_abertura: '',
      capital_social: '',
      optante_simples: '',
      tipo: '', // matriz/filial
      atividade_principal: {
        cnae: ''
      },
      atividade_secundaria: [
        {
          cnae: ''
        }
      ]
    },
    contact_data: {
      telefone: [
        {
          tipo: '', // Fixo, whatsapp, móvel, voip
          numero: ''
        }
      ],
      email: [
        {
          tipo: '', // principal, suporte, ...
          endereco_email: ''
        }
      ]
    },
    address: {
      pais: '',
      uf: '',
      municipio: '',
      bairro: '',
      tipo_logradouro: '',
      logradouro: '',
      numero: '',
      cep: ''
    },
    pipeline: 'new',
    profile: {
      area_atuacao: '',
      funcionarios: '',
      faturamento_anual: '',
      descricao: ''
    },
    company_logo_url: ''
  })

  useEffect(() => {
    console.log('formData CompanyForm:', formData)
  }, [account_id, company_id, formData, companyIdFromParams])

  async function getCNPJData(cnpj) {
    try {
      const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      const data = await response.json()
      console.log('Response CNPJ API:', data)

      let nomeFantasia

      if (!data.estabelecimento.nome_fantasia) {
        nomeFantasia = data.razao_social
      } else {
        nomeFantasia = data.estabelecimento.nome_fantasia
      }

      setFormData((prevState) => ({
        ...prevState,
        name: nomeFantasia,
        business_data: {
          cnpj,
          razao_social: data.razao_social,
          situacao_cadastral: data.estabelecimento.situacao_cadastral,
          natureza_juridica: `${data.natureza_juridica.descricao} (${data.natureza_juridica.id})`,
          porte: data.porte.descricao,
          data_abertura: data.estabelecimento.data_inicio_atividade,
          capital_social: data.capital_social,
          optante_simples: data.simples && data.simples.simples,
          tipo: data.estabelecimento.tipo, // matriz/filial
          atividade_principal: {
            cnae: `${data.estabelecimento.atividade_principal.subclasse} - ${data.estabelecimento.atividade_principal.descricao}`
          },
          atividade_secundaria: [
            {
              cnae: '',
              descricao: ''
            }
          ]
        },
        contact_data: {
          telefone: [
            {
              tipo: 'fixo', // Fixo, whatsapp, móvel, voip
              numero: `55${data.estabelecimento.ddd1}${data.estabelecimento.telefone1}`
            }
          ],
          email: [
            {
              tipo: '', // principal, suporte, ...
              endereco_email: ''
            }
          ]
        },
        address: {
          pais: data.estabelecimento.pais.nome,
          uf: data.estabelecimento.estado.sigla,
          municipio: data.estabelecimento.cidade.nome,
          bairro: data.estabelecimento.bairro,
          tipo_logradouro: data.estabelecimento.tipo_logradouro,
          logradouro: data.estabelecimento.logradouro,
          numero: data.estabelecimento.numero,
          cep: data.estabelecimento.cep
        }
      }))
    } catch (error) {
      console.error('Error fetching CNPJ data:', error)
    }
  }

  useEffect(() => {
    async function getCompanyData() {
      try {
        const { data } = await axios.get(`/companies/${company_id}`, { headers: authHeader() })
        setFormData(data)
      } catch (error) {
        console.log('Erro ao carregar dados da empresa: ', error)
      }
    }

    if (company_id) {
      getCompanyData()
    }
  }, [])

  const [sendAlert, setSendAlert] = useState('')

  const handleCheckboxProfileChange = (event) => {
    const value = event.target.value
    setFormData((prevContactProfile) => {
      const newCompanyProfile = JSON.parse(JSON.stringify(prevContactProfile))

      if (event.target.checked) {
        newCompanyProfile.owner_data.profile.push(value)
      } else {
        newCompanyProfile.owner_data.profile = newCompanyProfile.owner_data.profile.filter(
          (item) => item !== value
        )
      }

      return newCompanyProfile
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const newState = { ...prevState }

      const keys = name.split('.') // Divide o name em um array
      let temp = newState

      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) {
          temp[keys[i]] = {}
        }
        temp = temp[keys[i]]
      }

      temp[keys[keys.length - 1]] = value

      return newState
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    console.log('formData antes do envio:', formData)

    if (company_id) {
      try {
        const response = await axios.patch(`/companies/${company_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
        // setAddCompanyAnimal(true)
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/companies', formData, {
          headers: authHeader()
        })
        console.log('Empresa cadastrada com sucesso!', response.data)
        setCompanyId(response.data.id)
        // setAddCompanyAnimal(true)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar a empresa:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  const { status, color } = pipelineStatus(formData.pipeline)

  return (
    <>
      <Card p="20px">
        <Flex justifyContent={'space-between'}>
          <Flex flexDirection={'column'}>
            <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
              {formData.business_data.razao_social}
            </Text>
            <Flex flexWrap={'wrap'} gap={1} mb="24px">
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {formData.business_data.tipo}
              </Tag>
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {`Situação: ${formData.business_data.situacao_cadastral}`}
              </Tag>
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {`Fundação: ${formData.business_data.data_abertura}`}
              </Tag>
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {`Capital Social: ${formData.business_data.capital_social}`}
              </Tag>
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {formData.business_data.natureza_juridica}
              </Tag>
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {`Simples: ${formData.business_data.optante_simples}`}
              </Tag>
            </Flex>
          </Flex>
          {/* <Button color={color}>{status}</Button> */}
        </Flex>
        <form onSubmit={handleSubmit}>
          <Flex mb="100px">
            <Grid templateColumns="repeat(2, 1fr)" gap={5} w={'100%'}>
              <GridItem w="100%" colSpan={1}>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  CNPJ
                </label>
                <Flex py="0.3rem" mt="-10px" alignItems={'center'}>
                  <Icon as={FaIndustry} mr="8px" color="#A0AEC0" />
                  <InputText
                    value={formData.business_data.cnpj}
                    name="business_data.cnpj"
                    onChange={handleChange}
                    className="w-full p-1"
                    placeholder="CNPJ"
                    v-model="value1"
                  />
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Nome Fantasia
                </label>
                <Flex py="0.3rem" mt="-10px" alignItems={'center'}>
                  <Icon as={FaBuilding} mr="8px" color="#A0AEC0" />
                  <InputText
                    value={formData.name}
                    name="name"
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        name: e.target.value
                      }))
                    }
                    className="w-full p-1"
                    placeholder="Nome da empresa"
                    v-model="value1"
                  />
                </Flex>

                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Faturamento anual
                </label>
                <Flex py="0.3rem" mt="-10px" alignItems={'center'}>
                  <Icon as={FaIndustry} mr="8px" color="#A0AEC0" />
                  <InputText
                    value={formData.profile.faturamento_anual}
                    name="profile.faturamento_anual"
                    onChange={handleChange}
                    className="w-full p-1"
                    placeholder="R$..."
                    v-model="value1"
                  />
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Porte e Faturamento
                </label>
                <Flex py="0.3rem" mt="-10px" alignItems={'center'}>
                  <Icon as={FaUsers} mr="8px" color="#A0AEC0" />
                  <Dropdown
                    name="business_data.porte"
                    onChange={handleChange}
                    placeholder="Selecione o porte"
                    value={formData.business_data.porte}
                    options={porteEmpresas}
                    optionLabel="name"
                    showClear
                    className="w-full"
                  />
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Colaboradores
                </label>
                <Flex py="0.3rem" mt="-10px" alignItems={'center'}>
                  <Icon as={FaUsers} mr="8px" color="#A0AEC0" />
                  <Dropdown
                    name="profile.funcionarios"
                    onChange={handleChange}
                    placeholder="Número de funcionários"
                    value={formData.profile.funcionarios}
                    options={[
                      { value: '1 a 10', name: '1 a 10 Funcionários' },
                      { value: '10 a 30', name: '10 a 30 Funcionários' },
                      { value: '30 a 70', name: '30 a 70 Funcionários' },
                      { value: '70 a 200', name: '70 a 200 Funcionários' },
                      { value: '200 a 500', name: '200 a 500 Funcionários' },
                      { value: '+500', name: '+500' }
                    ]}
                    optionLabel="name"
                    showClear
                    className="w-full"
                  />
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Atuação
                </label>
                <Flex py="0.3rem" mt="-10px" alignItems={'center'}>
                  <Icon as={FaBriefcase} mr="8px" color="#A0AEC0" />

                  <InputText
                    value={formData.profile.area_atuacao}
                    name="profile.area_atuacao"
                    onChange={handleChange}
                    className="w-full p-1"
                    placeholder="..."
                    v-model="value1"
                  />
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Descrição
                </label>
                <Flex py="0.3rem" alignItems={'center'}>
                  <Icon as={FaFileAlt} mr="8px" color="#A0AEC0" />
                  <InputTextarea
                    value={formData.profile.descricao}
                    name="profile.descricao"
                    onChange={handleChange}
                    className="w-full p-1"
                    placeholder="..."
                    v-model="value1"
                  />
                </Flex>
              </GridItem>
              <GridItem w="100%" colSpan={1}>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Endereço da Sede
                </label>
                <Flex flexDirection={'column'} mb="15px" justifyContent={'start'}>
                  {/* <Icon as={FaBuilding} mr="8px" color="#A0AEC0" /> */}
                  <Text
                    fontSize={'14px'}
                  >{`${formData.address.tipo_logradouro} ${formData.address.logradouro}, ${formData.address.numero} - ${formData.address.bairro}`}</Text>
                  <Text
                    fontSize={'14px'}
                  >{`${formData.address.cep} - ${formData.address.municipio}, ${formData.address.uf} - ${formData.address.pais}`}</Text>
                </Flex>

                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Outros endereços
                </label>
                <Flex mb="15px">
                  <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                    + Adicionar endereço
                  </Tag>
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Telefones
                </label>
                <Flex>
                  <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                    + Adicionar telefone
                  </Tag>
                </Flex>
              </GridItem>
              <GridItem w="100%" colSpan={1} mt="20px" h="25px"></GridItem>
              <GridItem w="100%" colSpan={1} mt="20px" h="25px"></GridItem>
            </Grid>
          </Flex>
          <FormControl>
            <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  CNPJs
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaIndustry} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.cnpj}
                      name="business_data.cnpj"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Digite para carregar dados"
                      fontWeight="500"
                      size="md"
                    />
                    <InputRightElement width="2.7rem">
                      <IconButton
                        colorScheme={formData.whatsapp ? 'green' : 'gray'}
                        aria-label="Call Segun"
                        size="sm"
                        p="1px"
                        onClick={() => getCNPJData(formData.business_data.cnpj)}
                        icon={<Icon as={FaSearch} />}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </Flex>
              {/* <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Nome da empresa
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaIndustry} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.name}
                      name="name"
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          name: e.target.value
                        }))
                      }
                      placeholder="Nome da empresa"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Razão Social
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaBuilding} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.razao_social}
                      name="business_data.razao_social"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Razão Social"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Situação Cadastral
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaInfoCircle} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.situacao_cadastral}
                      name="business_data.situacao_cadastral"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Situação Cadastral"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Natureza Jurídica
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaGavel} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.natureza_juridica}
                      name="business_data.natureza_juridica"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Natureza Jurídica"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Porte
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaBuilding} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.porte}
                      name="business_data.porte"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Porte"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Data de Abertura
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaCalendarAlt} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.data_abertura}
                      name="business_data.data_abertura"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Data de Abertura"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Capital Social
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaDollarSign} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.capital_social}
                      name="business_data.capital_social"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Capital Social"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText mt={'4px'} fontSize="xs" ml="15px">
                  Optante pelo Simples
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaCheckCircle} mr="5px" />
                    </InputLeftAddon>
                    <Select
                      placeholder="Optante pelo Simples Nacional"
                      value={formData.business_data.optante_simples}
                      name="business_data.optante_simples"
                      onChange={handleChange}
                      style={{
                        borderRadius: '0 50px 50px 0',
                        paddingInlineEnd: '0'
                      }}
                    >
                      <option value="Sim">Sim</option>
                      <option value="Não">Não</option>
                    </Select>
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Tipo (Matriz/Filial)
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaBuilding} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.business_data.tipo}
                      name="business_data.tipo"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Tipo (Matriz/Filial)"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex> */}
              <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  Telefone
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaPhone} mr="5px" />
                    </InputLeftAddon>
                    <Select
                      value={formData.contact_data.telefone[0].tipo}
                      name="contact_data.telefone[0].tipo"
                      onChange={handleChange}
                      placeholder="Tipo"
                      style={{ bordeRadius: '0px' }}
                    >
                      <option value="fixo">Fixo</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="movel">Móvel</option>
                      <option value="voip">VoIP</option>
                    </Select>
                    <Input
                      value={formData.contact_data.telefone[0].numero}
                      name="contact_data.telefone[0].numero"
                      onChange={handleChange}
                      style={{
                        borderRadius: '0 50px 50px 0',
                        paddingInlineEnd: '0'
                      }}
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              {/* <Flex direction="column">
                <FormHelperText fontSize="xs" ml="15px">
                  País
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaGlobe} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.address.pais}
                      name="address.pais"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="País"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>
              <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  UF
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaMapSigns} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.address.uf}
                      name="address.uf"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="UF"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  Município
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaCity} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.address.municipio}
                      name="address.municipio"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Município"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  Bairro
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaMapMarkerAlt} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.address.bairro}
                      name="address.bairro"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Bairro"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  CEP
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaMapPin} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.address.cep}
                      name="address.cep"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="CEP"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex> */}

              {/* <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  Área de Atuação
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaBriefcase} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.profile.area_atuacao}
                      name="profile.area_atuacao"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Área de Atuação"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex> */}

              {/* <Flex direction="column" mt="10px">
                <FormHelperText mt={'4px'} fontSize="xs" ml="15px">
                  Número de Funcionários
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaUsers} mr="5px" />
                    </InputLeftAddon>
                    <Select
                      placeholder="Número de funcionários"
                      value={formData.profile.funcionarios}
                      name="profile.funcionarios"
                      onChange={handleChange}
                      style={{
                        borderRadius: '0 50px 50px 0',
                        paddingInlineEnd: '0'
                      }}
                    >
                      <option value="1 a 10">1 a 10</option>
                      <option value="10 a 30">10 a 30</option>
                      <option value="30 a 70">30 a 70</option>
                      <option value="70 a 200">70 a 200</option>
                      <option value="70 a 200">200 a 500</option>
                      <option value="70 a 200">Mais de 500</option>
                    </Select>
                  </InputGroup>
                </Stack>
              </Flex> */}

              {/* <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  Faturamento Anual
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaDollarSign} mr="5px" />
                    </InputLeftAddon>
                    <Input
                      value={formData.profile.faturamento_anual}
                      name="profile.faturamento_anual"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Faturamento Anual"
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <Flex direction="column" mt="10px">
                <FormHelperText fontSize="xs" ml="15px">
                  Descrição
                </FormHelperText>
                <Stack>
                  <InputGroup>
                    <InputLeftAddon
                      width="50px"
                      style={{
                        backgroundColor: '#FAFCFE',
                        color: '#A0AEC0',
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px'
                      }}
                    >
                      <Icon as={FaFileAlt} mr="5px" />
                    </InputLeftAddon>
                    <Textarea
                      value={formData.profile.descricao}
                      name="profile.descricao"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Descrição"
                      fontWeight="500"
                      size="md"
                      style={{ border: 'solid 1px #E0E5F2', borderRadius: '0px 20px 20px 20px' }}
                    />
                  </InputGroup>
                </Stack>
              </Flex> */}
            </SimpleGrid>
            <Flex direction="column">
              <FormHelperText fontSize="xs" ml="15px">
                Atividade Principal - CNAE
              </FormHelperText>
              <Stack>
                <InputGroup>
                  <InputLeftAddon
                    width="50px"
                    style={{
                      backgroundColor: '#FAFCFE',
                      color: '#A0AEC0',
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px'
                    }}
                  >
                    <Icon as={FaIndustry} mr="5px" />
                  </InputLeftAddon>
                  <Input
                    value={formData.business_data.atividade_principal.cnae}
                    name="business_data.atividade_principal.cnae"
                    onChange={handleChange}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="CNAE"
                    fontWeight="500"
                    size="md"
                  />
                </InputGroup>
              </Stack>
            </Flex>

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="md"
              fontWeight="800"
              color={textColor}
              mt="20px"
              mb="8px"
            >
              Perfil da empresa
            </FormLabel>
            <Stack
              spacing={1}
              style={{ display: 'grid', gap: '10px' }}
              gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
              mb={10}
            >
              {['Cliente', 'Fornecedor'].map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData?.owner_data?.profile?.includes(value)}
                  onChange={handleCheckboxProfileChange}
                >
                  {value}
                </Checkbox>
              ))}
            </Stack>

            <Button
              type="submit"
              fontSize="xs"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              borderRadius="50px"
            >
              {company_id ? 'Atualizar empresa' : 'Adicionar empresa'}
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
    </>
  )
}
