// import feathers from '@feathersjs/feathers';
// import auth from '@feathersjs/authentication-client';
// import history from '../../history';
import feathers from 'feathers-client'
import SERVER_URL from './serverUrl'

const app = feathers()

app.configure(feathers.rest(SERVER_URL).fetch(window.fetch))
// .configure(auth({ storage: window.localStorage }));

const services = {
  users: app.service('users'),
  companies: app.service('companies'),
  campaigns: app.service('campaigns'),
  animals: app.service('animals'),
  clients: app.service('clients'),
  consultations: app.service('consultations'),
  scripts: app.service('scripts')
}

// app.hooks({
//   error: {
//     all(context) {
//       if (
//         context.path !== 'login' &&
//         context.error &&
//         context.error.name === 'NotAuthenticated'
//       ) {
//         history.push({
//           pathname: `/login`,
//         });
//       }
//       return context;
//     },
//   },
// });

export { app, services }
