export default function pipelineStatus(pipeline) {
  switch (pipeline) {
    case 'contacted':
      return {
        status: 'Contatada',
        color: 'green'
      }
    case 'no-fit':
      return {
        status: 'Sem fit',
        color: 'orange'
      }
    case 'qualified':
      return {
        status: 'Qualificada',
        color: 'yellow'
      }
    case 'client':
      return {
        status: 'Cliente',
        color: 'green'
      }
    default:
      return {
        status: 'Não contatada',
        color: 'blue'
      } // Default icon
  }
}
