// Chakra imports
import { Box, Flex, Icon, Text, Select, useColorModeValue, SimpleGrid } from '@chakra-ui/react'
// Custom components
import AnimalsAgeDonutCard from './DonutCardAnimalsAge'
import StatusDonutChartCard from './DonutCardStatus'
import DonutChartCardSpecies from './DonutCardSpecies'
import React from 'react'
import { MdDone } from 'react-icons/md'

export default function ClientsDash(props) {
  const { height, weight, labels, ...rest } = props

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const cardColor = useColorModeValue('white', 'navy.700')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Flex borderRadius="20px" px="20px" bg="white" pt="20px" flexDirection="column">
      <Flex alignItems="center" w="100%">
        <Icon as={MdDone} width="20px" height="20px" mr={3} color="inherit" />

        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Perfil de clientes
        </Text>
      </Flex>
      <SimpleGrid minChildWidth="130px" columns={5} justifyItems={'center'} alignItems={'center'}>
        {/* <Flex flexDirection="column" alignContent="center"> */}

        {/* </Flex> */}
        <StatusDonutChartCard weight={'100%'} height={'80%'} labels={false} />
        {/* <AnimalsAgeDonutCard weight={'100%'} height={'80%'} labels={false} /> */}
        {/* <DonutChartCardSpecies weight={'100%'} height={'80%'} labels={false} /> */}
        <Flex
          flexDirection="column"
          alignContent="center"
          boxShadow={cardShadow}
          p="10px 20px"
          borderRadius="20px"
        >
          <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
            Clientes
          </Text>
          <Text color="#1b2559" fontSize="32px" fontWeight="700">
            6960
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          alignContent="center"
          boxShadow={cardShadow}
          p="10px 20px"
          borderRadius="20px"
        >
          <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
            Abordagens
          </Text>
          <Text color="#1b2559" fontSize="32px" fontWeight="700">
            2960
          </Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  )
}
