// Chakra imports
import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea
} from '@chakra-ui/react'

// Custom components
import React, { useState, useEffect } from 'react'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
import { getUserAuthentication } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import axios from 'api/axios'
import Pipeline from './components/pipeline'
import { useLocation } from 'react-router-dom'

export default function AdminDefault() {
  const textColor = useColorModeValue('navy.700', 'white')
  const userData = getUserAuthentication()

  const [pipelineList, setPipelineList] = useState({
    id: null
  })

  const {
    user: {
      user_data: { main_account_id: accountId },
      role: userRole // para permissão de renomeação e reordenação dos steps de cada pipeline <InputEdit>
    }
  } = userData

  async function getAccountPipelines() {
    try {
      const response = await axios.get(`/pipelines?account_id=${accountId}`, {
        headers: authHeader()
      })

      const orderedPipelineList = getOrderedPipelines(response.data.data)
      setPipelineList(orderedPipelineList)
    } catch (error) {
      console.error('PipelineId Not Found.', error)
    }
  }

  function getOrderedPipelines(pipelines) {
    if (pipelines.length === 1) {
      return pipelines
    }
    return pipelines.steps.sort((a, b) => a.order - b.order)
  }

  useEffect(() => {
    if (accountId) getAccountPipelines()
  }, [])

  return (
    <Box pt={{ base: '50px', md: '50px', xl: '50px' }}>
      <SimpleGrid rows="2">
        <Flex flexDirection={'row'}>
          {/* <Text p="10px">Modo Foco</Text>
          <Text p="10px">Status da meta</Text>
          <Text p="10px">Adicionar clientes</Text>
          <Text p="10px">Ligações</Text>
          <Text p="10px">Followups</Text>
          <Text p="10px">Apreentações</Text>
          <Text p="10px">Fechamentos</Text> */}
        </Flex>
        {pipelineList && <Pipeline accountPipelines={pipelineList} onUpdate={getAccountPipelines} />}
      </SimpleGrid>
    </Box>
  )
}
