export function capitalizeText(frase) {
  if (typeof frase !== 'string') {
    return '' // Retorna uma string vazia se a frase não for uma string
  }

  let palavras = frase.toLowerCase().split(' ')

  for (let i = 0; i < palavras.length; i++) {
    if (palavras[i] !== '') {
      palavras[i] = palavras[i].charAt(0).toUpperCase() + palavras[i].slice(1)
    }
  }

  return palavras.join(' ')
}
