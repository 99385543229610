import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Switch,
  Select,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import AddTermToInput from 'components/addTermToInput'

export default function ScriptForm(props) {
  const { campaign_id } = props
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { script_id: scriptIdFromParams } = useParams() || ''
  const [script_id, setClientId] = useState(scriptIdFromParams)

  const textareaRef = useRef(null)
  const [accountName, setAccountName] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    message: '',
    campaign_id: campaign_id,
    account_id: account_id,
    tags: {},
    status: 'active',
    step: ''
  })

  useEffect(() => {
    console.log('Script formData:', formData)
  }, [account_id, script_id, formData, scriptIdFromParams])

  useEffect(() => {
    async function getScriptData() {
      try {
        const { data } = await axios.get(`/scripts/${script_id}`, { headers: authHeader() })
        setFormData(data)
      } catch (error) {
        console.log('Erro ao carregar dados do cliente: ', error)
      }
    }

    if (script_id) {
      getScriptData()
    }
  }, [])

  useEffect(() => {
    async function getAccountName() {
      try {
        const { data } = await axios.get(`/accounts/${account_id}`, { headers: authHeader() })
        console.log('Conta:', data.name)
        setAccountName(data.name)
      } catch (err) {
        console.log('Erro: ', err.response.data)
      }
    }

    if (account_id) {
      getAccountName()
    }
  }, [])

  const [sendAlert, setSendAlert] = useState('')

  const handleCheckboxCategoryChange = (event, groupName) => {
    const value = event.target.value
    setFormData((prevFormData) => {
      const newCategories = { ...prevFormData.categories }

      if (!Array.isArray(newCategories[groupName])) {
        newCategories[groupName] = []
      }

      if (event.target.checked) {
        newCategories[groupName] = [...newCategories[groupName], value]
      } else {
        newCategories[groupName] = newCategories[groupName].filter((item) => item !== value)
      }

      return {
        ...prevFormData,
        categories: newCategories
      }
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (script_id) {
      try {
        const response = await axios.patch(`/scripts/${script_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/scripts', formData, {
          headers: authHeader()
        })
        console.log('Cliente cadastrado com sucesso!', response.data)
        setClientId(response.data.id)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  const checkboxGroups = {
    Atendimento: ['Preventivo', 'Campanha'],
    Especies: ['Canino', 'Felino'],
    Idade: ['Filhote', 'Adulto', 'Idoso'],
    'Status do Cliente': ['Ativo', 'Estagnado', 'Inativo']
  }

  const CheckboxGrid = () => {
    return (
      <SimpleGrid columns={2} gap={5}>
        {Object.entries(checkboxGroups).map(([groupName, values]) => (
          <Flex
            key={groupName}
            style={{ marginBottom: '16px' }}
            direction="column"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
          >
            <strong>{groupName}</strong>
            <Flex direction="column">
              {values.map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData.categories[groupName]?.includes(value)}
                  onChange={(e) => handleCheckboxCategoryChange(e, groupName)}
                  style={{ marginLeft: '8px' }}
                  fontSize="sm"
                  fontWeight="400"
                  color={textColor}
                >
                  {value}
                </Checkbox>
              ))}
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    )
  }

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <SimpleGrid columns={{ base: '1', md: '2.5' }} gap="0px 20px">
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Assunto
                </FormLabel>
                <Input
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              {/* <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Categoria
                </FormLabel>
                <Input
                  value={formData.category}
                  name="category"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Categoria"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex> */}
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Contexto
                </FormLabel>
                <Select
                  value={formData.subject}
                  name="subject"
                  onChange={handleChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Selecione"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                >
                  {[
                    'Primeira abordagem',
                    '2h depois',
                    '4h depois',
                    '24h depois',
                    '48h depois',
                    '72h depois'
                  ].map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </Flex>
              {/* <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Etapa
                </FormLabel>
                <Input
                  value={formData.step}
                  name="step"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Etapa"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex> */}
              {/* <FormControl display="flex" flexDirection="column" alignItems="start" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm" mb="8px">
                  Status Ativo?
                </FormLabel>
                <Switch id="status" defaultChecked />
              </FormControl> */}
            </SimpleGrid>
            {/* <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                Categorias
              </FormLabel>
              <CheckboxGrid />
            </FormControl> */}
            <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                Mensagem
              </FormLabel>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Olá {{TutorNome}}, tudo bem?"
                fontWeight="500"
                size="md"
                ref={textareaRef}
              />
              <FormHelperText mb="24px">
                <AddTermToInput textToCopy="{{TutorNome}}" textareaRef={textareaRef} mr="5px" />
                <AddTermToInput textToCopy="{{AnimalNome}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy="{{AnimalIdade}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy="{{o/a}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy="{{ele/ela}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy={`${accountName}`} textareaRef={textareaRef} />
                <Text fontSize={'sm'} mt="-10px" pl="10px">
                  Clique nas opções acima para adicionar os termos na sua mensagem.
                </Text>
              </FormHelperText>
            </FormControl>
            {/* <Stack
              spacing={1}
              style={{ display: 'grid', gap: '10px' }}
              gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
              mb={10}
            >
              {[
                'Canino',
                'Felino',
                'Filhote',
                'Adulto',
                'Idoso',
                'Cliente ativo',
                'Cliente estagnado',
                'Cliente inativo',
                'Preventivo',
                'Campanha'
              ].map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData?.owner_data?.profile?.includes(value)}
                  onChange={handleCheckboxProfileChange}
                >
                  {value}
                </Checkbox>
              ))}
            </Stack> */}

            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              mb="24px"
              borderRadius="50px"
            >
              Salvar
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
    </>
  )
}
